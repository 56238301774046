import React from 'react';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import './work_exception_event_data.scss';


class WorkExceptionEventData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.work_exception_event.id && this.props.work_exception_event.id > 0;
  }

  getStaffOptions() {
    let options = this.props.users.filter((user) => user.is_active || user.id === this.props.work_exception_event.user_id).map((entry) => SelectOption(entry.id, entry.name));

    if(this.props.work_exception_event.user_id && this.props.work_exception_event.user_id > 0) {
      return [
        ...options
      ];
    }

    return [
      SelectOption('', 'Selecione um colaborador'),
      ...options
    ];
  }

  render() {
    return (
      <DefaultSection
        className="work-exception-event-data"
        title="Dados do imprevisto"
      >

        <div className="work-exception-event-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="work-exception-event-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="user_id"
              isHighlighted={this.isHighlighted("user_id")}
              label="Colaborador"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.work_exception_event.user_id || ''}
              options={this.getStaffOptions()}
            />

            <DefaultInput
              name="date"
              label="Data do imprevisto"
              type="date"
              placeholder="Data do imprevisto"
              handleInputChange={this.props.handleInputChange}
              value={this.props.work_exception_event.date.slice(0, 10)}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do imprevisto"
            rows="4"
            handleInputChange={this.props.handleInputChange}
            value={this.props.work_exception_event.description || ''}
          />

        </div>

        <HorizontalRule />

        <div className="work-exception-event-data__buttons-container">

          <button
            className="work-exception-event-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <button
            className="work-exception-event-data__cancel-button"
            onClick={this.props.onClickCancelPath}
          >

            Cancelar

          </button>

        </div>

      </DefaultSection>
    );
  }
}

export default WorkExceptionEventData;
