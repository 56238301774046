import React from 'react';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import './work_schedule_data.scss';


class WorkScheduleData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.work_schedule.id && this.props.work_schedule.id > 0;
  }

  getStaffOptions() {
    let options = this.props.users.filter((user) => user.is_active || user.id === this.props.work_schedule.user_id).map((entry) => SelectOption(entry.id, entry.name));

    if(this.props.work_schedule.user_id && this.props.work_schedule.user_id > 0) {
      return [
        ...options
      ];
    }

    return [
      SelectOption('', 'Selecione um colaborador'),
      ...options
    ];
  }

  getWeekdayOptions() {
    const weekdays = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo'
    ];

    let options = weekdays.map((entry, index) => SelectOption(index, entry));

    if(typeof this.props.work_schedule.day_id === 'number' && this.props.work_schedule.day_id >= 0) {
      return [
        ...options
      ];
    }

    return [
      SelectOption('', 'Selecione um dia'),
      ...options
    ];
  }

  render() {
    return (
      <DefaultSection
        className="work-schedule-data"
        title="Dados do período de atendimento"
      >

        <div className="work-schedule-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="work-schedule-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="user_id"
              isHighlighted={this.isHighlighted("user_id")}
              label="Colaborador"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.work_schedule.user_id || ''}
              options={this.getStaffOptions()}
            />

            <DefaultInput
              name="day_id"
              isHighlighted={this.isHighlighted("day_id")}
              label="Dia da semana"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.work_schedule.day_id}
              options={this.getWeekdayOptions()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="start_at"
              isHighlighted={this.isHighlighted("start_at")}
              label="Hora da entrada"
              type="time"
              placeholder="Entrada"
              handleInputChange={this.props.handleInputChange}
              value={this.props.work_schedule.start_at}
              max={this.props.work_schedule.end_at}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="end_at"
              isHighlighted={this.isHighlighted("end_at")}
              label="Hora da saída"
              type="time"
              placeholder="Saída"
              handleInputChange={this.props.handleInputChange}
              value={this.props.work_schedule.end_at}
              min={this.props.work_schedule.start_at}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do trabalho"
            rows="4"
            handleInputChange={this.props.handleInputChange}
            value={this.props.work_schedule.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="work-schedule-data__buttons-container">

          <button
            className="work-schedule-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <button
            className="work-schedule-data__cancel-button"
            onClick={this.props.onClickCancelPath}
          >

            Cancelar

          </button>

        </div>

      </DefaultSection>
    );
  }
}

export default WorkScheduleData;
