import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModels, postModel} from '../../utils/functions';
import WorkScheduleData from './work_schedule_data';


class WorkScheduleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      work_schedule: {
        user_id: '',
        day_id: "",
        start_at: "",
        end_at: "",
        description: ""
      },
      users: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async getStaff() {
    return await getModels(routes.STAFF_GET_API);
  }

  async componentDidMount() {
    const update = {
      loading: false
    };

    let users = await this.getStaff();

    if(users) {
      update.users = users;
      update.users.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {};

    if((name === 'day_id' || name === 'user_id') && value.length > 0) {
      value = parseInt(value);
    }

    update.work_schedule = {...this.state.work_schedule, [name]: value};

    this.setState(update);
  }

  inputsAreValid() {
    return this.state.work_schedule.start_at.length > 0 &&
           this.state.work_schedule.end_at.length > 0 &&
           this.state.work_schedule.start_at <= this.state.work_schedule.end_at &&
           this.state.work_schedule.user_id > 0 &&
           this.state.work_schedule.day_id >= 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.work_schedule};

    try {
      await postModel(routes.WORK_SCHEDULE_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.goBack();
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.WORK_SCHEDULE_LIST_PAGE,
            text: "Agenda de trabalho"
          },
          {
            path: routes.WORK_SCHEDULE_ADD_PAGE,
            text: "Adicionar período"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar período de trabalho"
        loading={this.state.loading}
      >

        <WorkScheduleData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          work_schedule={this.state.work_schedule}
          users={this.state.users}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onClickCancelPath={() => this.props.history.goBack()}
          unit_type_id={this.props.unit_type_id}
        />

      </ContentFrame>
    );
  }
}

export default WorkScheduleAdd;
