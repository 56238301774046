import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import './libs/fontawesome-6.5.1-web/scss/fontawesome.scss';
import './libs/fontawesome-6.5.1-web/scss/solid.scss';
import './libs/fontawesome-6.5.1-web/scss/brands.scss';
import './init.scss';
import './general.scss';
import * as serviceWorker from './serviceWorker';
import {isAuthenticated, signOut, getModels, getAbbreviatedName} from './utils/functions';
import Login from './admin/login';
import {FadeContainer} from './utils/pose_containers';
import PreLoader from './utils/preloader';
import AdminFrame from './admin/admin_frame';
import * as routes from './constants';
import {DEFAULT_UNIT_TYPE, COACH_ACCESS_LEVEL} from './constants';
import {TRAINING_DAY_EDIT_PATH} from './admin/training_period/constants';
import {TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH} from './admin/training_day/constants';
import {TRAINING_GROUP_EXERCISE_ASSOCIATION_ADD_PATH, TRAINING_GROUP_EXERCISE_ASSOCIATION_EDIT_PATH} from './admin/training_day_group_association/constants';
import Desktop from './admin/desktop/desktop';
// import ExperimentalClassSchedule from './admin/experimental_class/experimental_class_schedule';
import ExperimentalClassList from './admin/experimental_class/experimental_class_list';
import ExperimentalClassEdit from './admin/experimental_class/experimental_class_edit';
import UserList from './admin/user/user_list';
import UserAdd from './admin/user/user_add';
import UserEdit from './admin/user/user_edit';
import ServiceList from './admin/service/service_list';
import ServiceAdd from './admin/service/service_add';
import ServiceEdit from './admin/service/service_edit';
import ServiceGroupList from './admin/service_group/service_group_list';
import ServiceGroupAdd from './admin/service_group/service_group_add';
import ServiceGroupEdit from './admin/service_group/service_group_edit';
import CostCenterList from './admin/cost_center/cost_center_list';
import CostCenterAdd from './admin/cost_center/cost_center_add';
import CostCenterEdit from './admin/cost_center/cost_center_edit';
import FinancialCategoryList from './admin/financial_category/financial_category_list';
import FinancialCategoryAdd from './admin/financial_category/financial_category_add';
import FinancialCategoryEdit from './admin/financial_category/financial_category_edit';
import StudentList from './admin/student/student_list';
import StudentAdd from './admin/student/student_add';
import StudentManage from './admin/student/student_manage';
import StudentGeneralReport from './admin/student/report/student_general_report';
import FeedbackList from './admin/feedback/feedback_list';
import FeedbackEdit from './admin/feedback/feedback_edit';
import FeedbackReport from './admin/feedback/report/feedback_report';
import FinancialMovementList from './admin/financial_movement/financial_movement_list';
import FinancialMovementAdd from './admin/financial_movement/financial_movement_add';
import FinancialMovementEdit from './admin/financial_movement/financial_movement_edit';
import FinancialGeneralReport from './admin/financial_report/financial_general_report';
// import PhysicalEvaluationExamList from './admin/physical_evaluation/physical_evaluation_exam_list';
// import PhysicalEvaluationExamAdd from './admin/physical_evaluation/physical_evaluation_exam_add';
// import PhysicalEvaluationExamEdit from './admin/physical_evaluation/physical_evaluation_exam_edit';
// import PhysicalEvaluationScheduledList from './admin/physical_evaluation/physical_evaluation_scheduled_list';
import PhysicalEvaluationEdit from './admin/physical_evaluation/physical_evaluation_edit';
import PhysicalEvaluationPhotoEdit from './admin/physical_evaluation/physical_evaluation_photo_edit';
import ExerciseFunctionList from './admin/exercise_function/exercise_function_list';
import ExerciseFunctionAdd from './admin/exercise_function/exercise_function_add';
import ExerciseFunctionEdit from './admin/exercise_function/exercise_function_edit';
import MuscleGroupList from './admin/muscle_group/muscle_group_list';
import MuscleGroupAdd from './admin/muscle_group/muscle_group_add';
import MuscleGroupEdit from './admin/muscle_group/muscle_group_edit';
import ExerciseCategoryList from './admin/exercise_category/exercise_category_list';
import ExerciseCategoryAdd from './admin/exercise_category/exercise_category_add';
import ExerciseCategoryEdit from './admin/exercise_category/exercise_category_edit';
import TrainingExerciseList from './admin/training_exercise/training_exercise_list';
import TrainingExerciseAdd from './admin/training_exercise/training_exercise_add';
import TrainingExerciseEdit from './admin/training_exercise/training_exercise_edit';
import TrainingPeriodList from './admin/training_period/training_period_list';
import TrainingPeriodAdd from './admin/training_period/training_period_add';
import TrainingPeriodEdit from './admin/training_period/training_period_edit';
import TrainingDayList from './admin/training_day/training_day_list';
import TrainingDayEdit from './admin/training_day/training_day_edit';
import TrainingDayGroupAssociationEdit from './admin/training_day_group_association/training_day_group_association_edit';
import TrainingGroupExerciseAssociationAdd from './admin/training_group_exercise_association/training_group_exercise_association_add';
import TrainingGroupExerciseAssociationEdit from './admin/training_group_exercise_association/training_group_exercise_association_edit';
import TrainingPeriodSchedule from './admin/training_period_schedule/training_period_schedule';
import TrainingScheduleActivePeriodAdd from './admin/training_period_schedule/training_schedule_active_period_add';
import TrainingScheduleActivePeriodEdit from './admin/training_period_schedule/training_schedule_active_period_edit';
import CoachList from './admin/coach/coach_list';
import CoachAdd from './admin/coach/coach_add';
import CoachEdit from './admin/coach/coach_edit';
import ReceptionistList from './admin/receptionist/receptionist_list';
import ReceptionistAdd from './admin/receptionist/receptionist_add';
import ReceptionistEdit from './admin/receptionist/receptionist_edit';
import NutritionistList from './admin/nutritionist/nutritionist_list';
import NutritionistAdd from './admin/nutritionist/nutritionist_add';
import NutritionistEdit from './admin/nutritionist/nutritionist_edit';
import UserDeviceList from './admin/user_device/user_device_list';
import UserDeviceEdit from './admin/user_device/user_device_edit';
import TrainingTime from './admin/training_time/training_time';
import ValidateAccount from './admin/validate_account';
import PasswordRecovery from './admin/password_recovery';
import HomeTrainingList from './admin/home_training/home_training_list';
import ClassBoard from './admin/student/class_board/class_board';
import ExerciseActivityTypeList from './admin/exercise_activity_type/exercise_activity_type_list';
import ExerciseActivityTypeAdd from './admin/exercise_activity_type/exercise_activity_type_add';
import ExerciseActivityTypeEdit from './admin/exercise_activity_type/exercise_activity_type_edit';
import ExerciseActivityList from './admin/exercise_activity/exercise_activity_list';
import ExerciseActivityAdd from './admin/exercise_activity/exercise_activity_add';
import ExerciseActivityEdit from './admin/exercise_activity/exercise_activity_edit';
import PersonalTrainingEdit from './admin/personal_training/personal_training_edit';
import PersonalTrainingPerformanceReport from './admin/personal_training/performance_report';
import HrDeviceList from './admin/hr_device/hr_device_list';
import HrDeviceEdit from './admin/hr_device/hr_device_edit';
import HrDeviceAdd from './admin/hr_device/hr_device_add';
import BluetoothDevices from './utils/bluetooth_devices';
import SalesReport from './admin/reports/sales_report';
import ClassReport from './admin/reports/class_report';
import SatisfactionReport from './admin/reports/satisfaction_report';
import FoodClassificationList from './admin/fyd_food/food_classification/food_classification_list';
import FoodClassificationAdd from './admin/fyd_food/food_classification/food_classification_add';
import FoodClassificationEdit from './admin/fyd_food/food_classification/food_classification_edit';
import FoodIngredientList from './admin/fyd_food/food_ingredient/food_ingredient_list';
import FoodIngredientEdit from './admin/fyd_food/food_ingredient/food_ingredient_edit';
import FoodIngredientAdd from './admin/fyd_food/food_ingredient/food_ingredient_add';
import MealClassificationList from './admin/fyd_food/meal_classification/meal_classification_list';
import MealClassificationAdd from './admin/fyd_food/meal_classification/meal_classification_add';
import MealClassificationEdit from './admin/fyd_food/meal_classification/meal_classification_edit';
import FoodRecipeList from './admin/fyd_food/food_recipe/food_recipe_list';
import FoodRecipeAdd from './admin/fyd_food/food_recipe/food_recipe_add';
import FoodRecipeEdit from './admin/fyd_food/food_recipe/food_recipe_edit';
import MealPeriodList from './admin/fyd_food/meal_period/meal_period_list';
import MealPeriodAdd from './admin/fyd_food/meal_period/meal_period_add';
import MealPeriodEdit from './admin/fyd_food/meal_period/meal_period_edit';
import FoodPrescriptionList from './admin/fyd_food/food_prescription/food_prescription_list';
import FoodPrescriptionAdd from './admin/fyd_food/food_prescription/food_prescription_add';
import FoodPrescriptionEdit from './admin/fyd_food/food_prescription/food_prescription_edit';
import FoodPrescriptionReport from './admin/reports/food_prescription_report';
import RoleExperienceLevelList from './admin/role_experience_level/role_experience_level_list';
import RoleExperienceLevelAdd from './admin/role_experience_level/role_experience_level_add';
import RoleExperienceLevelEdit from './admin/role_experience_level/role_experience_level_edit';
import CustomerFeedbackTargetList from './admin/customer_feedback/customer_feedback_target/customer_feedback_target_list';
import CustomerFeedbackTargetAdd from './admin/customer_feedback/customer_feedback_target/customer_feedback_target_add';
import CustomerFeedbackTargetEdit from './admin/customer_feedback/customer_feedback_target/customer_feedback_target_edit';
import CustomerFeedbackList from './admin/customer_feedback/customer_feedback_list';
import CustomerFeedbackEdit from './admin/customer_feedback/customer_feedback_edit';
import AppointmentRoomList from './admin/appointment/appointment_room/appointment_room_list';
import AppointmentRoomAdd from './admin/appointment/appointment_room/appointment_room_add';
import AppointmentRoomEdit from './admin/appointment/appointment_room/appointment_room_edit';
import AppointmentScheduleList from './admin/appointment/appointment_schedule/appointment_schedule_list';
import AppointmentScheduleAdd from './admin/appointment/appointment_schedule/appointment_schedule_add';
import AppointmentScheduleEdit from './admin/appointment/appointment_schedule/appointment_schedule_edit';
import AppointmentList from './admin/appointment/appointment_list';
import AppointmentEdit from './admin/appointment/appointment_edit';
import PersonalTrainingServiceReport from './admin/reports/personal_training_service_report';
import PromotionalCouponList from './admin/promotional_coupon/promotional_coupon_list';
import PromotionalCouponAdd from './admin/promotional_coupon/promotional_coupon_add';
import PromotionalCouponEdit from './admin/promotional_coupon/promotional_coupon_edit';
import PaymentDeviceList from './admin/payment_device/payment_device_list';
import PaymentDeviceAdd from './admin/payment_device/payment_device_add';
import PaymentDeviceEdit from './admin/payment_device/payment_device_edit';
import OperationalTaskList from './admin/operational_task/operational_task_list';
import OperationalTaskAdd from './admin/operational_task/operational_task_add';
import OperationalTaskEdit from './admin/operational_task/operational_task_edit';
import FinancialSearchTagList from './admin/financial_search_tag/financial_search_tag_list';
import FinancialSearchTagAdd from './admin/financial_search_tag/financial_search_tag_add';
import FinancialSearchTagEdit from './admin/financial_search_tag/financial_search_tag_edit';
import PaymentReport from './admin/reports/payment_report';
import AccessControlDeviceList from './admin/access_control_device/access_control_device_list';
import AccessControlDeviceAdd from './admin/access_control_device/access_control_device_add';
import AccessControlDeviceEdit from './admin/access_control_device/access_control_device_edit';
import StaffAccessList from './admin/access_control/staff_access_list';
import NfCouponReport from './admin/reports/nf_coupon_report';
import ClientLeadList from './admin/client_lead/client_lead_list';
import ExitSurveyList from './admin/exit_survey/exit_survey_list';
import ExitSurveyEdit from './admin/exit_survey/exit_survey_edit';
import ExitSurveyReport from './admin/exit_survey/report/exit_survey_report';
import WorkScheduleList from './admin/work_schedule/work_schedule_list';
import WorkScheduleAdd from './admin/work_schedule/work_schedule_add';
import WorkScheduleEdit from './admin/work_schedule/work_schedule_edit';
import WorkExceptionEventList from './admin/work_exception_event/work_exception_event_list';
import WorkExceptionEventAdd from './admin/work_exception_event/work_exception_event_add';
import WorkExceptionEventEdit from './admin/work_exception_event/work_exception_event_edit';
import * as permissions from './permissions';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: false,
      authenticated: false,
      user_id: null,
      username: "",
      permission_ids: [],
      unit_type_id: null,
      unit_name: null,
      access_level: null,
      main_path: '/',
      experimental_classes: [],
      client_leads: [],
    };

    sessionStorage.clear();

    this.bluetoothDevices = new BluetoothDevices();
  }

  async loadAdditionalData(load_experimental_classes, load_client_leads) {
    const data = {
      experimental_classes: [],
      client_leads: []
    };

    try {
      let experimental_classes;
      let client_leads;

      if (load_experimental_classes) {
        experimental_classes = getModels(`${routes.EXPERIMENTAL_CLASSES_GET_API}?marked=false`);
      }

      if (load_client_leads) {
        client_leads = getModels(`${routes.CRM_CLIENT_LEADS_GET_API}?resolved=false`);
      }

      if (load_experimental_classes) {
        data.experimental_classes = await experimental_classes;
      }
      if (load_client_leads) {
        data.client_leads = await client_leads;
      }
    }
    catch(errors) {
      return data;
    }

    return data;
  }

  async componentDidMount() {
    let data = isAuthenticated();

    data = await data;

    const updates = {};

    updates.authenticated = false;
    updates.user_id = null;
    updates.username = '';
    updates.pageLoaded = true;

    if(data) {
      updates.authenticated = data.is_authenticated || false;
      updates.user_id = data.user_id || null;
      updates.username = data.username ? getAbbreviatedName(data.username) : '';
      updates.unit_type_id = data.unit_type_id || null;
      updates.unit_name = data.unit_name || null;
      updates.permission_ids = data.permission_ids || [];
      updates.access_level = data.access_level || null;
      updates.main_path = data.main_path || '/';
    }

    if(updates.authenticated) {
      const additionalData = await this.loadAdditionalData(
        updates.permission_ids.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID),
        updates.permission_ids.includes(permissions.VIEW_CLIENT_LEAD_PERMISSION_ID));

      updates.experimental_classes = additionalData.experimental_classes;
      updates.client_leads = additionalData.client_leads;
    }

    this.setState(updates);
  }

  async onAuthenticate(data) {
    const updates = {
      authenticated: true,
      user_id: data.user_id,
      username: data.username ? getAbbreviatedName(data.username) : '',
      unit_type_id: data.unit_type_id,
      unit_name: data.unit_name,
      permission_ids: data.permission_ids,
      access_level: data.access_level,
      main_path: data.main_path,
    };

    const additionalData = await this.loadAdditionalData(
      data.permission_ids.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID),
      data.permission_ids.includes(permissions.VIEW_CLIENT_LEAD_PERMISSION_ID));

    updates.experimental_classes = additionalData.experimental_classes;
    updates.client_leads = additionalData.client_leads;

    this.setState(updates);
  }

  async onLogout() {
    this.setState({
      pageLoaded: false,
    });

    try {
      if(await signOut()) {
        this.setState({
          authenticated: false,
          user_id: null,
          username: "",
          pageLoaded: true,
        });

        return;
      }
    }
    catch(errors) {
      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              this.setState({
                authenticated: false,
                user_id: null,
                username: "",
                pageLoaded: true,
              });

              return;
            default:
          }
        }
      }

      return;
    }

    window.alert('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');

    this.setState({
      pageLoaded: true,
    });
  }

  isDefaultUnit() {
    return this.state.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  getExperimentalClassRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    // if(this.state.permission_ids.includes(permissions.MANAGE_EXPERIMENTAL_CLASS_SCHEDULE_PERMISSION_ID)) {
    //   routeElements.push(
    //     <Route
    //       key="route:manage_experimental_class_schedule"
    //       path={routes.EXPERIMENTAL_CLASS_AVAILABLE_DAYS_PATH}
    //       component={ExperimentalClassSchedule}
    //     />
    //   );
    // }

    if(this.state.permission_ids.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:experimental_class_incomplete_list"
          path={routes.EXPERIMENTAL_CLASS_SCHEDULED_PATH}
          render={(props) => (
            <ExperimentalClassList
              {...props}
              completedClasses={false}
              key="experimental_class_incomplete_instance"
              onReloadList={(experimental_classes) => this.setState({experimental_classes})}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:experimental_class_complete_list"
          path={routes.EXPERIMENTAL_CLASS_COMPLETED_PATH}
          render={(props) => (
            <ExperimentalClassList
              {...props}
              completedClasses={true}
              key="experimental_class_complete_instance"
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_EXPERIMENTAL_CLASS_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:experimental_class_edit"
          path={`${routes.EXPERIMENTAL_CLASS_EDIT_PATH}:experimentalClassId`}
          component={ExperimentalClassEdit}
        />
      );
    }

    return routeElements;
  }

  getTrainingTimeRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.MANAGE_TRAINING_TIME_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:manage_training_time"
          path={routes.MANAGE_TRAINING_TIMES_PATH}
          render={(props) => (
            <TrainingTime
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getStudentRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:student_list"
          path={routes.STUDENT_LIST_PATH}
          render={(props) => (
            <StudentList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:student_manage"
          path={`${routes.STUDENT_MANAGE_PATH}:studentId/`}
          render={(props) => (
            <StudentManage
              {...props}
              key="student_manage_view"
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              bluetoothDevices={this.bluetoothDevices}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:student_general_report"
          path={routes.STUDENT_REPORT_PATH}
          render={(props) => (
            <StudentGeneralReport
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:student_data_edit"
          path={`${routes.STUDENT_EDIT_PATH}:studentId/`}
          render={(props) => (
            <StudentManage
              {...props}
              key="student_manage_view"
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              bluetoothDevices={this.bluetoothDevices}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_STUDENT_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:student_add"
          path={routes.STUDENT_ADD_PATH}
          component={StudentAdd}
        />
      );
    }

    return routeElements;
  }

  // getPhysicalExamDayRoutes() {
  //   const routeElements = [];
  //
  //   if(this.state.permission_ids.includes(permissions.VIEW_PHYSICAL_EXAM_DAY_PERMISSION_ID)) {
  //     routeElements.push(
  //       <Route
  //         key="route:physical_exam_day_list"
  //         path={routes.PHYSICAL_EVALUATION_EXAM_LIST_PATH}
  //         render={(props) => (
  //           <PhysicalEvaluationExamList
  //             {...props}
  //             userPermissionIds={this.state.permission_ids}
  //             userAccessLevel={this.state.access_level}
  //           />
  //         )}
  //       />
  //     );
  //   }
  //
  //   if(this.state.permission_ids.includes(permissions.ADD_PHYSICAL_EXAM_DAY_PERMISSION_ID)) {
  //     routeElements.push(
  //       <Route
  //         key="route:physical_exam_day_add"
  //         path={routes.PHYSICAL_EVALUATION_EXAM_ADD_PATH}
  //         component={PhysicalEvaluationExamAdd}
  //       />
  //     );
  //   }
  //
  //   if(this.state.permission_ids.includes(permissions.EDIT_PHYSICAL_EXAM_DAY_PERMISSION_ID)) {
  //     routeElements.push(
  //       <Route
  //         key="route:physical_exam_day_edit"
  //         path={`${routes.PHYSICAL_EVALUATION_EXAM_EDIT_PATH}:physicalEvaluationExamDate`}
  //         component={PhysicalEvaluationExamEdit}
  //       />
  //     );
  //   }
  //
  //   return routeElements;
  // }

  getPhysicalEvaluationDataRoutes() {
    const routeElements = [];

    // if(this.state.permission_ids.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
    //   routeElements.push(
    //     <Route
    //       key="route:physical_evaluation_data_list"
    //       path={routes.PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH}
    //       render={(props) => (
    //         <PhysicalEvaluationScheduledList
    //           {...props}
    //           userPermissionIds={this.state.permission_ids}
    //           userAccessLevel={this.state.access_level}
    //         />
    //       )}
    //     />
    //   );
    // }

    if(this.state.permission_ids.includes(permissions.EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
      if (this.isDefaultUnit()) {
        routeElements.push(
          <Route
            key="route:physical_evaluation_photo_edit"
            path={routes.PHYSICAL_EVALUATION_SCHEDULED_PHOTO_EDIT_PATH.replace('{id}', ':physicalEvaluationId')}
            render={(props) => (
              <PhysicalEvaluationPhotoEdit
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }

      routeElements.push(
        <Route
          key="route:physical_evaluation_data_edit"
          path={`${routes.PHYSICAL_EVALUATION_SCHEDULED_EDIT_PATH}:physicalEvaluationId`}
          render={(props) => (
            <PhysicalEvaluationEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              bluetoothDevices={this.bluetoothDevices}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getServicePlansRoutes() {
    const routeElements = [];

    if (this.isDefaultUnit()) {
      if(this.state.permission_ids.includes(permissions.VIEW_SERVICE_PLAN_PERMISSION_ID)) {
        routeElements.push(
          <Route
          key="route:service_group_list"
          path={routes.SERVICE_GROUP_LIST_PATH}
          render={(props) => (
            <ServiceGroupList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
          />
        );
      }

      if(this.state.permission_ids.includes(permissions.ADD_SERVICE_PLAN_PERMISSION_ID)) {
        routeElements.push(
          <Route
          key="route:service_group_list_add"
          path={routes.SERVICE_GROUP_ADD_PATH}
          render={(props) => (
            <ServiceGroupAdd
              {...props}
            />
          )}
          />
        );
      }

      if(this.state.permission_ids.includes(permissions.EDIT_SERVICE_PLAN_PERMISSION_ID)) {
        routeElements.push(
          <Route
          key="route:service_group_list_edit"
          path={`${routes.SERVICE_GROUP_EDIT_PATH}:serviceGroupId`}
          render={(props) => (
            <ServiceGroupEdit
              {...props}
            />
          )}
          />
        );
      }
    }

    if(this.state.permission_ids.includes(permissions.VIEW_SERVICE_PLAN_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:service_plan_list"
          path={routes.SERVICE_LIST_PATH}
          render={(props) => (
            <ServiceList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_SERVICE_PLAN_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:service_plan_add"
          path={routes.SERVICE_ADD_PATH}
          render={(props) => (
            <ServiceAdd
              {...props}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_SERVICE_PLAN_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:service_plan_edit"
          path={`${routes.SERVICE_EDIT_PATH}:serviceId`}
          render={(props) => (
            <ServiceEdit
              {...props}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_PROMOTIONAL_COUPON_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:promotional_coupon_list"
          path={routes.PROMOTIONAL_COUPON_LIST_PATH}
          render={(props) => (
            <PromotionalCouponList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.ADD_PROMOTIONAL_COUPON_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:promotional_coupon_add"
          path={routes.PROMOTIONAL_COUPON_ADD_PATH}
          render={(props) => (
            <PromotionalCouponAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.EDIT_PROMOTIONAL_COUPON_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:promotional_coupon_edit"
          path={`${routes.PROMOTIONAL_COUPON_EDIT_PATH}:promotionalCouponId`}
          render={(props) => (
            <PromotionalCouponEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getUserDeviceRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_USER_DEVICE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:user_device_list"
          path={routes.USER_DEVICE_LIST_PATH}
          render={(props) => (
            <UserDeviceList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_USER_DEVICE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:user_device_edit"
          path={`${routes.USER_DEVICE_EDIT_PATH}:deviceId`}
          component={UserDeviceEdit}
        />
      );
    }

    return routeElements;
  }

  getUserRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_MANAGER_USER_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:manager_user_list"
          path={routes.USER_LIST_PATH}
          render={(props) => (
            <UserList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_MANAGER_USER_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:manager_user_add"
          path={routes.USER_ADD_PATH}
          component={UserAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_MANAGER_USER_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:manager_user_edit"
          path={`${routes.USER_EDIT_PATH}:userId`}
          render={(props) => (
            <UserEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getCoachRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_COACH_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:coach_list"
          path={routes.COACH_LIST_PATH}
          render={(props) => (
            <CoachList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_COACH_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:coach_add"
          path={routes.COACH_ADD_PATH}
          component={CoachAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_COACH_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:coach_edit"
          path={`${routes.COACH_EDIT_PATH}:userId`}
          render={(props) => (
            <CoachEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getRoleExperienceLevelRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:role_experience_level_list"
          path={routes.ROLE_EXPERIENCE_LEVEL_LIST_PATH}
          render={(props) => (
            <RoleExperienceLevelList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:role_experience_level_add"
          path={routes.ROLE_EXPERIENCE_LEVEL_ADD_PATH}
          component={RoleExperienceLevelAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:role_experience_level_edit"
          path={`${routes.ROLE_EXPERIENCE_LEVEL_EDIT_PATH}:roleExperienceLevelId`}
          render={(props) => (
            <RoleExperienceLevelEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getCustomerFeedbackRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:customer_feedback_target_list"
          path={routes.CUSTOMER_FEEDBACK_TARGET_LIST_PATH}
          render={(props) => (
            <CustomerFeedbackTargetList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:customer_feedback_target_add"
          path={routes.CUSTOMER_FEEDBACK_TARGET_ADD_PATH}
          component={CustomerFeedbackTargetAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:customer_feedback_target_edit"
          path={`${routes.CUSTOMER_FEEDBACK_TARGET_EDIT_PATH}:customerFeedbackTargetId`}
          render={(props) => (
            <CustomerFeedbackTargetEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_CUSTOMER_FEEDBACK_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:customer_feedback_list"
          path={routes.CUSTOMER_FEEDBACK_LIST_PATH}
          render={(props) => (
            <CustomerFeedbackList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_CUSTOMER_FEEDBACK_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:customer_feedback_edit"
          path={`${routes.CUSTOMER_FEEDBACK_EDIT_PATH}:customerFeedbackId`}
          render={(props) => (
            <CustomerFeedbackEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getCRMRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_CLIENT_LEAD_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:client_lead_list"
          path={routes.CRM_CLIENT_LEAD_LIST_PATH}
          render={(props) => (
            <ClientLeadList
              {...props}
              onReloadList={(client_leads) => this.setState({client_leads})}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getReceptionistRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_RECEPTIONIST_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:receptionist_list"
          path={routes.RECEPTIONIST_LIST_PATH}
          render={(props) => (
            <ReceptionistList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_RECEPTIONIST_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:receptionist_add"
          path={routes.RECEPTIONIST_ADD_PATH}
          component={ReceptionistAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_RECEPTIONIST_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:receptionist_edit"
          path={`${routes.RECEPTIONIST_EDIT_PATH}:userId`}
          render={(props) => (
            <ReceptionistEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getNutritionistRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_NUTRITIONIST_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:nutritionist_list"
          path={routes.NUTRITIONIST_LIST_PATH}
          render={(props) => (
            <NutritionistList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_NUTRITIONIST_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:nutritionist_add"
          path={routes.NUTRITIONIST_ADD_PATH}
          render={(props) => (
            <NutritionistAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_NUTRITIONIST_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:nutritionist_edit"
          path={`${routes.NUTRITIONIST_EDIT_PATH}:userId`}
          render={(props) => (
            <NutritionistEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getCostCenterRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_COST_CENTER_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:cost_center_list"
          path={routes.COST_CENTER_LIST_PATH}
          render={(props) => (
            <CostCenterList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_COST_CENTER_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:cost_center_add"
          path={routes.COST_CENTER_ADD_PATH}
          component={CostCenterAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_COST_CENTER_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:cost_center_edit"
          path={`${routes.COST_CENTER_EDIT_PATH}:costCenterId`}
          component={CostCenterEdit}
        />
      );
    }

    return routeElements;
  }

  getExerciseFunctionRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_EXERCISE_FUNCTION_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_function_list"
          path={routes.EXERCISE_FUNCTION_LIST_PATH}
          render={(props) => (
            <ExerciseFunctionList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_EXERCISE_FUNCTION_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_function_add"
          path={routes.EXERCISE_FUNCTION_ADD_PATH}
          component={ExerciseFunctionAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_EXERCISE_FUNCTION_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_function_edit"
          path={`${routes.EXERCISE_FUNCTION_EDIT_PATH}:exerciseFunctionId`}
          component={ExerciseFunctionEdit}
        />
      );
    }

    return routeElements;
  }

  getMuscleGroupRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_MUSCLE_GROUP_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:muscle_group_list"
          path={routes.MUSCLE_GROUP_LIST_PATH}
          render={(props) => (
            <MuscleGroupList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_MUSCLE_GROUP_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:muscle_group_add"
          path={routes.MUSCLE_GROUP_ADD_PATH}
          component={MuscleGroupAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_MUSCLE_GROUP_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:muscle_group_edit"
          path={`${routes.MUSCLE_GROUP_EDIT_PATH}:muscleGroupId`}
          component={MuscleGroupEdit}
        />
      );
    }

    return routeElements;
  }

  getExerciseCategoryRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_EXERCISE_CATEGORY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_cateogry_list"
          path={routes.EXERCISE_CATEGORY_LIST_PATH}
          render={(props) => (
            <ExerciseCategoryList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_EXERCISE_CATEGORY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_cateogry_add"
          path={routes.EXERCISE_CATEGORY_ADD_PATH}
          component={ExerciseCategoryAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_EXERCISE_CATEGORY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_cateogry_edit"
          path={`${routes.EXERCISE_CATEGORY_EDIT_PATH}:exerciseCategoryId`}
          component={ExerciseCategoryEdit}
        />
      );
    }

    return routeElements;
  }

  getExerciseActivityTypeRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_activity_type_list"
          path={routes.EXERCISE_ACTIVITY_TYPE_LIST_PATH}
          render={(props) => (
            <ExerciseActivityTypeList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_activity_type_add"
          path={routes.EXERCISE_ACTIVITY_TYPE_ADD_PATH}
          component={ExerciseActivityTypeAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_activity_type_edit"
          path={`${routes.EXERCISE_ACTIVITY_TYPE_EDIT_PATH}:exerciseActivityTypeId`}
          component={ExerciseActivityTypeEdit}
        />
      );
    }

    return routeElements;
  }

  getExerciseActivityRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_EXERCISE_ACTIVITY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_activity_list"
          path={routes.EXERCISE_ACTIVITY_LIST_PATH}
          render={(props) => (
            <ExerciseActivityList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_EXERCISE_ACTIVITY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_activity_add"
          path={routes.EXERCISE_ACTIVITY_ADD_PATH}
          render={(props) => (
            <ExerciseActivityAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_EXERCISE_ACTIVITY_PERMISSION_ID) || this.state.permission_ids.includes(permissions.EDIT_EXERCISE_ACTIVITY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_activity_edit"
          path={`${routes.EXERCISE_ACTIVITY_EDIT_PATH}:exerciseActivityId`}
          render={(props) => (
            <ExerciseActivityEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getTrainingExerciseRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_exercise_list"
          path={routes.TRAINING_EXERCISE_LIST_PATH}
          render={(props) => (
            <TrainingExerciseList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_TRAINING_EXERCISE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_exercise_add"
          path={routes.TRAINING_EXERCISE_ADD_PATH}
          render={(props) => (
            <TrainingExerciseAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID) || this.state.permission_ids.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_exercise_edit"
          path={`${routes.TRAINING_EXERCISE_EDIT_PATH}:trainingExerciseId`}
          render={(props) => (
            <TrainingExerciseEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getTrainingPeriodRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_group_training_day_association_edit"
          path={`${routes.TRAINING_PERIOD_EDIT_PATH}:trainingPeriodId${TRAINING_DAY_EDIT_PATH}:trainingDayId${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}:trainingDayGroupAssociationId${TRAINING_GROUP_EXERCISE_ASSOCIATION_EDIT_PATH}:trainingGroupExerciseAssociationId`}
          render={(props) => (
            <TrainingGroupExerciseAssociationEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exercise_group_training_day_association_add"
          path={`${routes.TRAINING_PERIOD_EDIT_PATH}:trainingPeriodId${TRAINING_DAY_EDIT_PATH}:trainingDayId${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}:trainingDayGroupAssociationId${TRAINING_GROUP_EXERCISE_ASSOCIATION_ADD_PATH}:trainingExerciseId`}
          render={(props) => (
            <TrainingGroupExerciseAssociationAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_DAY_GROUP_ASSOCIATION_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_day_group_association_edit"
          path={`${routes.TRAINING_PERIOD_EDIT_PATH}:trainingPeriodId${TRAINING_DAY_EDIT_PATH}:trainingDayId${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}:trainingDayGroupAssociationId`}
          render={(props) => (
            <TrainingDayGroupAssociationEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_DAY_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_TRAINING_PERIOD_SERVICES_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_EXERCISE_GROUP_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_EXERCISE_CATEGORY_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_EXERCISE_FUNCTION_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_MUSCLE_GROUP_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_day_list"
          path={routes.TRAINING_DAY_LIST_PATH}
          render={(props) => (
            <TrainingDayList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_DAY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_day_edit"
          path={`${routes.TRAINING_PERIOD_EDIT_PATH}:trainingPeriodId${TRAINING_DAY_EDIT_PATH}:trainingDayId`}
          render={(props) => (
            <TrainingDayEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_period_list"
          path={routes.TRAINING_PERIOD_LIST_PATH}
          render={(props) => (
            <TrainingPeriodList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_TRAINING_PERIOD_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_period_add"
          path={routes.TRAINING_PERIOD_ADD_PATH}
          render={(props) => (
            <TrainingPeriodAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_period_edit"
          path={`${routes.TRAINING_PERIOD_EDIT_PATH}:trainingPeriodId`}
          render={(props) => (
            <TrainingPeriodEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getHomeTrainingRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_HOME_TRAINING_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:home_training_list"
          path={routes.HOME_TRAINING_LIST_PATH}
          render={(props) => (
            <HomeTrainingList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getClassBoardRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_DAY_PERMISSION_ID) && this.state.permission_ids.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:classboard"
          path={`${routes.PERSONAL_TRAINING_CLASSBOARD_PATH.replace('{student_id}', ':studentId')}:trainingDayId`}
          render={(props) => (
            <ClassBoard
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getPersonalTrainingRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_DAY_PERMISSION_ID) && this.state.permission_ids.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:personal_training:edit_entry"
          path={`${routes.PERSONAL_TRAINING_EDIT_PATH}:personalTrainingDataId`}
          render={(props) => (
            <PersonalTrainingEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:personal_training:performance_report"
          path={`${routes.PERSONAL_TRAINING_PERFORMANCE_GRAPH_PATH.replace('{student_id}', ':studentId')}:trainingDayId`}
          render={(props) => (
            <PersonalTrainingPerformanceReport
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getFinancialCategoryRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_FINANCIAL_CATEGORY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_category_list"
          path={routes.FINANCIAL_CATEGORY_LIST_PATH}
          render={(props) => (
            <FinancialCategoryList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_FINANCIAL_CATEGORY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_category_add"
          path={routes.FINANCIAL_CATEGORY_ADD_PATH}
          component={FinancialCategoryAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_FINANCIAL_CATEGORY_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_category_edit"
          path={`${routes.FINANCIAL_CATEGORY_EDIT_PATH}:financialCategoryId`}
          component={FinancialCategoryEdit}
        />
      );
    }

    return routeElements;
  }

  getTrainingPeriodScheduleRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.ADD_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_period_schedule_add"
          path={routes.TRAINING_SCHEDULE_ACTIVATE_PERIOD_PATH}
          component={TrainingScheduleActivePeriodAdd}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_period_schedule_edit"
          path={`${routes.TRAINING_SCHEDULE_EDIT_ACTIVE_PERIOD_PATH}:trainingPeriodScheduleId`}
          component={TrainingScheduleActivePeriodEdit}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_TRAINING_CALENDAR_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:training_period_schedule_manage"
          path={routes.TRAINING_SCHEDULE_MANAGE_PATH}
          render={(props) => (
            <TrainingPeriodSchedule
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getFeedbackRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_BASIC_FEEDBACK_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:feedback_list"
          path={routes.FEEDBACK_LIST_PATH}
          render={(props) => (
            <FeedbackList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_BASIC_FEEDBACK_PERMISSION_ID) && this.state.permission_ids.includes(permissions.VIEW_FEEDBACK_DATA_PERMISSION_ID)) {
      if(this.state.access_level < COACH_ACCESS_LEVEL) {
        routeElements.push(
          <Route
            key="route:feedback_report"
            path={routes.FEEDBACK_REPORT_PATH}
            component={FeedbackReport}
          />
        );
      }
      else {
        routeElements.push(
          <Redirect
            key="route:feedback_report_redirect"
            from={routes.FEEDBACK_REPORT_PATH}
            to={this.state.main_path}
          />
        );
      }

      routeElements.push(
        <Route
          key="route:feedback_edit"
          path={`${routes.FEEDBACK_EDIT_PATH}:feedbackId`}
          component={FeedbackEdit}
        />
      );
    }

    return routeElements;
  }

  getExitSurveyRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_EXIT_SURVEY_DATA_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:exit_survey_list"
          path={routes.EXIT_SURVEY_LIST_PATH}
          render={(props) => (
            <ExitSurveyList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:exit_survey_report"
          path={routes.EXIT_SURVEY_REPORT_PATH}
          component={ExitSurveyReport}
        />
      );

      routeElements.push(
        <Route
          key="route:exit_survey_edit"
          path={`${routes.EXIT_SURVEY_SHOW_PATH}:exitSurveySubmissionId`}
          component={ExitSurveyEdit}
        />
      );
    }

    return routeElements;
  }

  getWorkControlRoutes() {
    if(!this.isDefaultUnit()) {
      return [];
    }

    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_WORK_SCHEDULE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:work_schedule_list"
          path={routes.WORK_SCHEDULE_LIST_PAGE}
          render={(props) => (
            <WorkScheduleList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_WORK_SCHEDULE_PERMISSION_ID) && this.state.permission_ids.includes(permissions.VIEW_STAFF_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:work_schedule_add"
          path={routes.WORK_SCHEDULE_ADD_PAGE}
          render={(props) => (
            <WorkScheduleAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_WORK_SCHEDULE_PERMISSION_ID) && this.state.permission_ids.includes(permissions.VIEW_STAFF_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:work_schedule_edit"
          path={`${routes.WORK_SCHEDULE_EDIT_PAGE}:workScheduleId`}
          render={(props) => (
            <WorkScheduleEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_WORK_EXCEPTION_EVENT_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:work_exception_event_list"
          path={routes.WORK_EXCEPTION_EVENT_LIST_PATH}
          render={(props) => (
            <WorkExceptionEventList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_WORK_EXCEPTION_EVENT_PERMISSION) && this.state.permission_ids.includes(permissions.VIEW_STAFF_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:work_exception_event_add"
          path={routes.WORK_EXCEPTION_EVENT_ADD_PATH}
          render={(props) => (
            <WorkExceptionEventAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_WORK_EXCEPTION_EVENT_PERMISSION) && this.state.permission_ids.includes(permissions.VIEW_STAFF_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:work_exception_event_edit"
          path={`${routes.WORK_EXCEPTION_EVENT_EDIT_PATH}:workExceptionEventId`}
          render={(props) => (
            <WorkExceptionEventEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getFinancialRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_report"
          path={`${routes.FINANCIAL_REPORT_PATH}`}
          render={(props) => (
            <FinancialGeneralReport
              {...props}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:financial_movement_income_list"
          path={routes.INCOME_LIST_PATH}
          render={(props) => (
            <FinancialMovementList
              {...props}
              isExpense={false}
              key="financial-movement-income-list"
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:financial_movement_expense_list"
          path={routes.EXPENSE_LIST_PATH}
          render={(props) => (
            <FinancialMovementList
              {...props}
              isExpense={true}
              key="financial-movement-expense-list"
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_STUDENT_FINANCIAL_ENTRIES_PERMISSION_ID) || this.state.permission_ids.includes(permissions.ADD_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_movement_income_add"
          path={routes.INCOME_ADD_PATH}
          render={(props) => (
            <FinancialMovementAdd
              {...props}
              isExpense={false}
              key="financial-movement-income-add"
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );

      routeElements.push(
        <Route
          key="route:financial_movement_expense_add"
          path={routes.EXPENSE_ADD_PATH}
          render={(props) => (
            <FinancialMovementAdd
              {...props}
              isExpense={true}
              key="financial-movement-expense-add"
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if (this.state.permission_ids.includes(permissions.ADD_STUDENT_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_movement_income_edit"
          path={`${routes.INCOME_EDIT_PATH}:financialMovementId`}
          render={(props) => (
            <FinancialMovementEdit
              {...props}
              isExpense={true}
              key="financial-movement-income-edit"
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_movement_expense_edit"
          path={`${routes.EXPENSE_EDIT_PATH}:financialMovementId`}
          render={(props) => (
            <FinancialMovementEdit
              {...props}
              isExpense={true}
              key="financial-movement-expense-edit"
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if (this.isDefaultUnit()) {
      if(this.state.permission_ids.includes(permissions.VIEW_PAYMENT_DEVICE_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:payment_device_list"
            path={routes.PAYMENT_DEVICE_LIST_PATH}
            render={(props) => (
              <PaymentDeviceList
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
      if(this.state.permission_ids.includes(permissions.ADD_PAYMENT_DEVICE_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:payment_device_add"
            path={routes.PAYMENT_DEVICE_ADD_PATH}
            render={(props) => (
              <PaymentDeviceAdd
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
      if(this.state.permission_ids.includes(permissions.EDIT_PAYMENT_DEVICE_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:payment_device_edit"
            path={`${routes.PAYMENT_DEVICE_EDIT_PATH}:paymentDeviceId`}
            render={(props) => (
              <PaymentDeviceEdit
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
    }

    return routeElements;
  }

  getBoxUnitRoutes() {
    const routeElements = [];

    if (this.isDefaultUnit()) {
      if(this.state.permission_ids.includes(permissions.VIEW_OPERATIONAL_TASK_COMPLETE_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:operational_task_list"
            path={routes.OPERATIONAL_TASK_LIST_PAGE}
            render={(props) => (
              <OperationalTaskList
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
      if(this.state.permission_ids.includes(permissions.ADD_OPERATIONAL_TASK_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:operational_task_add"
            path={routes.OPERATIONAL_TASK_ADD_PAGE}
            render={(props) => (
              <OperationalTaskAdd
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
      if(this.state.permission_ids.includes(permissions.EDIT_OPERATIONAL_TASK_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:operational_task_edit"
            path={`${routes.OPERATIONAL_TASK_EDIT_PAGE}:operationalTaskId`}
            render={(props) => (
              <OperationalTaskEdit
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
    }

    if(this.state.permission_ids.includes(permissions.VIEW_FINANCIAL_SEARCH_TAG_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_search_tag_list"
          path={routes.FINANCIAL_SEARCH_TAG_LIST_PAGE}
          render={(props) => (
            <FinancialSearchTagList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.ADD_FINANCIAL_SEARCH_TAG_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_search_tag_add"
          path={routes.FINANCIAL_SEARCH_TAG_ADD_PAGE}
          render={(props) => (
            <FinancialSearchTagAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.EDIT_FINANCIAL_SEARCH_TAG_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:financial_search_tag_edit"
          path={`${routes.FINANCIAL_SEARCH_TAG_EDIT_PAGE}:financialSeachTagId`}
          render={(props) => (
            <FinancialSearchTagEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getReportRoutes() {
    const routeElements = [];

    if(this.isDefaultUnit() &&
       this.state.permission_ids.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_BASIC_FEEDBACK_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_CONTRACT_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:sales_report"
          path={`${routes.SALES_REPORT_PATH}`}
          render={(props) => (
            <SalesReport
              {...props}
              unit_type_id={this.state.unit_type_id}
              userPermissionIds={this.state.permission_ids}
            />
          )}
        />
      );
    }

    if(this.isDefaultUnit() &&
       this.state.permission_ids.includes(permissions.VIEW_TRAINING_CLASS_DATA_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_TRAINING_DATA_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_TRAINING_TIME_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_COACH_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:class_report"
          path={`${routes.CLASS_REPORT_PATH}`}
          render={(props) => (
            <ClassReport
              {...props}
              userPermissionIds={this.state.permission_ids}
            />
          )}
        />
      );
    }

    if(this.isDefaultUnit() &&
       this.state.permission_ids.includes(permissions.VIEW_SATISFACTION_REPORT_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:satisfaction_report"
          path={`${routes.SATISFACTION_REPORT_PATH}`}
          render={(props) => (
            <SatisfactionReport
              {...props}
              userPermissionIds={this.state.permission_ids}
            />
          )}
        />
      );
    }

    if(this.isDefaultUnit() &&
       this.state.permission_ids.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_prescription_report"
          path={routes.FOOD_PRESCRIPTION_SERVICE_REPORT_PATH}
          render={(props) => (
            <FoodPrescriptionReport
              {...props}
              userPermissionIds={this.state.permission_ids}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID) &&
       this.state.permission_ids.includes(permissions.VIEW_PERSONAL_TRAINING_DATA_PERMISSION) &&
       this.state.permission_ids.includes(permissions.VIEW_PERSONAL_TRAINING_PERIOD_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:training_period_service_report"
          path={routes.PERSONAL_TRAINING_SERVICE_REPORT_PATH}
          render={(props) => (
            <PersonalTrainingServiceReport
              {...props}
              userPermissionIds={this.state.permission_ids}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:payment_report"
          path={routes.PAYMENT_REPORT_PAGE}
          render={(props) => (
            <PaymentReport
              {...props}
              userPermissionIds={this.state.permission_ids}
            />
          )}
        />
      );
    }

    if(this.isDefaultUnit() && this.state.permission_ids.includes(permissions.GENERATE_NF_COUPON_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:nf_coupon_report"
          path={routes.NF_COUPON_REPORT_PATH}
          render={(props) => (
            <NfCouponReport
              {...props}
              userPermissionIds={this.state.permission_ids}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getWearableRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_WEARABLE_DEVICE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:hr_device_list"
          path={routes.HR_DEVICE_LIST_PATH}
          render={(props) => (
            <HrDeviceList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_WEARABLE_DEVICE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:hr_device_add"
          path={routes.HR_DEVICE_ADD_PATH}
          render={(props) => (
            <HrDeviceAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_WEARABLE_DEVICE_PERMISSION_ID) || this.state.permission_ids.includes(permissions.EDIT_WEARABLE_DEVICE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:hr_device_edit"
          path={`${routes.HR_DEVICE_EDIT_PATH}:deviceId`}
          render={(props) => (
            <HrDeviceEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getAppointmentRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_APPOINTMENT_ROOM_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:appointment_room_list"
          path={routes.APPOINTMENT_ROOM_LIST_PATH}
          render={(props) => (
            <AppointmentRoomList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.ADD_APPOINTMENT_ROOM_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:appointment_room_add"
          path={routes.APPOINTMENT_ROOM_ADD_PATH}
          render={(props) => (
            <AppointmentRoomAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.EDIT_APPOINTMENT_ROOM_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:appointment_room_edit"
          path={`${routes.APPOINTMENT_ROOM_EDIT_PATH}:appointmentRoomId`}
          render={(props) => (
            <AppointmentRoomEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_OWN_APPOINTMENT_SCHEDULE_PERMISSION_ID) ||
       this.state.permission_ids.includes(permissions.VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:appointment_schedule_list"
          path={routes.APPOINTMENT_SCHEDULE_LIST_PATH}
          render={(props) => (
            <AppointmentScheduleList
              {...props}
              userId={this.state.user_id}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.ADD_APPOINTMENT_SCHEDULE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:appointment_schedule_add"
          path={routes.APPOINTMENT_SCHEDULE_ADD_PATH}
          render={(props) => (
            <AppointmentScheduleAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
              userId={this.state.user_id}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.EDIT_APPOINTMENT_SCHEDULE_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:appointment_schedule_edit"
          path={`${routes.APPOINTMENT_SCHEDULE_EDIT_PATH}:appointmentScheduleId`}
          render={(props) => (
            <AppointmentScheduleEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
              unit_type_id={this.state.unit_type_id}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.MANAGE_OWN_APPOINTMENT_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:appointment_list"
          path={routes.APPOINTMENT_LIST_PATH}
          render={(props) => (
            <AppointmentList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }
    if(this.state.permission_ids.includes(permissions.MANAGE_OWN_APPOINTMENT_PERMISSION_ID)) {
      routeElements.push(
        <Route
          key="route:appointment_edit"
          path={`${routes.APPOINTMENT_EDIT_PATH}:appointmentId`}
          render={(props) => (
            <AppointmentEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getFydFoodRoutes() {
    const routeElements = [];

    if(this.state.permission_ids.includes(permissions.VIEW_FOOD_CLASSIFICATION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_classification_list"
          path={routes.FOOD_CLASSIFICATION_LIST_PATH}
          render={(props) => (
            <FoodClassificationList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_FOOD_CLASSIFICATION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_classification_add"
          path={routes.FOOD_CLASSIFICATION_ADD_PATH}
          render={(props) => (
            <FoodClassificationAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_FOOD_CLASSIFICATION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_classification_edit"
          path={`${routes.FOOD_CLASSIFICATION_EDIT_PATH}:foodClassificationId`}
          render={(props) => (
            <FoodClassificationEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_FOOD_INGREDIENT_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:fyd_food_ingredients_list"
          path={routes.FOOD_INGREDIENT_LIST_PATH}
          render={(props) => (
            <FoodIngredientList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_FOOD_INGREDIENT_PERMISSION) || this.state.permission_ids.includes(permissions.EDIT_FOOD_INGREDIENT_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:fyd_food_ingredients_edit"
          path={`${routes.FOOD_INGREDIENT_EDIT_PATH}:foodIngredientId`}
          render={(props) => (
            <FoodIngredientEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_FOOD_INGREDIENT_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:fyd_food_ingredients_add"
          path={routes.FOOD_INGREDIENT_ADD_PATH}
          render={(props) => (
            <FoodIngredientAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_MEAL_CLASSIFICATION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:meal_classification_list"
          path={routes.MEAL_CLASSIFICATION_LIST_PATH}
          render={(props) => (
            <MealClassificationList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_MEAL_CLASSIFICATION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:meal_classification_add"
          path={routes.MEAL_CLASSIFICATION_ADD_PATH}
          render={(props) => (
            <MealClassificationAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_MEAL_CLASSIFICATION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:meal_classification_edit"
          path={`${routes.MEAL_CLASSIFICATION_EDIT_PATH}:mealClassificationId`}
          render={(props) => (
            <MealClassificationEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_recipe_list"
          path={routes.FOOD_RECIPE_LIST_PATH}
          render={(props) => (
            <FoodRecipeList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_FOOD_RECIPE_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_recipe_add"
          path={routes.FOOD_RECIPE_ADD_PATH}
          render={(props) => (
            <FoodRecipeAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_FOOD_RECIPE_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_recipe_edit"
          path={`${routes.FOOD_RECIPE_EDIT_PATH}:foodRecipeId`}
          render={(props) => (
            <FoodRecipeEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_MEAL_PERIOD_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:meal_period_list"
          path={routes.MEAL_PERIOD_LIST_PATH}
          render={(props) => (
            <MealPeriodList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_MEAL_PERIOD_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:meal_period_add"
          path={routes.MEAL_PERIOD_ADD_PATH}
          render={(props) => (
            <MealPeriodAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_MEAL_PERIOD_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:meal_period_edit"
          path={`${routes.MEAL_PERIOD_EDIT_PATH}:mealPeriodId`}
          render={(props) => (
            <MealPeriodEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_prescription_list"
          path={routes.FOOD_PRESCRIPTION_LIST_PATH}
          render={(props) => (
            <FoodPrescriptionList
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.ADD_FOOD_PRESCRIPTION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_prescription_add"
          path={routes.FOOD_PRESCRIPTION_ADD_PATH}
          render={(props) => (
            <FoodPrescriptionAdd
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    if(this.state.permission_ids.includes(permissions.EDIT_FOOD_PRESCRIPTION_PERMISSION)) {
      routeElements.push(
        <Route
          key="route:food_prescription_edit"
          path={`${routes.FOOD_PRESCRIPTION_EDIT_PATH}:foodPrescriptionId`}
          render={(props) => (
            <FoodPrescriptionEdit
              {...props}
              userPermissionIds={this.state.permission_ids}
              userAccessLevel={this.state.access_level}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  getAcessControlRoutes() {
    const routeElements = [];

    if (this.isDefaultUnit()) {
      if(this.state.permission_ids.includes(permissions.VIEW_ACCESS_CONTROL_DEVICE_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:access_control_device_list"
            path={routes.ACCESS_CONTROL_DEVICE_LIST_PATH}
            render={(props) => (
              <AccessControlDeviceList
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
      if(this.state.permission_ids.includes(permissions.ADD_ACCESS_CONTROL_DEVICE_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:access_control_device_add"
            path={routes.ACCESS_CONTROL_DEVICE_ADD_PATH}
            render={(props) => (
              <AccessControlDeviceAdd
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
      if(this.state.permission_ids.includes(permissions.EDIT_ACCESS_CONTROL_DEVICE_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:access_control_device_edit"
            path={`${routes.ACCESS_CONTROL_DEVICE_EDIT_PATH}:accessControlDeviceId`}
            render={(props) => (
              <AccessControlDeviceEdit
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
      if(this.state.permission_ids.includes(permissions.VIEW_STAFF_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="route:staff_access_control_list"
            path={routes.STAFF_ACCESS_CONTROL_LIST_PATH}
            render={(props) => (
              <StaffAccessList
                {...props}
                userPermissionIds={this.state.permission_ids}
                userAccessLevel={this.state.access_level}
              />
            )}
          />
        );
      }
    }

    return routeElements;
  }

  render() {
    return !this.state.pageLoaded ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <BrowserRouter>

        <React.Fragment>

          {this.state.authenticated ?
            (
              <Switch>

                {this.getClassBoardRoutes()}

                <Route
                  path="/"
                  render={(props) => (
                    <AdminFrame
                      {...props}
                      username={this.state.username}
                      userPermissionIds={this.state.permission_ids}
                      userAccessLevel={this.state.access_level}
                      unit_type_id={this.state.unit_type_id}
                      unit_name={this.state.unit_name}
                      onLogout={() => this.onLogout()}
                      experimental_classes_count={this.state.experimental_classes.filter((entry) => entry.checked_in === null).length}
                      client_leads_count={this.state.client_leads.length}
                    >

                      <Switch>

                        <Route
                          path={routes.DESKTOP_PATH}
                          render={(props) => (
                            <Desktop
                              {...props}
                              userId={this.state.user_id}
                              userPermissionIds={this.state.permission_ids}
                              userAccessLevel={this.state.access_level}
                              unit_type_id={this.state.unit_type_id}
                            />
                          )}
                        />

                        {this.getExperimentalClassRoutes()}

                        {this.getTrainingTimeRoutes()}

                        {this.getStudentRoutes()}

                        {this.getUserRoutes()}

                        {this.getServicePlansRoutes()}

                        {this.getCostCenterRoutes()}

                        {this.getFinancialCategoryRoutes()}

                        {this.getFeedbackRoutes()}

                        {this.getExitSurveyRoutes()}

                        {this.getWorkControlRoutes()}

                        {this.getFinancialRoutes()}

                        {/* {this.getPhysicalExamDayRoutes()} */}

                        {this.getAppointmentRoutes()}

                        {this.getPhysicalEvaluationDataRoutes()}

                        {this.getExerciseFunctionRoutes()}

                        {this.getMuscleGroupRoutes()}

                        {this.getExerciseCategoryRoutes()}

                        {this.getExerciseActivityTypeRoutes()}

                        {this.getExerciseActivityRoutes()}

                        {this.getTrainingExerciseRoutes()}

                        {this.getTrainingPeriodRoutes()}

                        {this.getHomeTrainingRoutes()}

                        {this.getTrainingPeriodScheduleRoutes()}

                        {this.getCoachRoutes()}

                        {this.getRoleExperienceLevelRoutes()}

                        {this.getCustomerFeedbackRoutes()}

                        {this.getCRMRoutes()}

                        {this.getReceptionistRoutes()}

                        {this.getNutritionistRoutes()}

                        {this.getUserDeviceRoutes()}

                        {this.getPersonalTrainingRoutes()}

                        {this.getWearableRoutes()}

                        {this.getFydFoodRoutes()}

                        {this.getReportRoutes()}

                        {this.getBoxUnitRoutes()}

                        {this.getAcessControlRoutes()}

                        <Redirect from="/" to={this.state.main_path} />

                      </Switch>

                    </AdminFrame>
                  )}
                />

              </Switch>
            ):
            (
              <Switch>

                <Route
                  path={`${routes.USER_VALIDATE_EMAIL_PATH}:authHash`}
                  render={(props) => (
                    <ValidateAccount
                      key="route:user_validade_email"
                      {...props}
                    />
                  )}
                />

                <Route
                  path={`${routes.PASSWORD_RECOVERY_PATH}:authHash`}
                  render={(props) => (
                    <PasswordRecovery
                      key="route:password_recovery"
                      {...props}
                    />
                  )}
                />

                <Route
                  path="/"
                  render={(props) => (
                    <Login
                      {...props}
                      onAuthenticate= {(data) => this.onAuthenticate(data)}
                    />
                  )}
                />

              </Switch>
            )
          }

        </React.Fragment>

      </BrowserRouter>
    );
  }
}

ReactDOM.render((
  <App />
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
