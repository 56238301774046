import React from 'react';
import { Link } from 'react-router-dom';
import './admin_frame.scss';
import logo from '../images/logo_fyd.png';
import {MenuItem, ExpandableOrganizerMenuItem, AccordionMenuItem, SubMenuItem} from './menu_items'; //OrganizerMenuItem
import * as routes from '../constants';
import * as permissions from '../permissions';
import {COACH_ACCESS_LEVEL, DEFAULT_UNIT_TYPE} from '../constants';


class AdminFrame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpened: window.innerWidth > 970,
    };

    window.beforeToggleMenuCallbacks = new Set();
  }

  async componentDidMount() {
  }

  toggleMenu() {
    if(window.beforeToggleMenuCallbacks && window.beforeToggleMenuCallbacks.size) {
      for(let callback of window.beforeToggleMenuCallbacks) {
        callback(!this.state.menuOpened);
      }
    }

    this.setState({
      menuOpened: !this.state.menuOpened
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.location.pathname !== prevProps.location.pathname && this.state.menuOpened && window.innerWidth <= 970) {
      this.setState({
        menuOpened: false
      });
    }
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  render() {
    return (
      <div className="admin-frame">

        <header className="admin-frame__header">

          <div className="admin-frame__header__content-wrapper">

            <div className="admin-frame__header__logo-container">

              <img className="admin-frame__header__logo" src={logo} alt="Logo da FYD" />
              <p className="admin-frame__header__logo-text">FYD FIT</p>

            </div>

            <button
              className="admin-frame__header__menu-button"
              onClick={() => this.toggleMenu()}
            >

              <span className="admin-frame__header__menu-icon"></span>

            </button>

          </div>


          <div className="admin-frame__header__controls-wrapper">

            {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_CLIENT_LEAD_PERMISSION_ID)) &&
              <Link
                className="admin-frame__header__counter-button"
                to={routes.CRM_CLIENT_LEAD_LIST_PATH}
              >

                <i className="fa-solid fa-envelope"></i>

                <div className="admin-frame__header__counter-button__counter--red">

                  <p>{this.props.client_leads_count}</p>

                </div>

              </Link>
            }

            {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID)) &&
              <Link
                className="admin-frame__header__counter-button"
                to={routes.EXPERIMENTAL_CLASS_SCHEDULED_PATH}
              >

                <i className="fas fa-user-plus"></i>

                <div className="admin-frame__header__counter-button__counter">

                  <p>{this.props.experimental_classes_count}</p>

                </div>

              </Link>
            }

            <div className="admin-frame__header__identifiers">

              <p className="admin-frame__header__user-name">{this.props.username}</p>
              {this.isDefaultUnit() && this.props.unit_name &&
                <p className="admin-frame__header__unit-name">{this.props.unit_name}</p>
              }

            </div>

            <button
              className="admin-frame__header__logout-button"
              onClick={() => this.props.onLogout()}
            >

              <i className="fas fa-sign-out-alt"></i>

            </button>

          </div>

        </header>

        <div className="admin-frame__content-wrapper">

          <aside className={`admin-frame__menu${this.state.menuOpened ? '' : '--hidden'}`}>

            <ul className="admin-frame__menu__list">

              <MenuItem
                text="Área de trabalho"
                linkTo={routes.DESKTOP_PATH}
                location={this.props.location}
                icon={<i className="fas fa-home menu-items__default-item__icon"></i>}
              />

              <ExpandableOrganizerMenuItem
                text="Relatórios"
              >

                <MenuItem
                  text="Relatório geral"
                  linkTo={routes.STUDENT_REPORT_PATH}
                  location={this.props.location}
                  icon={<i className="fas fa-chart-line menu-items__default-item__icon"></i>}
                />

                {this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID) &&
                  <MenuItem
                    text="Relatório financeiro"
                    linkTo={routes.FINANCIAL_REPORT_PATH}
                    location={this.props.location}
                    icon={<i className="fas fa-chart-line menu-items__default-item__icon"></i>}
                  />
                }

                {(this.isDefaultUnit() &&
                  this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID) &&
                  this.props.userPermissionIds.includes(permissions.VIEW_BASIC_FEEDBACK_PERMISSION_ID) &&
                  this.props.userPermissionIds.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID) &&
                  this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID) &&
                  this.props.userPermissionIds.includes(permissions.VIEW_CONTRACT_PERMISSION_ID)) &&
                  <MenuItem
                    text="Relatório de vendas"
                    linkTo={routes.SALES_REPORT_PATH}
                    location={this.props.location}
                    icon={<i className="fas fa-chart-line menu-items__default-item__icon"></i>}
                  />
                }

                {(this.isDefaultUnit() &&
                  this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_CLASS_DATA_PERMISSION_ID) &&
                  this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_DATA_PERMISSION_ID) &&
                  this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_TIME_PERMISSION_ID) &&
                  this.props.userPermissionIds.includes(permissions.VIEW_COACH_PERMISSION_ID)) &&
                  <MenuItem
                    text="Relatório de aulas"
                    linkTo={routes.CLASS_REPORT_PATH}
                    location={this.props.location}
                    icon={<i className="fas fa-chart-line menu-items__default-item__icon"></i>}
                  />
                }

                {(this.isDefaultUnit() &&
                  this.props.userPermissionIds.includes(permissions.VIEW_SATISFACTION_REPORT_PERMISSION_ID)) &&
                  <MenuItem
                    text="Relatório de satisfação"
                    linkTo={routes.SATISFACTION_REPORT_PATH}
                    location={this.props.location}
                    icon={<i className="fas fa-chart-line menu-items__default-item__icon"></i>}
                  />
                }

              </ExpandableOrganizerMenuItem>

              <ExpandableOrganizerMenuItem
                text="Cadastros"
              >

                {(this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_STUDENT_PERMISSION_ID)) &&
                  <AccordionMenuItem
                    text="Alunos"
                    icon={<i className="fas fa-user menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar alunos"
                        linkTo={routes.STUDENT_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.ADD_STUDENT_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Adicionar aluno"
                        linkTo={routes.STUDENT_ADD_PATH}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

                {(this.isDefaultUnit() && (this.props.userPermissionIds.includes(permissions.MANAGE_EXPERIMENTAL_CLASS_SCHEDULE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID))) &&
                  <AccordionMenuItem
                    text="Aulas experimentais"
                    icon={<i className="fas fa-calendar-day menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Agendadas"
                        linkTo={routes.EXPERIMENTAL_CLASS_SCHEDULED_PATH}
                        statusIcon={
                          <div className="admin-frame__menu__counter-icon">

                            <p>{this.props.experimental_classes_count}</p>

                          </div>
                        }
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Completas"
                        linkTo={routes.EXPERIMENTAL_CLASS_COMPLETED_PATH}
                        location={this.props.location}
                      />
                    }

                    {/* {this.props.userPermissionIds.includes(permissions.MANAGE_EXPERIMENTAL_CLASS_SCHEDULE_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Dias & horários"
                        linkTo={routes.EXPERIMENTAL_CLASS_AVAILABLE_DAYS_PATH}
                        location={this.props.location}
                      />
                    } */}

                  </AccordionMenuItem>
                }

                {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_CLIENT_LEAD_PERMISSION_ID)) &&
                  <AccordionMenuItem
                    text="CRM"
                    icon={<i className="fa-solid fa-headset menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_CLIENT_LEAD_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Contatos do site"
                        linkTo={routes.CRM_CLIENT_LEAD_LIST_PATH}
                        statusIcon={
                          <div className="admin-frame__menu__counter-icon--red">

                            <p>{this.props.client_leads_count}</p>

                          </div>
                        }
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

                {/* {(this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EXAM_DAY_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) &&
                  <AccordionMenuItem
                    text="Avaliação física"
                    icon={<i className="fas fa-weight menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EXAM_DAY_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Gerenciar dias"
                        linkTo={routes.PHYSICAL_EVALUATION_EXAM_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Exames agendados"
                        linkTo={routes.PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                } */}

                {(this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_ROOM_PERMISSION_ID) ||
                  this.props.userPermissionIds.includes(permissions.VIEW_OWN_APPOINTMENT_SCHEDULE_PERMISSION_ID) ||
                  this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID) ||
                  this.props.userPermissionIds.includes(permissions.MANAGE_OWN_APPOINTMENT_PERMISSION_ID)) &&
                  <AccordionMenuItem
                    text="Atendimento"
                    icon={<i className="fa-solid fa-calendar-check menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_ROOM_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar salas"
                        linkTo={routes.APPOINTMENT_ROOM_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                    {(this.props.userPermissionIds.includes(permissions.VIEW_OWN_APPOINTMENT_SCHEDULE_PERMISSION_ID) ||
                      this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID)) &&
                      <SubMenuItem
                        text="Calendário"
                        linkTo={routes.APPOINTMENT_SCHEDULE_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.MANAGE_OWN_APPOINTMENT_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar atendimentos"
                        linkTo={routes.APPOINTMENT_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

                {(this.isDefaultUnit() && (this.props.userPermissionIds.includes(permissions.VIEW_BASIC_FEEDBACK_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.VIEW_FEEDBACK_DATA_PERMISSION_ID))) &&
                  <AccordionMenuItem
                    text="Pesquisa de satisfação"
                    icon={<i className="fas fa-smile menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_BASIC_FEEDBACK_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar pesquisas"
                        linkTo={routes.FEEDBACK_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                    {(this.props.userPermissionIds.includes(permissions.VIEW_FEEDBACK_DATA_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) &&
                      <SubMenuItem
                        text="Relatório geral"
                        linkTo={routes.FEEDBACK_REPORT_PATH}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

                {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_EXIT_SURVEY_DATA_PERMISSION_ID)) &&
                  <AccordionMenuItem
                    text="Pesq. cancelamento"
                    icon={<i className="fa-solid fa-face-angry menu-items__accordion-item__icon"></i>}
                  >

                    <SubMenuItem
                      text="Listar pesquisas"
                      linkTo={routes.EXIT_SURVEY_LIST_PATH}
                      location={this.props.location}
                    />

                    <SubMenuItem
                      text="Relatório geral"
                      linkTo={routes.EXIT_SURVEY_REPORT_PATH}
                      location={this.props.location}
                    />

                  </AccordionMenuItem>
                }

                {(this.isDefaultUnit() && (this.props.userPermissionIds.includes(permissions.VIEW_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.VIEW_CUSTOMER_FEEDBACK_PERMISSION_ID))) &&
                  <AccordionMenuItem
                    text="Feedback de cliente"
                    icon={<i className="fa-solid fa-comment menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar assuntos"
                        linkTo={routes.CUSTOMER_FEEDBACK_TARGET_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.VIEW_CUSTOMER_FEEDBACK_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar feedbacks"
                        linkTo={routes.CUSTOMER_FEEDBACK_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

                {(this.props.userPermissionIds.includes(permissions.VIEW_SERVICE_PLAN_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_SERVICE_PLAN_PERMISSION_ID)) &&
                  <AccordionMenuItem
                    text="Serviços"
                    icon={<i className="fas fa-concierge-bell menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_SERVICE_PLAN_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar serviços"
                        linkTo={routes.SERVICE_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.ADD_SERVICE_PLAN_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Adicionar serviço"
                        linkTo={routes.SERVICE_ADD_PATH}
                        location={this.props.location}
                      />
                    }

                    {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_SERVICE_PLAN_PERMISSION_ID)) &&
                      <SubMenuItem
                        text="Listar agrupamentos"
                        linkTo={routes.SERVICE_GROUP_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

                {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.MANAGE_TRAINING_TIME_PERMISSION_ID)) &&
                  <MenuItem
                    text="Horários de treino"
                    linkTo={routes.MANAGE_TRAINING_TIMES_PATH}
                    location={this.props.location}
                    icon={<i className="fas fa-clock menu-items__default-item__icon"></i>}
                  />
                }

                {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_STAFF_PERMISSION_ID) &&
                    (this.props.userPermissionIds.includes(permissions.VIEW_WORK_SCHEDULE_PERMISSION_ID) ||
                     this.props.userPermissionIds.includes(permissions.VIEW_WORK_EXCEPTION_EVENT_PERMISSION))) &&
                  <AccordionMenuItem
                    text="Agenda de trabalho"
                    icon={<i className="fa-solid fa-briefcase menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_WORK_SCHEDULE_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar horários"
                        linkTo={routes.WORK_SCHEDULE_LIST_PAGE}
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.VIEW_WORK_EXCEPTION_EVENT_PERMISSION) &&
                      <SubMenuItem
                        text="Listar imprevistos"
                        linkTo={routes.WORK_EXCEPTION_EVENT_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

                {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_PROMOTIONAL_COUPON_PERMISSION_ID)) &&
                  <AccordionMenuItem
                    text="Cupons de desconto"
                    icon={<i className="fa-solid fa-ticket menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_PROMOTIONAL_COUPON_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar cupons"
                        linkTo={routes.PROMOTIONAL_COUPON_LIST_PATH}
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.ADD_PROMOTIONAL_COUPON_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Adicionar cupom"
                        linkTo={routes.PROMOTIONAL_COUPON_ADD_PATH}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

                {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_OPERATIONAL_TASK_COMPLETE_PERMISSION_ID)) &&
                  <AccordionMenuItem
                    text="Pendências e tarefas"
                    icon={<i className="fa-solid fa-list-check menu-items__accordion-item__icon"></i>}
                  >

                    {this.props.userPermissionIds.includes(permissions.VIEW_OPERATIONAL_TASK_COMPLETE_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Listar pendências"
                        linkTo={routes.OPERATIONAL_TASK_LIST_PAGE}
                        location={this.props.location}
                      />
                    }

                    {this.props.userPermissionIds.includes(permissions.ADD_OPERATIONAL_TASK_PERMISSION_ID) &&
                      <SubMenuItem
                        text="Adicionar pendência"
                        linkTo={routes.OPERATIONAL_TASK_ADD_PAGE}
                        location={this.props.location}
                      />
                    }

                  </AccordionMenuItem>
                }

              </ExpandableOrganizerMenuItem>

              {((this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) ||
                this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_ENTRIES_PERMISSION_ID) ||
                (this.props.userPermissionIds.includes(permissions.VIEW_COST_CENTER_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) ||
                this.props.userPermissionIds.includes(permissions.ADD_COST_CENTER_PERMISSION_ID) ||
                (this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_CATEGORY_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) ||
                this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_CATEGORY_PERMISSION_ID) ||
                (this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_SEARCH_TAG_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) ||
                this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_SEARCH_TAG_PERMISSION_ID)) &&
                <ExpandableOrganizerMenuItem
                 text="Financeiro"
                >

                  {(this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_ENTRIES_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Movimentações"
                      icon={<i className="fas fa-dollar-sign menu-items__accordion-item__icon"></i>}
                    >
                      {this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID) &&
                        <React.Fragment>

                          <SubMenuItem
                            text="Listar receitas"
                            linkTo={routes.INCOME_LIST_PATH}
                            location={this.props.location}
                          />

                          <SubMenuItem
                            text="Listar despesas"
                            linkTo={routes.EXPENSE_LIST_PATH}
                            location={this.props.location}
                          />

                        </React.Fragment>
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_ENTRIES_PERMISSION_ID) &&
                        <React.Fragment>

                          <SubMenuItem
                            text="Adicionar receita"
                            linkTo={routes.INCOME_ADD_PATH}
                            location={this.props.location}
                          />

                          <SubMenuItem
                            text="Adicionar despesa"
                            linkTo={routes.EXPENSE_ADD_PATH}
                            location={this.props.location}
                          />

                        </React.Fragment>
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userAccessLevel < COACH_ACCESS_LEVEL && (this.props.userPermissionIds.includes(permissions.VIEW_COST_CENTER_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_COST_CENTER_PERMISSION_ID))) &&
                    <AccordionMenuItem
                      text="Centros de custo"
                      icon={<i className="fas fa-box menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_COST_CENTER_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar centros"
                          linkTo={routes.COST_CENTER_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_COST_CENTER_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar centro"
                          linkTo={routes.COST_CENTER_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userAccessLevel < COACH_ACCESS_LEVEL && (this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_CATEGORY_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_CATEGORY_PERMISSION_ID))) &&
                    <AccordionMenuItem
                      text="Categorias financeiras"
                      icon={<i className="fas fa-tags menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_CATEGORY_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar categorias"
                          linkTo={routes.FINANCIAL_CATEGORY_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_CATEGORY_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar categoria"
                          linkTo={routes.FINANCIAL_CATEGORY_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userAccessLevel < COACH_ACCESS_LEVEL && (this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_SEARCH_TAG_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_SEARCH_TAG_PERMISSION_ID))) &&
                    <AccordionMenuItem
                      text="Palavras-chave / Tags"
                      icon={<i className="fa-solid fa-magnifying-glass menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_SEARCH_TAG_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listas tags"
                          linkTo={routes.FINANCIAL_SEARCH_TAG_LIST_PAGE}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_SEARCH_TAG_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar tag"
                          linkTo={routes.FINANCIAL_SEARCH_TAG_ADD_PAGE}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID)) &&
                    <MenuItem
                      text="Gerenciar contas"
                      linkTo={routes.PAYMENT_REPORT_PAGE}
                      location={this.props.location}
                      icon={<i className="fa-solid fa-list-check menu-items__default-item__icon"></i>}
                    />
                  }

                  {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.GENERATE_NF_COUPON_PERMISSION_ID)) &&
                    <MenuItem
                      text="Emitir nota fiscal"
                      linkTo={routes.NF_COUPON_REPORT_PATH}
                      location={this.props.location}
                      icon={<i className="fa-solid fa-receipt menu-items__default-item__icon"></i>}
                    />
                  }

                </ExpandableOrganizerMenuItem>
              }

              {(this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_ACTIVITY_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_CATEGORY_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_CATEGORY_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_FUNCTION_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_FUNCTION_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.VIEW_MUSCLE_GROUP_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.ADD_MUSCLE_GROUP_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.ADD_TRAINING_EXERCISE_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.ADD_TRAINING_PERIOD_PERMISSION_ID) ||
                this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_CALENDAR_PERMISSION_ID)) &&
                <ExpandableOrganizerMenuItem
                 text="Periodização"
                >

                  {(this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID) ||
                    this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_PERMISSION_ID) ||
                    this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_CATEGORY_PERMISSION_ID) ||
                    this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_FUNCTION_PERMISSION_ID) ||
                    this.props.userPermissionIds.includes(permissions.VIEW_MUSCLE_GROUP_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Classificações"
                      icon={<i className="fas fa-tags menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Capacidades"
                          linkTo={routes.EXERCISE_ACTIVITY_TYPE_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Atividades"
                          linkTo={routes.EXERCISE_ACTIVITY_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Pilares"
                          linkTo={routes.EXERCISE_CATEGORY_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_FUNCTION_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Funções"
                          linkTo={routes.EXERCISE_FUNCTION_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.VIEW_MUSCLE_GROUP_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Músculo alvo"
                          linkTo={routes.MUSCLE_GROUP_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {/* {(this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Capacidades"
                      icon={<i className="fas fa-tag menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar capacidades"
                          linkTo={routes.EXERCISE_ACTIVITY_TYPE_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar capacidade"
                          linkTo={routes.EXERCISE_ACTIVITY_TYPE_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_ACTIVITY_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Atividades"
                      icon={<i className="fas fa-running menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_ACTIVITY_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar atividades"
                          linkTo={routes.EXERCISE_ACTIVITY_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_ACTIVITY_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar atividade"
                          linkTo={routes.EXERCISE_ACTIVITY_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_CATEGORY_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_CATEGORY_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Pilares"
                      icon={<i className="fas fa-tags menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_CATEGORY_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar pilares"
                          linkTo={routes.EXERCISE_CATEGORY_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_CATEGORY_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar pilar"
                          linkTo={routes.EXERCISE_CATEGORY_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_FUNCTION_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_FUNCTION_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Funções"
                      icon={<i className="fas fa-box menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_FUNCTION_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar funções"
                          linkTo={routes.EXERCISE_FUNCTION_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_EXERCISE_FUNCTION_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar função"
                          linkTo={routes.EXERCISE_FUNCTION_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_MUSCLE_GROUP_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_MUSCLE_GROUP_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Músculo alvo"
                      icon={<i className="fas fa-bullseye menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_MUSCLE_GROUP_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar agrupamentos"
                          linkTo={routes.MUSCLE_GROUP_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_MUSCLE_GROUP_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar Agrupamento"
                          linkTo={routes.MUSCLE_GROUP_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  } */}

                  {(this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_TRAINING_EXERCISE_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Exercícios"
                      icon={<i className="fas fa-dumbbell menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar exercícios"
                          linkTo={routes.TRAINING_EXERCISE_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_TRAINING_EXERCISE_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar exercício"
                          linkTo={routes.TRAINING_EXERCISE_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_TRAINING_PERIOD_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Periodizações"
                      icon={<i className="fas fa-calendar-week menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar periodizações"
                          linkTo={routes.TRAINING_PERIOD_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_TRAINING_PERIOD_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar periodização"
                          linkTo={routes.TRAINING_PERIOD_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                      {(this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID) &&
                        this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_DAY_PERMISSION_ID) &&
                        this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_SERVICES_PERMISSION_ID) &&
                        this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID) &&
                        this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_GROUP_PERMISSION_ID) &&
                        this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_CATEGORY_PERMISSION_ID) &&
                        this.props.userPermissionIds.includes(permissions.VIEW_EXERCISE_FUNCTION_PERMISSION_ID) &&
                        this.props.userPermissionIds.includes(permissions.VIEW_MUSCLE_GROUP_PERMISSION_ID)) &&
                        <SubMenuItem
                          text="Pesquisar treinos"
                          linkTo={routes.TRAINING_DAY_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_CALENDAR_PERMISSION_ID)) &&
                    <MenuItem
                      text="Calendário de treinos"
                      linkTo={routes.TRAINING_SCHEDULE_MANAGE_PATH}
                      location={this.props.location}
                      icon={<i className="fas fa-calendar-alt menu-items__default-item__icon"></i>}
                    />
                  }

                  {this.props.userPermissionIds.includes(permissions.VIEW_HOME_TRAINING_PERMISSION_ID) &&
                    <MenuItem
                      text="Home training"
                      linkTo={routes.HOME_TRAINING_LIST_PATH}
                      location={this.props.location}
                      icon={<i className="fas fa-home menu-items__default-item__icon"></i>}
                    />
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID) &&
                    this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_DATA_PERMISSION) &&
                    this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_PERIOD_PERMISSION)) &&
                    <MenuItem
                      text="Acompanhamento"
                      linkTo={routes.PERSONAL_TRAINING_SERVICE_REPORT_PATH}
                      location={this.props.location}
                      icon={<i className="fa-solid fa-image-portrait menu-items__default-item__icon"></i>}
                    />
                  }

                </ExpandableOrganizerMenuItem>
              }

              {(this.props.userPermissionIds.includes(permissions.VIEW_FOOD_INGREDIENT_PERMISSION) ||
                this.props.userPermissionIds.includes(permissions.VIEW_FOOD_CLASSIFICATION_PERMISSION) ||
                this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)) &&
                <ExpandableOrganizerMenuItem
                 text="Nutrição"
                >

                  {(this.props.userPermissionIds.includes(permissions.VIEW_FOOD_CLASSIFICATION_PERMISSION) || this.props.userPermissionIds.includes(permissions.ADD_FOOD_CLASSIFICATION_PERMISSION)) &&
                    <AccordionMenuItem
                      text="Grupos de alimentos"
                      icon={<i className="fas fa-tags menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_CLASSIFICATION_PERMISSION) &&
                        <SubMenuItem
                          text="Listar grupos"
                          linkTo={routes.FOOD_CLASSIFICATION_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_FOOD_CLASSIFICATION_PERMISSION) &&
                        <SubMenuItem
                          text="Adicionar grupo"
                          linkTo={routes.FOOD_CLASSIFICATION_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_FOOD_INGREDIENT_PERMISSION) || this.props.userPermissionIds.includes(permissions.ADD_FOOD_INGREDIENT_PERMISSION)) &&
                    <AccordionMenuItem
                      text="Ingredientes"
                      icon={<i className="fas fa-carrot menu-items__accordion-item__icon"></i>}
                    >
                      {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_INGREDIENT_PERMISSION) &&
                        <React.Fragment>

                          <SubMenuItem
                            text="Listar ingredientes"
                            linkTo={routes.FOOD_INGREDIENT_LIST_PATH}
                            location={this.props.location}
                          />

                        </React.Fragment>
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_FOOD_INGREDIENT_PERMISSION) &&
                        <React.Fragment>

                          <SubMenuItem
                            text="Adicionar ingrediente"
                            linkTo={routes.FOOD_INGREDIENT_ADD_PATH}
                            location={this.props.location}
                          />

                        </React.Fragment>
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_MEAL_CLASSIFICATION_PERMISSION) || this.props.userPermissionIds.includes(permissions.ADD_MEAL_CLASSIFICATION_PERMISSION)) &&
                    <AccordionMenuItem
                      text="Tipos de refeição"
                      icon={<i className="fas fa-tags menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_MEAL_CLASSIFICATION_PERMISSION) &&
                        <SubMenuItem
                          text="Listar classificações"
                          linkTo={routes.MEAL_CLASSIFICATION_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_MEAL_CLASSIFICATION_PERMISSION) &&
                        <SubMenuItem
                          text="Adicionar classificação"
                          linkTo={routes.MEAL_CLASSIFICATION_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION) || this.props.userPermissionIds.includes(permissions.ADD_FOOD_RECIPE_PERMISSION)) &&
                    <AccordionMenuItem
                      text="Receitas"
                      icon={<i className="fas fa-utensils menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION) &&
                        <SubMenuItem
                          text="Listar receitas"
                          linkTo={routes.FOOD_RECIPE_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_FOOD_RECIPE_PERMISSION) &&
                        <SubMenuItem
                          text="Adicionar receita"
                          linkTo={routes.FOOD_RECIPE_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_MEAL_PERIOD_PERMISSION) || this.props.userPermissionIds.includes(permissions.ADD_MEAL_PERIOD_PERMISSION)) &&
                    <AccordionMenuItem
                      text="Períodos de refeições"
                      icon={<i className="fas fa-clock menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_MEAL_PERIOD_PERMISSION) &&
                        <SubMenuItem
                          text="Listar períodos"
                          linkTo={routes.MEAL_PERIOD_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_MEAL_PERIOD_PERMISSION) &&
                        <SubMenuItem
                          text="Adicionar período"
                          linkTo={routes.MEAL_PERIOD_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION) || this.props.userPermissionIds.includes(permissions.ADD_FOOD_PRESCRIPTION_PERMISSION)) &&
                    <AccordionMenuItem
                      text="Dietas alimentares"
                      icon={<i className="fas fa-clipboard-list menu-items__accordion-item__icon"></i>}
                    >

                      {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION) &&
                        <SubMenuItem
                          text="Listar dietas"
                          linkTo={routes.FOOD_PRESCRIPTION_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {this.props.userPermissionIds.includes(permissions.ADD_FOOD_PRESCRIPTION_PERMISSION) &&
                        <SubMenuItem
                          text="Adicionar dieta"
                          linkTo={routes.FOOD_PRESCRIPTION_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {(this.isDefaultUnit() &&
                    this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)) &&
                    <MenuItem
                      text="Acompanhamento"
                      linkTo={routes.FOOD_PRESCRIPTION_SERVICE_REPORT_PATH}
                      location={this.props.location}
                      icon={<i className="fa-solid fa-image-portrait menu-items__default-item__icon"></i>}
                    />
                  }

                </ExpandableOrganizerMenuItem>
              }

              {(this.isDefaultUnit() &&
                  (this.props.userPermissionIds.includes(permissions.VIEW_USER_DEVICE_PERMISSION_ID) ||
                   (this.props.userPermissionIds.includes(permissions.VIEW_RECEPTIONIST_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) ||
                   (this.props.userPermissionIds.includes(permissions.VIEW_NUTRITIONIST_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) ||
                   (this.props.userPermissionIds.includes(permissions.VIEW_COACH_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) ||
                   this.props.userPermissionIds.includes(permissions.VIEW_WEARABLE_DEVICE_PERMISSION_ID) ||
                   this.props.userPermissionIds.includes(permissions.ADD_WEARABLE_DEVICE_PERMISSION_ID) ||
                   (this.props.userPermissionIds.includes(permissions.VIEW_MANAGER_USER_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) ||
                   this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID))) &&
                <ExpandableOrganizerMenuItem
                 text="Sistema"
                >

                  {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_USER_DEVICE_PERMISSION_ID)) &&
                    <MenuItem
                      text="Dispositivos"
                      linkTo={routes.USER_DEVICE_LIST_PATH}
                      location={this.props.location}
                      icon={<i className="fas fa-tv menu-items__default-item__icon"></i>}
                    />
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_WEARABLE_DEVICE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_WEARABLE_DEVICE_PERMISSION_ID)) &&
                    <AccordionMenuItem
                      text="Frequencímetros"
                      icon={<i className="fas fa-heartbeat menu-items__accordion-item__icon"></i>}
                    >
                      {this.props.userPermissionIds.includes(permissions.VIEW_WEARABLE_DEVICE_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar frequencímetros"
                          linkTo={routes.HR_DEVICE_LIST_PATH}
                          location={this.props.location}
                        />
                      }
                      {this.props.userPermissionIds.includes(permissions.ADD_WEARABLE_DEVICE_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar freq."
                          linkTo={routes.HR_DEVICE_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                  {/* {(this.isDefaultUnit() && (this.props.userPermissionIds.includes(permissions.VIEW_COACH_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.ADD_COACH_PERMISSION_ID))) &&
                    <AccordionMenuItem
                      text="Treinadores"
                      icon={<i className="fas fa-chalkboard-teacher menu-items__accordion-item__icon"></i>}
                    >
                      {this.props.userPermissionIds.includes(permissions.VIEW_COACH_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Listar treinadores"
                          linkTo={routes.COACH_LIST_PATH}
                          location={this.props.location}
                        />
                      }
                      {this.props.userPermissionIds.includes(permissions.ADD_COACH_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar treinador"
                          linkTo={routes.COACH_ADD_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  } */}

                  {(this.isDefaultUnit() &&
                    this.props.userAccessLevel < COACH_ACCESS_LEVEL &&
                    (this.props.userPermissionIds.includes(permissions.VIEW_MANAGER_USER_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.VIEW_COACH_PERMISSION_ID))) &&
                    <AccordionMenuItem
                      text="Usuários"
                      icon={<i className="fas fa-users menu-items__accordion-item__icon"></i>}
                    >

                      {(this.props.userPermissionIds.includes(permissions.VIEW_RECEPTIONIST_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) &&
                        <SubMenuItem
                          text="Listar recepcionistas"
                          linkTo={routes.RECEPTIONIST_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {(this.props.userPermissionIds.includes(permissions.VIEW_NUTRITIONIST_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) &&
                        <SubMenuItem
                          text="Listar nutricionistas"
                          linkTo={routes.NUTRITIONIST_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {(this.props.userPermissionIds.includes(permissions.VIEW_COACH_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) &&
                        <SubMenuItem
                          text="Listar treinadores"
                          linkTo={routes.COACH_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {(this.props.userPermissionIds.includes(permissions.VIEW_MANAGER_USER_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) &&
                        <SubMenuItem
                          text="Listar gerentes"
                          linkTo={routes.USER_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {(this.props.userPermissionIds.includes(permissions.VIEW_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID) && this.props.userAccessLevel < COACH_ACCESS_LEVEL) &&
                        <SubMenuItem
                          text="Níveis de experiência"
                          linkTo={routes.ROLE_EXPERIENCE_LEVEL_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                      {/* {this.props.userPermissionIds.includes(permissions.ADD_MANAGER_USER_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Adicionar gerente"
                          linkTo={routes.USER_ADD_PATH}
                          location={this.props.location}
                        />
                      } */}

                    </AccordionMenuItem>
                  }

                  {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_PAYMENT_DEVICE_PERMISSION_ID)) &&
                    <MenuItem
                      text="Máquinas de cartão"
                      linkTo={routes.PAYMENT_DEVICE_LIST_PATH}
                      location={this.props.location}
                      icon={<i className="fa-regular fa-credit-card menu-items__default-item__icon"></i>}
                    />
                  }

                  {(this.isDefaultUnit() && (this.props.userPermissionIds.includes(permissions.VIEW_ACCESS_CONTROL_DEVICE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID))) &&
                    <AccordionMenuItem
                      text="Controle de acesso"
                      icon={<i className="fa-solid fa-key menu-items__accordion-item__icon"></i>}
                    >
                      {(this.props.userPermissionIds.includes(permissions.VIEW_ACCESS_CONTROL_DEVICE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID)) &&
                        <SubMenuItem
                          text="Listar dispositivos"
                          linkTo={routes.ACCESS_CONTROL_DEVICE_LIST_PATH}
                          location={this.props.location}
                        />
                      }
                      {this.props.userPermissionIds.includes(permissions.VIEW_STAFF_PERMISSION_ID) &&
                        <SubMenuItem
                          text="Gerenciar acesso"
                          linkTo={routes.STAFF_ACCESS_CONTROL_LIST_PATH}
                          location={this.props.location}
                        />
                      }

                    </AccordionMenuItem>
                  }

                </ExpandableOrganizerMenuItem>
              }

            </ul>

          </aside>

          <div
            className="admin-frame__content-blocker"
            onClick={() => this.toggleMenu()}
          />

          <main className="admin-frame__main">

            {this.props.children}

          </main>

        </div>

        <footer className="admin-frame__footer">

          <p className="admin-frame__footer__text">{(new Date()).getFullYear()} © FYD CENTRO DE TREINAMENTO</p>

        </footer>

      </div>
    );
  }
}

export default AdminFrame;
