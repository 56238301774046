import React from 'react';
import './exit_survey_report.scss';
import * as routes from '../../../constants';
import ContentFrame from '../../content_frame';
import {DefaultSubSectionTitle, HorizontalRule} from '../../../utils/default_section';
import {getModels, setUrlParameters} from '../../../utils/functions';
import DefaultInput, {HalfWrapper} from '../../../utils/default_input';
// import PieGraph, {PiePoint} from '../../../graphs/pie_graph';
import VerticalBarGraph, {BarDataPoint} from '../../../graphs/vertical_bar_graph';


class ExitSurveyReport extends React.Component {
  constructor(props) {
    super(props);

    const initialDate = new Date();
    initialDate.setDate(1);
    initialDate.setMonth(initialDate.getMonth() - 1);
    const finalDate = new Date();

    this.state = {
      initialDate: initialDate.toISOString().slice(0, 10),
      initialDateInput: initialDate.toISOString().slice(0, 10),
      finalDate: finalDate.toISOString().slice(0, 10),
      finalDateInput: finalDate.toISOString().slice(0, 10),
      exit_survey_submissions: [],
      loadingData: true,
      screenWidth: window.innerWidth,
      graphContainerWidth: null
    };
  }

  async getExitSurveySubmissions() {
    const parameters = {
      initial_reference_date: this.state.initialDate,
      final_reference_date: this.state.finalDate,
      load_answers: true
    };

    return await getModels(setUrlParameters(routes.EXIT_SURVEY_SUBMISSIONS_GET_API, parameters));
  }

  async refreshData() {
    if(this.state.initialDate > this.state.finalDate) {
      return;
    }

    this.setState({loadingData: true});

    const update = {
      loadingData: false
    }

    let exit_survey_submissions = this.getExitSurveySubmissions();

    exit_survey_submissions = await exit_survey_submissions;

    if(exit_survey_submissions) {
      update.exit_survey_submissions = exit_survey_submissions;
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.refreshData(true);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate || prevState.finalDate !== this.state.finalDate) {
      this.refreshData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.refreshData();
    }
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getDiscGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  getGraphs() {
    const questionMap = new Map();

    const serviceRegex = /(.+) \(ID do contrato:.+\)/;

    for(let submission of this.state.exit_survey_submissions) {
      for(let entry of submission.answers) {
        if (entry.answer === null) {
          continue;
        }

        let answerMap;

        if(!questionMap.has(entry.fyd_form_question.question_title)) {
          answerMap = new Map();
          questionMap.set(entry.fyd_form_question.question_title, answerMap);
        }
        else {
          answerMap = questionMap.get(entry.fyd_form_question.question_title);
        }

        let answer = entry.answer;

        if (entry.fyd_form_question.order === 1) {
          const serviceMatch = answer.match(serviceRegex);

          if (serviceMatch !== null) {
            answer = serviceMatch[1];
          }
        }

        let count = 1;

        if(answerMap.has(answer)) {
          count = answerMap.get(answer) + 1;
        }

        answerMap.set(answer, count);
      }
    }

    let graphs = [];

    for(let [questionText, answerMap] of questionMap.entries()) {
      const graphData = [...answerMap.entries()].map(([answerText, count]) => BarDataPoint(count, answerText));

      graphData.sort((a, b) => a.value - b.value).reverse(); // a.label.localeCompare(b.label)

      graphs.push(
        <React.Fragment key={`exit_survey_question_${questionText.replace(/\s+/g, '_').toLocaleLowerCase()}`}>
          <DefaultSubSectionTitle
            icon={<i className="fas fa-question-circle"></i>}
            text={questionText}
          />

          <VerticalBarGraph
            xLabel="Resposta"
            yLabel="Frequência"
            data={graphData}
            height={this.getDefaultGraphHeight()}
            animationEnabled={false}
          />

          <HorizontalRule />
        </React.Fragment>
      );
    }


    return graphs;
  }

  mayUpdateData() {
    if(!this.state.initialDateInput || !this.state.finalDateInput) {
      return false;
    }

    if(this.state.initialDateInput !== this.state.initialDate || this.state.finalDateInput !== this.state.finalDate) {
      return true;
    }

    return false;
  }

  applyInputChanges() {
    if(this.mayUpdateData()) {
      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput
      });
    }
  }

  render() {

    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.EXIT_SURVEY_REPORT_PATH,
            text: "Relatório de cancelamento"
          },
        ]}
        titleIcon={<i className="fas fa-chart-line"></i>}
        title="Relatório de satisfação"
        loading={this.state.loadingData}
      >

        <div className="exit-survey-report__wrapper">

          <div className="exit-survey-report__period-control">

            <h3 className="exit-survey-report__period-control__title">Período de avaliação</h3>

            <div className="exit-survey-report__inputs-container">

              <div className="exit-survey-report__inputs-wrapper">

                <HalfWrapper>

                  <DefaultInput
                    name="initialDateInput"
                    isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                    label="Data inicial"
                    type="date"
                    placeholder="Data inicial"
                    max={this.state.finalDateInput}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.initialDateInput}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  />

                  <DefaultInput
                    name="finalDateInput"
                    isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                    label="Data final"
                    type="date"
                    placeholder="Data final"
                    min={this.state.initialDateInput}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.finalDateInput}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  />

                </HalfWrapper>

              </div>

              <button
                className="exit-survey-report__refresh-button"
                onClick={() => this.applyInputChanges()}
                disabled={!this.mayUpdateData()}
              >

                <i className="fas fa-sync"></i>

              </button>

            </div>

          </div>

          <HorizontalRule />

          <div className="exit-survey-report__indicator-container">

            <div className="exit-survey-report__indicator">

              <h2 className="exit-survey-report__indicator__label">Pesquisas no período:</h2>
              <p className="exit-survey-report__indicator__value">{this.state.exit_survey_submissions.length}</p>

            </div>

          </div>

          <HorizontalRule />

          {this.getGraphs()}

        </div>

      </ContentFrame>
    );
  }
}

export default ExitSurveyReport;
