import React from 'react';
import './exit_survey_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';

class ExitSurveyData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getSurveyQuestions() {
    return this.props.exit_survey_submission.answers.map((entry) => (
      <DefaultInput
        key={`exit_survey_question_input_${entry.fyd_form_question.id}`}
        name={`exit_survey_question_input_${entry.fyd_form_question.id}`}
        label={`${entry.fyd_form_question.order}) ${entry.fyd_form_question.question_title}`}
        labelClassName="exit-survey-data__question__label"
        type="text"
        placeholder="Sem resposta"
        value={entry.answer || ''}
        disabled={true}
      />
    ));
  }

  render() {
    return (
      <DefaultSection
        className="exit-survey-data"
        title="Dados da entrada de pesquisa de satisfação"
      >

        <div className="exit-survey-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="exit-survey-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do aluno"
              value={this.props.exit_survey_submission.user.name}
              autoComplete="off"
              disabled={true}
            />

            <DefaultInput
              name="email"
              isHighlighted={this.isHighlighted("email")}
              label="E-mail"
              type="email"
              placeholder="E-mail do aluno"
              value={this.props.exit_survey_submission.user.email}
              disabled={true}
            />

          </HalfWrapper>

        </div>

        <HorizontalRule />

        {this.getSurveyQuestions()}

      </DefaultSection>
    );
  }
}

export default ExitSurveyData;
