import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, getModels, patchModel, setUrlParameters} from '../../utils/functions';
import * as permissions from '../../permissions';
import CustomerFeedbackData from './customer_feedback_data';

class CustomerFeedbackEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_feedback: {
        staff_id: null,
        customer_feedback_type_id: null,
        customer_feedback_target_id: null,
        name: null,
        email: null,
        description: "",
        archived: false,
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async getCustomerFeedbackTypes() {
    return await getModels(routes.CUSTOMER_FEEDBACK_TYPES_GET_API);
  }

  async getCustomerFeedbackTargets() {
    return await getModels(routes.CUSTOMER_FEEDBACK_TARGETS_GET_API);
  }

  async getCoaches() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_COACH_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.COACHES_GET_API, parameters));
  }

  async getReceptionists() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_RECEPTIONIST_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.RECEPTIONISTS_GET_API, parameters));
  }

  async getManagers() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_MANAGER_USER_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.USERS_GET_API, parameters));
  }

  async getNutritionists() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_NUTRITIONIST_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.NUTRITIONISTS_GET_API, parameters));
  }

  async componentDidMount() {
    const update = {loading: false};

    const userIdSet = new Set();
    update.users = [];

    let customer_feedback = getModel(`${routes.CUSTOMER_FEEDBACK_GET_API}${this.props.match.params.customerFeedbackId}`);
    let customer_feedback_types = this.getCustomerFeedbackTypes();
    let customer_feedback_targets = this.getCustomerFeedbackTargets();
    let coaches = this.getCoaches();
    let receptionists = this.getReceptionists();
    let managers = this.getManagers();
    let nutritionists = this.getNutritionists();


    customer_feedback = await customer_feedback;

    if(customer_feedback) {
      update.customer_feedback = {...this.state.customer_feedback, ...customer_feedback};

      update.customer_feedback_types = await customer_feedback_types;
      update.customer_feedback_targets = await customer_feedback_targets;

      coaches = await coaches;

      if (coaches) {
        for (const entry of coaches) {
          if (!userIdSet.has(entry.id)) {
            userIdSet.add(entry.id);
            update.users.push(entry);
          }
        }
      }

      receptionists = await receptionists;

      if (receptionists) {
        for (const entry of receptionists) {
          if (!userIdSet.has(entry.id)) {
            userIdSet.add(entry.id);
            update.users.push(entry);
          }
        }
      }

      managers = await managers;

      if (managers) {
        for (const entry of managers) {
          if (!userIdSet.has(entry.id)) {
            userIdSet.add(entry.id);
            update.users.push(entry);
          }
        }
      }

      nutritionists = await nutritionists;

      if (nutritionists) {
        for (const entry of nutritionists) {
          if (!userIdSet.has(entry.id)) {
            userIdSet.add(entry.id);
            update.users.push(entry);
          }
        }
      }

      update.users.sort((a, b) => a.name.localeCompare(b.name));

      this.setState(update);
    }
    else {
      this.props.history.replace(routes.CUSTOMER_FEEDBACK_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.customer_feedback, [name]: value};

    this.setState({
      customer_feedback: newData
    });
  }

  inputsAreValid() {
    return this.state.customer_feedback.description.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.customer_feedback}

    try {
      await patchModel(`${routes.CUSTOMER_FEEDBACK_PATCH_API}${this.props.match.params.customerFeedbackId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      // if(errors instanceof Array) {
      //   for(let error of errors) {
      //     switch (error.code) {
      //       case 103:
      //         for(let parameter of error.parameters) {
      //           switch (parameter.name) {
      //             case 'name':
      //               warningMessages.push('Nome já cadastrado');
      //               highlights.push('name');

      //               break;
      //             default:
      //           }
      //         }

      //         break;
      //       case 209:
      //         warningMessages.push('Sessão do usuário expirada');

      //         break;
      //       default:
      //     }
      //   }
      // }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.CUSTOMER_FEEDBACK_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.CUSTOMER_FEEDBACK_LIST_PATH,
            text: 'Listar feedbacks'
          },
          {
            path: `${routes.CUSTOMER_FEEDBACK_EDIT_PATH}${this.props.match.params.customerFeedbackId}`,
            text: "Editar feedback"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar assunto de feedback"
        loading={this.state.loading}
      >

        <CustomerFeedbackData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          customer_feedback={this.state.customer_feedback}
          customer_feedback_types={this.state.customer_feedback_types}
          customer_feedback_targets={this.state.customer_feedback_targets}
          users={this.state.users}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.CUSTOMER_FEEDBACK_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default CustomerFeedbackEdit;
