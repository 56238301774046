import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import {getModels, patchModel, deleteModel, setUrlParameters, getAsLocalDate, getAsLocalDateString} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';
import ContextPopup from '../../components/context_popup';
import './customer_feedback_list.scss';

class CustomerFeedbackList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let typeId = queryParameters.get('type_id');
    let targetId = queryParameters.get('target_id');

    let archived = queryParameters.get('archived');

    if(!typeId) {
      typeId = '';
    }
    if(!targetId) {
      targetId = '';
    }
    if(!archived) {
      archived = 'false';
    }

    this.state = {
      typeId,
      typeIdInput: typeId,
      targetId,
      targetIdInput: targetId,
      archived,
      archivedInput: archived,
      loadingData: true,
      customer_feedbacks: [],
      customer_feedback_types: [],
      customer_feedback_targets: [],
      entryToToggleArchive: null,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      popupContent: null,
      popupTarget: null,
      screenWidth: window.innerWidth,
    };
  }

  async getCustomerFeedbacks() {
    const parameters = {
      type_id: this.state.typeId,
      target_id: this.state.targetId,
      archived: this.state.archived,
      load_staff: true
    };

    if(parameters.archived === 'all') {
      parameters.archived = null;
    }

    return await getModels(setUrlParameters(routes.CUSTOMER_FEEDBACKS_GET_API, parameters));
  }

  async getCustomerFeedbackTypes() {
    return await getModels(routes.CUSTOMER_FEEDBACK_TYPES_GET_API);
  }

  async getCustomerFeedbackTargets() {
    return await getModels(routes.CUSTOMER_FEEDBACK_TARGETS_GET_API);
  }

  async componentDidMount() {
    this.setState({
      loadingData: true
    });

    const listUpdate = this.reloadList(false);

    const update = {loadingData: false};

    let customer_feedback_types = this.getCustomerFeedbackTypes();
    let customer_feedback_targets = this.getCustomerFeedbackTargets();

    update.customer_feedback_types = await customer_feedback_types;
    update.customer_feedback_targets = await customer_feedback_targets;

    await listUpdate;

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.typeId !== this.state.typeId ||
        prevState.targetId !== this.state.targetId ||
        prevState.archived !== this.state.archived) {
      this.reloadList();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(updateLoadingStatus=true) {
    const update = {};

    if (updateLoadingStatus) {
      this.setState({
        loadingData: true
      });

      update.loadingData = false;
    }

    update.customer_feedbacks = await this.getCustomerFeedbacks();

    this.setState(update);
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      entryToToggleArchive: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onToggleArchiveEntry(entry) {
    this.setState({
      entryToToggleArchive: entry,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      entryToToggleArchive: null,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteId !== null) {
      try{
        if(await deleteModel(`${routes.CUSTOMER_FEEDBACK_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.entryToToggleArchive !== null) {
      const data = {archived: !this.state.entryToToggleArchive.archived};

      try{
        if(await patchModel(`${routes.CUSTOMER_FEEDBACK_PATCH_API}${this.state.entryToToggleArchive.id}`, data)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      entryToToggleArchive: null,
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {entry.user_id !== null &&
          <Link
            className="model-table__default-link-button"
            to={`${routes.STUDENT_MANAGE_PATH}${entry.user_id}`}
          >

              <i className="fas fa-link"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.EDIT_CUSTOMER_FEEDBACK_PERMISSION_ID) &&
          <React.Fragment>

            <button
              className="model-table__default-edit-button customer-feedback-list__archive-toggle-button"
              onClick={() => this.onToggleArchiveEntry(entry)}
            >

              {entry.archived ? (
                <i className="fa-solid fa-box-open"></i>
              ) : (
                <i className="fa-solid fa-box-archive"></i>
              )}

            </button>

            <Link
              className="model-table__default-edit-button"
              to={`${routes.CUSTOMER_FEEDBACK_EDIT_PATH}${entry.id}`}
            >

                <i className="fas fa-edit"></i>

            </Link>

          </React.Fragment>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_CUSTOMER_FEEDBACK_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getCreatedAtText(entry) {
    const date = getAsLocalDate(entry.created_at);

    return (
      <div className="customer-feedback-list__cell-wrapper">

        <p className="customer-feedback-list__date-text">

          {date.toLocaleDateString()}

        </p>

        {(entry.name !== null || entry.email !== null) && (
          <i
            className="fa-solid fa-user customer-feedback-list__info-icon"
            onMouseEnter={(event) => this.onShowHoverdata(event.target, this.getIdentityText(entry))}
            onMouseLeave={(event) => this.onHideHoverdata()}
          >
          </i>
        )}

      </div>
    );
  }

  getCreatedAtFilter(entry) {
    return `${getAsLocalDateString(entry.created_at)} ${this.getIdentityFilter(entry)}`;
  }

  getArchivedText(entry) {
    const text = entry.archived ? 'Arquivado' : 'Não resolvido';;

    return (
      <div className="customer-feedback-list__cell-wrapper">

        <p className={`customer-feedback-list__archived-text${entry.archived ? '' : '--alert'}`}>

          {text}

        </p>

      </div>
    );
  }

  getArchivedFilter(entry) {
    return entry.archived ? 'Arquivado' : 'Não resolvido';
  }

  getIdentityText(entry) {
    if (!entry.name && !entry.email) {
      return (
        <div className="customer-feedback-list__identity-container--centered">

          -

        </div>
      );
    }

    return (
      <div className="customer-feedback-list__identity-container">

        <p className="customer-feedback-list__identity-entry">

          <i className="fa-solid fa-user customer-feedback-list__identity-entry__icon"></i>{' '}{entry.name}

        </p>

        <p className="customer-feedback-list__identity-entry">

          <i className="fa-solid fa-envelope customer-feedback-list__identity-entry__icon"></i>{' '}{entry.email}

        </p>

      </div>
    );
  }

  getIdentityFilter(entry) {
    if (!entry.name && !entry.email) {
      return '';
    }

    return `${entry.name} ${entry.email}`;
  }

  getStaffName(entry) {
    if (entry.staff_id === null) {
      return '';
    }

    return entry.staff.name;
  }

  getProperties() {
    let properties = [
      Property('created_at', 'Criado em', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getCreatedAtText(entry),
        getFilterText: (entry) => this.getCreatedAtFilter(entry),
      }),
      // Property('name', 'Identidade', <i className="fa-solid fa-tag"></i>, {
      //   getDataText: this.getIdentityText,
      //   getFilterText: this.getIdentityFilter,
      //   sortable: false
      // }),
      Property('staff_id', 'Colaborador', <i className="fa-solid fa-tag"></i>, {
        getDataText: this.getStaffName,
        getFilterText: this.getStaffName
      }),
      Property('customer_feedback_type', 'Tipo', <i className="fa-solid fa-tag"></i>, {
        getDataText: (entry) => entry.customer_feedback_type.name,
        getFilterText: (entry) => entry.customer_feedback_type.name,
      }),
      Property('customer_feedback_target', 'Assunto', <i className="fa-solid fa-tag"></i>, {
        getDataText: (entry) => entry.customer_feedback_target.name,
        getFilterText: (entry) => entry.customer_feedback_target.name,
      }),
      Property('description', 'Mensagem', <i className="fa-solid fa-comment"></i>, {
        cellClassName: "customer-feedback-list__description-cell"
      }),
      Property('archived', 'Status', <i className="fa-solid fa-circle-info"></i>, {
        getDataText: this.getArchivedText,
        getFilterText: this.getArchivedFilter,
      })
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteId !== null) {
        return 'Deletando feedback';
      }
      else if(this.state.entryToToggleArchive !== null) {
        return `${this.state.entryToToggleArchive.archived ? 'Desarquivando' : 'Arquivando'} feedback`;
      }

      return 'INDEFINIDO';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteId !== null) {
        return 'Falha ao deletar';
      }
      else if(this.state.entryToToggleArchive !== null) {
        return `Falha ao ${this.state.entryToToggleArchive.archived ? 'desarquivar' : 'arquivar'}`;
      }

      return 'INDEFINIDO';
    }

    if(this.state.deleteId !== null) {
      return 'Deletar feedback';
    }
    else if(this.state.entryToToggleArchive !== null) {
      return `${this.state.entryToToggleArchive.archived ? 'Desarquivar' : 'Arquivar'} feedback`;
    }

    return 'INDEFINIDO';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId !== null) {
      return 'Todos os dados relacionados ao feedback serão removidos permanentemente.';
    }
    else if(this.state.entryToToggleArchive !== null) {
      return `O cadastro de feedback será ${this.state.entryToToggleArchive.archived ? 'desarquivado' : 'arquivado'}.`;
    }

    return 'INDEFINIDO';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.deleteId !== null) {
      return 'Deletar feedback';
    }
    else if(this.state.entryToToggleArchive !== null) {
      return this.state.entryToToggleArchive.archived ? 'Desarquivar' : 'Arquivar';
    }

    return 'INDEFINIDO';
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  mayUpdateDateInputs() {
    if(this.state.typeIdInput !== this.state.typeId ||
       this.state.targetIdInput !== this.state.targetId ||
       this.state.archivedInput !== this.state.archived) {
      return true;
    }

    return false;
  }

  mayResetFilterInputs() {
    if(this.state.typeIdInput.length > 0 ||
       this.state.targetIdInput.length > 0 ||
       this.state.archivedInput !== 'false') {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      typeIdInput: '',
      targetIdInput: '',
      archivedInput: 'false',
    });
  }

  applyFilterChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.CUSTOMER_FEEDBACK_LIST_PATH, {
        type_id: this.state.typeIdInput,
        target_id: this.state.targetIdInput,
        archived: this.state.archivedInput
      }));

      this.setState({
        typeId: this.state.typeIdInput,
        targetId: this.state.targetIdInput,
        archived: this.state.archivedInput,
      });
    }
  }

  getArchivedOptions() {
    return [
      SelectOption('true', 'Arquivado'),
      SelectOption('false', 'Não arquivado'),
      SelectOption('all', 'Todos'),
    ];
  }

  getTypeOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.state.customer_feedback_types.map((type) => SelectOption(type.id, type.name))
    ];
  }

  getTargetOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.state.customer_feedback_targets.map((target) => SelectOption(target.id, target.name))
    ];
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || this.state.entryToToggleArchive !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.CUSTOMER_FEEDBACK_LIST_PATH,
              text: 'Listar feedbacks'
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title={'Listar feedbacks de clientes'}
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="customer-feedback-list"
            title={'Lista de feedbacks'}
          >

            <div className="customer-feedback-list__filters">

              <header className="customer-feedback-list__filters__header">

                <h4 className="customer-feedback-list__filters__header__text">Filtros</h4>

              </header>

              <div className="customer-feedback-list__filters__inputs">

                <div className="customer-feedback-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="archivedInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.archivedInput || ''}
                    options={this.getArchivedOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="typeIdInput"
                      label="Tipo do feedback"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.typeIdInput || ''}
                      options={this.getTypeOptions()}
                    />

                    <DefaultInput
                      name="targetIdInput"
                      label="Assunto do feedback"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.targetIdInput || ''}
                      options={this.getTargetOptions()}
                    />

                  </HalfWrapper>

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="customer-feedback-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayUpdateDateInputs() &&
                  <button
                    className="customer-feedback-list__filters__refresh-button"
                    onClick={() => this.applyFilterChanges()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.state.customer_feedbacks}
              initialOrderBy="created_at"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default CustomerFeedbackList;
