import React from 'react';
import ContextPopup from './context_popup';
import './schedule_grid.scss';

class ScheduleGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupTarget: null,
      popupContent: null
    };
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  getScheduleGrid() {
    const DAY_ID_ORDER = [0, 1, 2, 3, 4, 5, 6];
    const WEEKDAY_ORDER = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];

    let timeRange = null;
    const dayMap = new Map();

    let minDuration = null;


    this.props.data.sort((a, b) => {
      if (a.day_id === b.day_id) {
        if (a.sort_tag === b.sort_tag) {
          return a.start_at.localeCompare(b.start_at);
        }

        return a.sort_tag.localeCompare(b.sort_tag);
      }

      return a.day_id - b.day_id;
    });

    for(const entry of this.props.data) {
      const parsedStartHour = parseInt(entry.start_at.slice(0, 2));
      const parsedStartMinutes = parseInt(entry.start_at.slice(3, 5));

      const startHour = parsedStartHour + (parsedStartMinutes/60);

      const parsedEndHour = parseInt(entry.end_at.slice(0, 2));
      const parsedEndMinutes = parseInt(entry.end_at.slice(3, 5));

      const endHour = parsedEndHour + (parsedEndMinutes/60);

      entry.duration = endHour - startHour;

      if (minDuration === null || minDuration > entry.duration) {
        minDuration = entry.duration;
      }

      let dayGroups;

      if(!dayMap.has(entry.day_id)) {
        dayGroups = [[entry]];

        dayMap.set(entry.day_id, dayGroups);
      }
      else {
        dayGroups = dayMap.get(entry.day_id);

        let groupIndex = 0;

        while(dayGroups[groupIndex].some((dayEntry) => (dayEntry.start_at <= entry.start_at && dayEntry.end_at >= entry.start_at) ||
                                                       (dayEntry.start_at <= entry.end_at && dayEntry.end_at >= entry.end_at) ||
                                                       (entry.start_at <= dayEntry.start_at && entry.end_at >= dayEntry.start_at) ||
                                                       (entry.start_at <= dayEntry.end_at && entry.end_at >= dayEntry.end_at))) {
          groupIndex += 1;

          if (dayGroups.length < groupIndex + 1) {
            dayGroups.push([]);
          }
        }

        dayGroups[groupIndex].push(entry);
      }

      if (minDuration == null) {
        minDuration = 1;
      }

      if(timeRange === null) {
        timeRange = [entry.start_at, entry.end_at]
      }
      else {
        if(entry.start_at < timeRange[0]) {
          timeRange[0] = entry.start_at;
        }
        if(entry.end_at > timeRange[1]) {
          timeRange[1] = entry.end_at;
        }
      }
    }

    if (timeRange === null) {
      return null;
    }

    const parsedRange = [
      parseFloat(timeRange[0].slice(0, 2)),
      parseFloat(timeRange[1].slice(0, 2)) + Math.ceil((parseFloat(timeRange[1].slice(3, 5)) / 60))
    ];

    const HOUR_STEP = parsedRange[1] - parsedRange[0] > 12 ? 2 : 1;
    const hourLabels = [];
    const verticalBars = [];

    const maxStep = ((parsedRange[1] - parsedRange[0]) / HOUR_STEP);

    let hourSize = 6.5 / minDuration; //unit: em

    let horizontalSize = 0;

    for(let i=0; i<=maxStep; ++i) {
      let style = {};
      let width = 3.5;

      if(i < maxStep) {
        width = hourSize*HOUR_STEP;
        style.width = `${width}em`;
      }

      horizontalSize += width;

      hourLabels.push(
        <div
          key={`schedule_grid:hour_label:${i}`}
          className="schedule-grid__hour-label"
          style={style}
        >

          <p className="schedule-grid__hour-label__text">
            {`${(parsedRange[0] + (HOUR_STEP*i)).toString().padStart(2, '0')}:00`}
          </p>

        </div>
      );

      verticalBars.push(
        <div
          key={`schedule_grid:vertical_bar:${i}`}
          className="schedule-grid__vertical-division"
          style={{left: `${(i*HOUR_STEP)*hourSize}em`,}}
        >
        </div>
      );
    };

    const dayRows = [];

    for(const day_id of DAY_ID_ORDER) {
      if(dayMap.has(day_id)) {
        dayRows.push(
          <div
            key={`schedule_grid:day_row:${day_id}`}
            className="schedule-grid__day-row"
            style={{width: `${horizontalSize + 3.5}em`}}
          >

            <div className="schedule-grid__day-row__title">

              <h4 className="schedule-grid__day-row__title__text">

                {WEEKDAY_ORDER[day_id]}

              </h4>

            </div>

            <div className="schedule-grid__day-row__container">

              {dayMap.get(day_id).map((dayGroup, dayGroupIndex) => {
                const dayEntries = dayGroup.map((entry, index) => {
                  const parsedStartHour = parseInt(entry.start_at.slice(0, 2));
                  const parsedStartMinutes = parseInt(entry.start_at.slice(3, 5));

                  const startHour = parsedStartHour + (parsedStartMinutes/60);

                  return (
                    (
                      <div
                        key={`schedule_grid:interval_group:${dayGroupIndex}:interval:${index}`}
                        className={`schedule-grid__interval${entry.className ? ` ${entry.className}` : ''}`}
                        style={{
                          left: `${(startHour - parsedRange[0])*hourSize}em`,
                          width: `${hourSize*entry.duration}em`
                        }}
                        onMouseEnter={(event) => this.onShowHoverdata(
                          event.target,
                          entry.popupContent)}
                        onMouseLeave={() => this.onHideHoverdata()}
                      >

                        <p className="schedule-grid__interval__text">
                          {entry.text}
                        </p>

                      </div>
                    )
                  );
                });


                return (
                  <div
                    key={`schedule_grid:interval_group:${dayGroupIndex}`}
                    className="schedule-grid__interval-group"
                  >
                    {dayEntries}
                  </div>
                );
              })}

            </div>

          </div>
        );
      }
    }

    return (
      <article className="schedule-grid">

        <div className="schedule-grid__header-container">

          <div className="schedule-grid__left-padding"></div>

          <div className="schedule-grid__header-wrapper">

            {hourLabels}

          </div>

        </div>

        <div>

          {dayRows}

        </div>


        <div className="schedule-grid__division-container">

          {verticalBars}

        </div>

      </article>
    );
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          className="schedule-grid__context-popup"
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        {this.getScheduleGrid()}

      </React.Fragment>
    );
  }
}

export default ScheduleGrid;
