import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import {getModels, deleteModel, getLocalDateIsoString, getAsLocalDatetime, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';
import './exit_survey_list.scss';

class ExitSurveyList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDate = queryParameters.get('initial_date');
    let finalDate = queryParameters.get('final_date');

    if(!initialDate) {
      initialDate = new Date();
      initialDate.setMonth(initialDate.getMonth() - 2);
      initialDate = getLocalDateIsoString(initialDate);
    }
    else if(initialDate === 'all') {
      initialDate = '';
    }

    if(!finalDate) {
      finalDate = '';
    }

    this.state = {
      loadingData: false,
      exit_survey_submissions: [],
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      initialDate,
      initialDateInput: initialDate,
      finalDate,
      finalDateInput: finalDate,
      screenWidth: window.innerWidth,
    };
  }

  async getExitSurveySubmissions() {
    const parameters = {};

    if (this.state.initialDate) {
      parameters.initial_reference_date = this.state.initialDate;
    }
    if (this.state.finalDate) {
      parameters.final_reference_date = this.state.finalDate;
    }

    return await getModels(setUrlParameters(routes.EXIT_SURVEY_SUBMISSIONS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate ||
        prevState.finalDate !== this.state.finalDate) {
      this.reloadList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const exit_survey_submissions = await this.getExitSurveySubmissions();

    this.setState({
      exit_survey_submissions,
      loadingData: false
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
    });
  }

  async onConfirm() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await deleteModel(`${routes.EXIT_SURVEY_SUBMISSION_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.VIEW_EXIT_SURVEY_DATA_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_EXIT_SURVEY_DATA_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.VIEW_EXIT_SURVEY_DATA_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.EXIT_SURVEY_SHOW_PATH}${entry.id}`}
          >

              <i className="fas fa-eye"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_EXIT_SURVEY_DATA_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getSubmittedAtText(entry) {
    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const date = getAsLocalDatetime(entry.submitted_at, false);
    const dateString = getLocalDateIsoString(date);

    return (
      <div className="exit-survey-list__cell-wrapper">

        <p className={`exit-survey-list__date-text${dateString === todayString ? '--today' : ''}`}>

          {date.toLocaleDateString()}

        </p>

      </div>
    );
  }

  getProperties() {
    let properties = [
      Property('student_name', 'Nome', <i className="fas fa-tag"></i>),
    ];

    properties = [
      ...properties,
      Property('submitted_at', this.state.screenWidth > 470 ? 'Enviado em' : 'Data', <i className="fas fa-calendar-alt"></i>, {getDataText: this.getSubmittedAtText, getFilterText: this.getSubmittedAtText}),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Deletando entrada';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar entrada';
  }

  mayUpdateDateInputs() {
    if(this.state.initialDateInput !== this.state.initialDate ||
       this.state.finalDateInput !== this.state.finalDate) {
      return true;
    }

    return false;
  }

  mayResetFilterInputs() {
    if(this.state.initialDateInput.length > 0 ||
       this.state.finalDateInput.length > 0) {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      initialDateInput: '',
      finalDateInput: '',
    });
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.EXIT_SURVEY_LIST_PATH, {
        initial_date: this.state.initialDateInput || 'all',
        final_date: this.state.finalDateInput,
      }));

      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput,
      });
    }
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Todos os dados relacionados à entrada da pesquisa serão removidos'}
          confirmText={'Deletar entrada'}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onConfirm()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.EXIT_SURVEY_LIST_PATH,
              text: 'Listar pesquisas'
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar pesquisas"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="exit-survey-list"
            title="Lista de pesquisas de cancelamento"
          >

            <div className="exit-survey-list__filters">

              <header className="exit-survey-list__filters__header">

                <h4 className="exit-survey-list__filters__header__text">Filtros</h4>

              </header>

              <div className="exit-survey-list__filters__inputs">

                <div className="exit-survey-list__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="initialDateInput"
                      label="Data inicial"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.finalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.initialDateInput}
                    />

                    <DefaultInput
                      name="finalDateInput"
                      label="Data final"
                      type="date"
                      placeholder="Data final"
                      min={this.state.initialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.finalDateInput}
                    />

                  </HalfWrapper>

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="exit-survey-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayUpdateDateInputs() &&
                  <button
                    className="exit-survey-list__filters__refresh-button"
                    onClick={() => this.applyDateInputChanges()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.exit_survey_submissions}
              initialOrderBy="submitted_at"
              initialOrderIsDecrescent={true}
            />

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default ExitSurveyList;
