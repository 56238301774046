import React from 'react';
import './tab_view.scss';

class TabView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabId: this.props.initialTab || null
    };
  }

  getSelectedTabId() {
    let selectedTabId =  this.state.selectedTabId;

    if (this.state.selectedTabId === null || !this.props.tabOptions.some((option) => option.id === this.state.selectedTabId)) {
      selectedTabId = this.props.tabOptions[0].id;
    }

    return selectedTabId;
  }

  getOptions() {
    return this.props.tabOptions.map((option) => (
      <li
        key={`tab_view:${this.props.id}:${option.id}`}
        className="tab-view__option"
      >

        <button
          className="tab-view__option__button"
          onClick={() => {
            if (this.props.onSelectTab) {
              this.props.onSelectTab(option.id);
            }

            this.setState({selectedTabId: option.id})
          }}
          disabled={this.getSelectedTabId() ===  option.id}
        >

          <h3 className="tab-view__option__text">{option.text}</h3>

        </button>

      </li>
    ))
  }

  getContent() {
    if (this.props.contentCallback) {
      return this.props.contentCallback(this.getSelectedTabId());
    }

    return null;
  }

  render() {
    return (
      <div className={`tab-view${this.props.className ? ` ${this.props.className}` : ''}`}>

        <ul className="tab-view__options">

          {this.getOptions()}

        </ul>

        <div className="tab-view__content-container">

          {this.getContent()}

        </div>

      </div>
    );
  }
}

export default TabView;
