import React from 'react';
import * as routes from '../../constants';
import ContentFrame from '../content_frame';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModels, getLocalDateIsoString, setUrlParameters, getAsLocalDate} from '../../utils/functions';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import StackedBarGraph, {StackGroup, LineGroup, StackPoint, ErrorPoint} from '../../graphs/stacked_bar_graph';
import './satisfaction_report.scss';


const TICKET_BAR_PALLET = [
  '#9a9a9a',
  '#d2915a',
  '#3fb68e',
  '#6973f6',
  '#cd59b1',
  '#ea6767',
  '#5ec2d2',
  '#edf570',
  '#78bb67',
];

const TICKET_LINE_PALLET = [
  '#ca5353c4',
  '#0e8c62',
  '#8746ce',
  '#a92689',
  '#c52f2f',
  '#1e92a5',
  '#bbc517',
  '#499e33',
];

class SatisfactionReport extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDate = queryParameters.get('initial_date');
    let finalDate = queryParameters.get('final_date');

    if(!initialDate) {
      initialDate = new Date();
      initialDate.setDate(1);
      initialDate.setMonth(initialDate.getMonth() - 3);
      initialDate = getLocalDateIsoString(initialDate);
    }
    if(!finalDate) {
      finalDate = new Date();
      finalDate.setDate(1);
      finalDate.setMonth(finalDate.getMonth() - 1);
      finalDate = getLocalDateIsoString(finalDate);
    }

    this.state = {
      initialDateInput: initialDate.slice(0, 7),
      finalDateInput: finalDate.slice(0, 7),
      initialDate: initialDate.slice(0, 7),
      finalDate: finalDate.slice(0, 7),
      satisfactionData: [],
      flux_data: [],
      reportData: null,
      serviceSectionVisible: false,
      coachSectionVisible: false,
      loadingData: true,
      screenWidth: window.innerWidth,
    };
  }

  async getSatisfactionData() {
    const parameters = {
      initial_date: this.state.initialDate,
      final_date: this.state.finalDate
    };

    return await getModels(setUrlParameters(routes.SATISFACTION_REPORT_DATA_GET_API, parameters));
  }

  async refreshData(setLoading=true) {
    if(this.state.initialDate > this.state.finalDate) {
      return;
    }

    this.setState({loadingData: true});

    let satisfactionData = this.getSatisfactionData();

    const update = {reportData: null};

    if(setLoading) {
      update.loadingData = false;
    }

    satisfactionData = await satisfactionData;

    if(satisfactionData) {
      update.satisfactionData = satisfactionData.monthly_satisfaction_survey_data;
      update.flux_data = satisfactionData.flux_data;

      update.satisfactionData.sort((a, b) => a.month.localeCompare(b.month));
    }

    this.setState(update);
  }

  async componentDidMount() {
    await this.refreshData();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate || prevState.finalDate !== this.state.finalDate) {
      this.refreshData();
    }
    else if(!this.state.loadingData && this.state.reportData === null) {
      this.loadReportData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  mayUpdateDateInputs() {
    if(!this.state.initialDateInput || !this.state.finalDateInput) {
      return false;
    }

    if(this.state.initialDateInput !== this.state.initialDate || this.state.finalDateInput !== this.state.finalDate) {
      return true;
    }

    return false;
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.SATISFACTION_REPORT_PATH, {
        initial_date: this.state.initialDateInput,
        final_date: this.state.finalDateInput,
      }));

      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput,
      });
    }
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.applyDateInputChanges();
    }
  }

  // getSatisfactionDataStructure(monthLabel, monthData) {
  //   const graphData = {};

  //   let roomScoreMean = 0;
  //   let roomScoreStandardDeviation = 0;

  //   if (monthData.roomScores.length > 0) {
  //     roomScoreMean =  monthData.roomScores.reduce((sum, value) => sum + value, 0) / monthData.roomScores.length;
  //     roomScoreStandardDeviation = Math.sqrt(monthData.roomScores.reduce((sum, value) => sum + Math.pow(monthData.roomScoreMean - value, 2), 0) / monthData.roomScores.length);
  //   }

  //   let coachScoreMean = 0;
  //   let coachScoreStandardDeviation = 0;

  //   if (monthData.coachScores.length > 0) {
  //     coachScoreMean =  monthData.coachScores.reduce((sum, value) => sum + value, 0) / monthData.coachScores.length;
  //     coachScoreStandardDeviation = Math.sqrt(monthData.coachScores.reduce((sum, value) => sum + Math.pow(monthData.coachScoreMean - value, 2), 0) / monthData.coachScores.length);
  //   }

  //   let trainingScoreMean = 0;
  //   let trainingScoreStandardDeviation = 0;

  //   if (monthData.trainingScores.length > 0) {
  //     trainingScoreMean =  monthData.trainingScores.reduce((sum, value) => sum + value, 0) / monthData.trainingScores.length;
  //     trainingScoreStandardDeviation = Math.sqrt(monthData.trainingScores.reduce((sum, value) => sum + Math.pow(monthData.trainingScoreMean - value, 2), 0) / monthData.trainingScores.length);
  //   }

  //   let generalScoreMean = 0;
  //   let generalScoreStandardDeviation = 0;

  //   if (monthData.generalScores.length > 0) {
  //     generalScoreMean =  monthData.generalScores.reduce((sum, value) => sum + value, 0) / monthData.generalScores.length;
  //     generalScoreStandardDeviation = Math.sqrt(monthData.generalScores.reduce((sum, value) => sum + Math.pow(monthData.generalScoreMean - value, 2), 0) / monthData.generalScores.length);
  //   }

  //   graphData.roomData = StackPoint(roomScoreMean, monthLabel, Math.round(10 * roomScoreMean) / 10);
  //   graphData.coachData = StackPoint(coachScoreMean, monthLabel, Math.round(10 * coachScoreMean) / 10);
  //   graphData.trainingData = StackPoint(trainingScoreMean, monthLabel, Math.round(10 * trainingScoreMean) / 10);
  //   graphData.generalData = StackPoint(generalScoreMean, monthLabel, Math.round(10 * generalScoreMean) / 10);

  //   graphData.roomErrorData = new ErrorPoint([roomScoreMean - roomScoreStandardDeviation, roomScoreMean + roomScoreStandardDeviation], monthLabel);
  //   graphData.coachErrorData = new ErrorPoint([coachScoreMean - coachScoreStandardDeviation, coachScoreMean + coachScoreStandardDeviation], monthLabel);
  //   graphData.trainingErrorData = new ErrorPoint([trainingScoreMean - trainingScoreStandardDeviation, trainingScoreMean + trainingScoreStandardDeviation], monthLabel);
  //   graphData.generalErrorData = new ErrorPoint([generalScoreMean - generalScoreStandardDeviation, generalScoreMean + generalScoreStandardDeviation], monthLabel);

  //   graphData.classCountData = StackPoint(monthData.totalClassCount, monthLabel, monthData.totalClassCount);
  //   graphData.studentCountData = StackPoint(monthData.studentIdSet.size, monthLabel, monthData.studentIdSet.size);

  //   return graphData;
  // }

  loadReportData() {
    if(this.state.initialDate > this.state.finalDate) {
      return;
    }

    const monthLabelMap = new Map();
    const serviceSet = new Set();
    const coachSet = new Set();
    const coachList = [];

    const date = getAsLocalDate(`${this.state.initialDate}-01`);
    const finalDate = getAsLocalDate(`${this.state.finalDate}-01`);
    finalDate.setMonth(finalDate.getMonth() + 1);
    finalDate.setDate(0);

    const finalYear = finalDate.getFullYear();
    const finalMonth = finalDate.getMonth();

    const monthLabelFormat = new Intl.DateTimeFormat('pt-BR', {month: 'long'});

    let year = date.getFullYear();
    let month = date.getMonth();

    while(year < finalYear || (year === finalYear && month <= finalMonth)) {
      const isoMonth = getLocalDateIsoString(date).slice(0, 7);
      monthLabelMap.set(isoMonth, `${monthLabelFormat.format(date)} ${year}`);

      date.setMonth(date.getMonth() + 1);
      month = date.getMonth();
      year = date.getFullYear();
    }

    for (const dataEntry of this.state.satisfactionData) {
      for (const service of dataEntry.services_analysed) {
        serviceSet.add(service);
      }

      for (const coach of dataEntry.coaches_analysed) {
        if (!coachSet.has(coach.id)) {
          coachSet.add(coach.id);
          coachList.push(coach);
        }
      }
    }

    const overallRoomData = [];
    const overallCoachData = [];
    const overallTrainingData = [];
    const overallGeneralData = [];

    const overallRoomErrorData = [];
    const overallCoachErrorData = [];
    const overallTrainingErrorData = [];
    const overallGeneralErrorData = [];

    const overallClassCountData = [];
    const overallStudentCountData = [];

    const serviceList = [...serviceSet].sort();
    coachList.sort((a, b) => a.name.localeCompare(b.name));

    const serviceGraphDataMap = new Map();
    const coachGraphDataMap = new Map();

    for (const dataEntry of this.state.satisfactionData) {
      const isoMonth = dataEntry.month;
      const monthLabel = monthLabelMap.get(isoMonth);

      overallRoomData.push(StackPoint(dataEntry.overall_score.room_mean_score, monthLabel, Math.round(100 * dataEntry.overall_score.room_mean_score) / 100));
      overallCoachData.push(StackPoint(dataEntry.overall_score.coach_mean_score, monthLabel, Math.round(100 * dataEntry.overall_score.coach_mean_score) / 100));
      overallTrainingData.push(StackPoint(dataEntry.overall_score.training_mean_score, monthLabel, Math.round(100 * dataEntry.overall_score.training_mean_score) / 100));
      overallGeneralData.push(StackPoint(dataEntry.overall_score.general_mean_score, monthLabel, Math.round(100 * dataEntry.overall_score.general_mean_score) / 100));

      overallRoomErrorData.push(new ErrorPoint([dataEntry.overall_score.room_mean_score - dataEntry.overall_score.room_standard_deviation, dataEntry.overall_score.room_mean_score + dataEntry.overall_score.room_standard_deviation], monthLabel));
      overallCoachErrorData.push(new ErrorPoint([dataEntry.overall_score.coach_mean_score - dataEntry.overall_score.coach_standard_deviation, dataEntry.overall_score.coach_mean_score + dataEntry.overall_score.coach_standard_deviation], monthLabel));
      overallTrainingErrorData.push(new ErrorPoint([dataEntry.overall_score.training_mean_score - dataEntry.overall_score.training_standard_deviation, dataEntry.overall_score.training_mean_score + dataEntry.overall_score.training_standard_deviation], monthLabel));
      overallGeneralErrorData.push(new ErrorPoint([dataEntry.overall_score.general_mean_score - dataEntry.overall_score.general_standard_deviation, dataEntry.overall_score.general_mean_score + dataEntry.overall_score.general_standard_deviation], monthLabel));

      overallClassCountData.push(StackPoint(dataEntry.overall_score.total_classes, monthLabel, dataEntry.overall_score.total_classes));
      overallStudentCountData.push(StackPoint(dataEntry.overall_score.total_students, monthLabel, dataEntry.overall_score.total_students));

      // Service

      for (const service of serviceList) {
        let serviceGraphData;

        if (!serviceGraphDataMap.has(service)) {
          serviceGraphData = {
            roomData: [],
            coachData: [],
            trainingData: [],
            generalData: [],
            roomErrorData: [],
            coachErrorData: [],
            trainingErrorData: [],
            generalErrorData: [],
            classCountData: [],
            studentCountData: []
          };

          serviceGraphDataMap.set(service, serviceGraphData);
        }
        else {
          serviceGraphData = serviceGraphDataMap.get(service);
        }

        if (Object.hasOwn(dataEntry.service_score, service)) {
          serviceGraphData.roomData.push(StackPoint(dataEntry.service_score[service].room_mean_score, monthLabel, Math.round(100 * dataEntry.service_score[service].room_mean_score) / 100));
          serviceGraphData.coachData.push(StackPoint(dataEntry.service_score[service].coach_mean_score, monthLabel, Math.round(100 * dataEntry.service_score[service].coach_mean_score) / 100));
          serviceGraphData.trainingData.push(StackPoint(dataEntry.service_score[service].training_mean_score, monthLabel, Math.round(100 * dataEntry.service_score[service].training_mean_score) / 100));
          serviceGraphData.generalData.push(StackPoint(dataEntry.service_score[service].general_mean_score, monthLabel, Math.round(100 * dataEntry.service_score[service].general_mean_score) / 100));

          serviceGraphData.roomErrorData.push(new ErrorPoint([dataEntry.service_score[service].room_mean_score - dataEntry.service_score[service].room_standard_deviation, dataEntry.service_score[service].room_mean_score + dataEntry.service_score[service].room_standard_deviation], monthLabel));
          serviceGraphData.coachErrorData.push(new ErrorPoint([dataEntry.service_score[service].coach_mean_score - dataEntry.service_score[service].coach_standard_deviation, dataEntry.service_score[service].coach_mean_score + dataEntry.service_score[service].coach_standard_deviation], monthLabel));
          serviceGraphData.trainingErrorData.push(new ErrorPoint([dataEntry.service_score[service].training_mean_score - dataEntry.service_score[service].training_standard_deviation, dataEntry.service_score[service].training_mean_score + dataEntry.service_score[service].training_standard_deviation], monthLabel));
          serviceGraphData.generalErrorData.push(new ErrorPoint([dataEntry.service_score[service].general_mean_score - dataEntry.service_score[service].general_standard_deviation, dataEntry.service_score[service].general_mean_score + dataEntry.service_score[service].general_standard_deviation], monthLabel));

          serviceGraphData.classCountData.push(StackPoint(dataEntry.service_score[service].total_classes, monthLabel, dataEntry.service_score[service].total_classes));
          serviceGraphData.studentCountData.push(StackPoint(dataEntry.service_score[service].total_students, monthLabel, dataEntry.service_score[service].total_students));
        }
        else {
          serviceGraphData.roomData.push(StackPoint(0, monthLabel, 0));
          serviceGraphData.coachData.push(StackPoint(0, monthLabel, 0));
          serviceGraphData.trainingData.push(StackPoint(0, monthLabel, 0));
          serviceGraphData.generalData.push(StackPoint(0, monthLabel, 0));

          serviceGraphData.roomErrorData.push(new ErrorPoint([0, 0], monthLabel));
          serviceGraphData.coachErrorData.push(new ErrorPoint([0, 0], monthLabel));
          serviceGraphData.trainingErrorData.push(new ErrorPoint([0, 0], monthLabel));
          serviceGraphData.generalErrorData.push(new ErrorPoint([0, 0], monthLabel));

          serviceGraphData.classCountData.push(StackPoint(0, monthLabel, 0));
          serviceGraphData.studentCountData.push(StackPoint(0, monthLabel, 0));
        }
      }

      for (const coach of coachList) {
        let coachGraphData;

        if (!coachGraphDataMap.has(coach.id)) {
          coachGraphData = {
            roomData: [],
            coachData: [],
            trainingData: [],
            generalData: [],
            roomErrorData: [],
            coachErrorData: [],
            trainingErrorData: [],
            generalErrorData: [],
            classCountData: [],
            studentCountData: []
          };

          coachGraphDataMap.set(coach.id, coachGraphData);
        }
        else {
          coachGraphData = coachGraphDataMap.get(coach.id);
        }

        if (Object.hasOwn(dataEntry.coach_score, coach.id)) {
          coachGraphData.roomData.push(StackPoint(dataEntry.coach_score[coach.id].room_mean_score, monthLabel, Math.round(100 * dataEntry.coach_score[coach.id].room_mean_score) / 100));
          coachGraphData.coachData.push(StackPoint(dataEntry.coach_score[coach.id].coach_mean_score, monthLabel, Math.round(100 * dataEntry.coach_score[coach.id].coach_mean_score) / 100));
          coachGraphData.trainingData.push(StackPoint(dataEntry.coach_score[coach.id].training_mean_score, monthLabel, Math.round(100 * dataEntry.coach_score[coach.id].training_mean_score) / 100));
          coachGraphData.generalData.push(StackPoint(dataEntry.coach_score[coach.id].general_mean_score, monthLabel, Math.round(100 * dataEntry.coach_score[coach.id].general_mean_score) / 100));

          coachGraphData.roomErrorData.push(new ErrorPoint([dataEntry.coach_score[coach.id].room_mean_score - dataEntry.coach_score[coach.id].room_standard_deviation, dataEntry.coach_score[coach.id].room_mean_score + dataEntry.coach_score[coach.id].room_standard_deviation], monthLabel));
          coachGraphData.coachErrorData.push(new ErrorPoint([dataEntry.coach_score[coach.id].coach_mean_score - dataEntry.coach_score[coach.id].coach_standard_deviation, dataEntry.coach_score[coach.id].coach_mean_score + dataEntry.coach_score[coach.id].coach_standard_deviation], monthLabel));
          coachGraphData.trainingErrorData.push(new ErrorPoint([dataEntry.coach_score[coach.id].training_mean_score - dataEntry.coach_score[coach.id].training_standard_deviation, dataEntry.coach_score[coach.id].training_mean_score + dataEntry.coach_score[coach.id].training_standard_deviation], monthLabel));
          coachGraphData.generalErrorData.push(new ErrorPoint([dataEntry.coach_score[coach.id].general_mean_score - dataEntry.coach_score[coach.id].general_standard_deviation, dataEntry.coach_score[coach.id].general_mean_score + dataEntry.coach_score[coach.id].general_standard_deviation], monthLabel));

          coachGraphData.classCountData.push(StackPoint(dataEntry.coach_score[coach.id].total_classes, monthLabel, dataEntry.coach_score[coach.id].total_classes));
          coachGraphData.studentCountData.push(StackPoint(dataEntry.coach_score[coach.id].total_students, monthLabel, dataEntry.coach_score[coach.id].total_students));
        }
        else {
          coachGraphData.roomData.push(StackPoint(0, monthLabel, 0));
          coachGraphData.coachData.push(StackPoint(0, monthLabel, 0));
          coachGraphData.trainingData.push(StackPoint(0, monthLabel, 0));
          coachGraphData.generalData.push(StackPoint(0, monthLabel, 0));

          coachGraphData.roomErrorData.push(new ErrorPoint([0, 0], monthLabel));
          coachGraphData.coachErrorData.push(new ErrorPoint([0, 0], monthLabel));
          coachGraphData.trainingErrorData.push(new ErrorPoint([0, 0], monthLabel));
          coachGraphData.generalErrorData.push(new ErrorPoint([0, 0], monthLabel));

          coachGraphData.classCountData.push(StackPoint(0, monthLabel, 0));
          coachGraphData.studentCountData.push(StackPoint(0, monthLabel, 0));
        }
      }
    }

    const overallData = [
      StackGroup('Limpeza e organização', overallRoomData, TICKET_BAR_PALLET[0]),
      StackGroup('Professores', overallCoachData, TICKET_BAR_PALLET[1]),
      StackGroup('Treino', overallTrainingData, TICKET_BAR_PALLET[2]),
      StackGroup('Geral', overallGeneralData, TICKET_BAR_PALLET[3]),
    ];

    const overallErrorData = [
      overallRoomErrorData,
      overallCoachErrorData,
      overallTrainingErrorData,
      overallGeneralErrorData
    ];

    const overallCountData = [
      LineGroup('Aulas', overallClassCountData, TICKET_LINE_PALLET[0], null, 'solid', false),
      LineGroup('Alunos', overallStudentCountData, TICKET_LINE_PALLET[1]),
    ];

    const unrenewedRateData = [];
    const contractCanceledRateData = [];

    for (const entry of this.state.flux_data) {
      const isoMonth = entry.date;
      const monthLabel = monthLabelMap.get(isoMonth);

      const unrenewedRate = 100 * (entry.outcome - entry.outcome_by_contract_cancel) / entry.total_students
      const contractCanceledRate = 100 * entry.outcome_by_contract_cancel / entry.total_students;

      unrenewedRateData.push(StackPoint(unrenewedRate, monthLabel, Math.round(10 * unrenewedRate) / 10));
      contractCanceledRateData.push(StackPoint(contractCanceledRate, monthLabel, Math.round(10 * contractCanceledRate) / 10));
    }

    const studentLossData = [
      StackGroup('Não renovação', unrenewedRateData, '#5899da', "Total"),
      StackGroup('Cancelamento', contractCanceledRateData, '#da5858', "Total"),
    ];

    this.setState({reportData: {
      overallData,
      overallErrorData,
      overallCountData,
      serviceGraphDataMap,
      coachGraphDataMap,
      serviceList,
      coachList,
      studentLossData
    }});
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getPerClassificationGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  getServiceGraphs() {
    return this.state.reportData.serviceList.map((service) => {
      const serviceData = this.state.reportData.serviceGraphDataMap.get(service);

      const graphData = [
        StackGroup('Limpeza e organização', serviceData.roomData, TICKET_BAR_PALLET[0]),
        StackGroup('Professores', serviceData.coachData, TICKET_BAR_PALLET[1]),
        StackGroup('Treino', serviceData.trainingData, TICKET_BAR_PALLET[2]),
        StackGroup('Geral', serviceData.generalData, TICKET_BAR_PALLET[3]),
      ];

      const graphErrorData = [
        serviceData.roomErrorData,
        serviceData.coachErrorData,
        serviceData.trainingErrorData,
        serviceData.generalErrorData,
      ];

      const graphCountData = [
        LineGroup('Aulas', serviceData.classCountData, TICKET_LINE_PALLET[0], null, 'solid', false),
        LineGroup('Alunos', serviceData.studentCountData, TICKET_LINE_PALLET[1]),
      ];

      return (
        <React.Fragment key={`satisfaction_report:service_graph:${service}`}>

          <DefaultSubSectionTitle
            className="satisfaction-report__sub-section"
            icon={<i className="fa-solid fa-bell-concierge"></i>}
            text={service}
          />

          <StackedBarGraph
            className="satisfaction-report__graph"
            data={graphData}
            improvedErrorData={graphErrorData}
            lineData={graphCountData}
            lineYAxisType="secondary"
            doNotStack={true}
            height={this.getDefaultGraphHeight()}
            legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
            legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            normalLegendOrder={true}
            ToolTipValueCallback={(value) => `${Math.round(10 * value) / 10}`}
          />

        </React.Fragment>
      );
    });
  }

  getCoachGraphs() {
    return this.state.reportData.coachList.map((coach) => {
      const coachData = this.state.reportData.coachGraphDataMap.get(coach.id);

      const graphData = [
        StackGroup('Limpeza e organização', coachData.roomData, TICKET_BAR_PALLET[0]),
        StackGroup('Professores', coachData.coachData, TICKET_BAR_PALLET[1]),
        StackGroup('Treino', coachData.trainingData, TICKET_BAR_PALLET[2]),
        StackGroup('Geral', coachData.generalData, TICKET_BAR_PALLET[3]),
      ];

      const graphErrorData = [
        coachData.roomErrorData,
        coachData.coachErrorData,
        coachData.trainingErrorData,
        coachData.generalErrorData,
      ];

      const graphCountData = [
        LineGroup('Aulas', coachData.classCountData, TICKET_LINE_PALLET[0], null, 'solid', false),
        LineGroup('Alunos', coachData.studentCountData, TICKET_LINE_PALLET[1]),
      ];

      return (
        <React.Fragment key={`satisfaction_report:coach_graph:${coach.id}`}>

          <DefaultSubSectionTitle
            className="satisfaction-report__sub-section"
            icon={<i className="fa-solid fa-user-ninja"></i>}
            text={coach.name}
          />

          <StackedBarGraph
            className="satisfaction-report__graph"
            data={graphData}
            improvedErrorData={graphErrorData}
            lineData={graphCountData}
            lineYAxisType="secondary"
            doNotStack={true}
            height={this.getDefaultGraphHeight()}
            legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
            legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            normalLegendOrder={true}
            ToolTipValueCallback={(value) => `${Math.round(10 * value) / 10}`}
          />

        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.SATISFACTION_REPORT_PATH,
            text: "Relatório de satisfação"
          },
        ]}
        titleIcon={<i className="fas fa-chart-line"></i>}
        title="Relatório de satisfação"
        loading={this.state.loadingData}
      >

        <div className="satisfaction-report__wrapper">

          <div className="satisfaction-report__period-control">

            <h3 className="satisfaction-report__period-control__title">Período de avaliação</h3>

            <div className="satisfaction-report__period-control__inputs-container">

              <HalfWrapper className="satisfaction-report__period-control__inputs">

                <DefaultInput
                  name="initialDateInput"
                  isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                  label="Data inicial"
                  type="month"
                  placeholder="Mês inicial"
                  max={this.state.finalDateInput}
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.initialDateInput}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />

                <DefaultInput
                  name="finalDateInput"
                  isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                  label="Data final"
                  type="month"
                  placeholder="Mês final"
                  min={this.state.initialDateInput}
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.finalDateInput}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />

              </HalfWrapper>

              <button
                className="satisfaction-report__period-control__refresh-button"
                onClick={() => this.applyDateInputChanges()}
                disabled={!this.mayUpdateDateInputs()}
              >

                <i className="fas fa-sync"></i>

              </button>

            </div>

          </div>

          <HorizontalRule />

          {this.state.reportData !== null &&
            <React.Fragment>

             <DefaultSubSectionTitle
                className="satisfaction-report__sub-section"
                icon={<i className="far fa-chart-bar"></i>}
                text="Resumo do período"
              />

              <StackedBarGraph
                className="satisfaction-report__graph"
                data={this.state.reportData.overallData}
                improvedErrorData={this.state.reportData.overallErrorData}
                lineData={this.state.reportData.overallCountData}
                lineYAxisType="secondary"
                doNotStack={true}
                height={this.getDefaultGraphHeight()}
                legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                normalLegendOrder={true}
                ToolTipValueCallback={(value) => `${Math.round(10 * value) / 10}`}
              />

              <DefaultSubSectionTitle
                className="satisfaction-report__sub-section"
                icon={<i className="far fa-chart-bar"></i>}
                text="Taxa de perda de aluno"
              />

              <StackedBarGraph
                data={this.state.reportData.studentLossData}
                lineYAxisType="secondary"
                doNotStack={false}
                height={this.getDefaultGraphHeight()}
                ToolTipValueCallback={(value) => `${Math.round(10 * value) / 10}%`}
                legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                normalLegendOrder={true}
                normalXLabel={true}
              />

              <HorizontalRule />

              <section className="satisfaction-report__report-section">

                <header
                  className="satisfaction-report__report-section__header"
                  onClick={() => this.setState({serviceSectionVisible: !this.state.serviceSectionVisible})}
                >

                  <h3 className="satisfaction-report__report-section__header__text">
                    <i className="far fa-chart-bar satisfaction-report__report-section__header__text-icon"></i>
                    Por serviço
                  </h3>

                  {this.state.serviceSectionVisible ?
                    <i className="fas fa-chevron-down satisfaction-report__report-section__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up satisfaction-report__report-section__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container satisfaction-report__report-section__content"
                  pose={this.state.serviceSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="satisfaction-report__report-section__wrapper">

                    {this.getServiceGraphs()}

                  </div>

                </VerticalAccordionContainer>

              </section>

              <HorizontalRule />

              <section className="satisfaction-report__report-section">

                <header
                  className="satisfaction-report__report-section__header"
                  onClick={() => this.setState({coachSectionVisible: !this.state.coachSectionVisible})}
                >

                  <h3 className="satisfaction-report__report-section__header__text">
                    <i className="far fa-chart-bar satisfaction-report__report-section__header__text-icon"></i>
                    Por treinador
                  </h3>

                  {this.state.coachSectionVisible ?
                    <i className="fas fa-chevron-down satisfaction-report__report-section__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up satisfaction-report__report-section__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container satisfaction-report__report-section__content"
                  pose={this.state.coachSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="satisfaction-report__report-section__wrapper">

                    {this.getCoachGraphs()}

                  </div>

                </VerticalAccordionContainer>

              </section>

            </React.Fragment>
          }

        </div>

      </ContentFrame>
    );
  }
}

export default SatisfactionReport;
