import React from 'react';
import { PoseGroup } from 'react-pose';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ConfirmationWindow from '../confirmation_window';
import ModelTable, {Property} from '../../utils/model_table';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import {getModels, postModel, getAsLocalDate, setUrlParameters, getAbbreviatedName} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE} from '../../constants';
import DefaultInput from '../../utils/default_input';
import {HorizontalContainer, FadeContainer} from '../../utils/pose_containers';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import TabView from '../../components/tab_view';
import ScheduleGrid from '../../components/schedule_grid';
import ContextPopup from '../../components/context_popup';
import * as permissions from '../../permissions';
import './training_time.scss';

class TrainingTime extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: true,
      activeService: '',
      coaches: [],
      work_schedule_list: [],
      training_times: [],
      training_time_exceptions: [],
      services: [],
      class_duration_map: {},
      original_class_duration_map: {},
      checkin_limit_map: {},
      original_checkin_limit_map: {},
      idsToDelete: [],
      exceptionIdsToDelete: [],
      timesToAdd: [],
      exceptionsToAdd: [],
      exceptionDateMap: new Map(),
      trainingTimeToUpdate: null,
      trainingTimeExceptionToUpdate: null,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      exceptionTabSelected: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
      selectedDay: 0,
      selectedExceptionDate: null,
      novoHorario: "",
      newExceptionDate: "",
      popupTarget: null,
      popupContent: null,
      showDataSaved: false,
      coachOptionsVisible: false,
      coachNameFilter: ""
    };

    this.dateFormat = new Intl.DateTimeFormat('pt-BR', {weekday: 'long'});
  }

  async getCoaches() {
    return await getModels(routes.COACHES_GET_API);
  }

  async getWorkScheduleList() {
    if (!this.props.userPermissionIds.includes(permissions.VIEW_WORK_SCHEDULE_PERMISSION_ID)) {
      return [];
    }

    return await getModels(routes.WORK_SCHEDULE_LIST_GET_API);
  }

  async componentDidMount() {
    let available_calendar = getModels(setUrlParameters(routes.TRAINING_TIMES_GET_API, {load_coach_ids: true}));
    let coaches = this.getCoaches();
    let work_schedule_list = this.getWorkScheduleList();
    const services = [];
    let activeService = '';
    let class_duration_map = {};
    let checkin_limit_map = {};
    const exceptionDateMap = new Map();

    available_calendar = await available_calendar;
    coaches = await coaches;
    coaches.sort((a, b) => a.name.localeCompare(b.name));
    work_schedule_list = await work_schedule_list;

    if(available_calendar) {
      for(let service of available_calendar.services) {
        services.push(service);

        if(!activeService) {
          activeService = service;
        }
      }

      if(available_calendar.class_duration_map) {
        class_duration_map = available_calendar.class_duration_map;
      }

      if(available_calendar.checkin_limit_map) {
        checkin_limit_map = available_calendar.checkin_limit_map;
      }

      services.sort((a, b) => a.localeCompare(b));

      const training_times = available_calendar.training_times.map((entry) => {
        entry.updated = false;

        return entry;
      });

      const training_time_exceptions = available_calendar.training_time_exceptions.map((training_time_exception) => {
        training_time_exception.updated = false;

        let exceptionDates = [];

        if (!exceptionDateMap.has(training_time_exception.target_service)) {
          exceptionDateMap.set(training_time_exception.target_service, exceptionDates);
        }
        else {
          exceptionDates = exceptionDateMap.get(training_time_exception.target_service);
        }

        if (!exceptionDates.some((entry) => entry.date === training_time_exception.date)) {
          const date = getAsLocalDate(training_time_exception.date);

          let dayId = date.getDay() - 1;

          if (dayId < 0) {
            dayId = 6;
          }

          const newDate = {
            date: training_time_exception.date,
            weekday: this.dateFormat.format(date),
            dateText: date.toLocaleDateString(),
            dayId
          };

          exceptionDates.push(newDate);
          exceptionDates.sort((a, b) => a.date.localeCompare(b.date));
        }

        return training_time_exception;
      });

      this.setState({
        coaches,
        work_schedule_list,
        training_times,
        training_time_exceptions,
        activeService,
        services,
        class_duration_map,
        original_class_duration_map: {...class_duration_map},
        checkin_limit_map,
        original_checkin_limit_map: {...checkin_limit_map},
        exceptionDateMap,
        loadingData: false,
      });
    }
    else {
      this.props.history.replace(routes.DESKTOP_PATH);
      return;
    }

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name === 'time' || name === 'target_student_count' || name === 'checkin_limit' || name === 'allow_experimental_class' || name === 'experimental_class_limit') {
      if ((name === 'target_student_count' || name === 'checkin_limit' || name === 'experimental_class_limit') && value.length <= 0) {
        value = null;
      }

      if (this.state.exceptionTabSelected) {
        const trainingTimeExceptionToUpdate = {
          ...this.state.trainingTimeExceptionToUpdate,
          [name]: value
        };

        this.setState({
          trainingTimeExceptionToUpdate
        });
      }
      else {
        const trainingTimeToUpdate = {
          ...this.state.trainingTimeToUpdate,
          [name]: value
        };

        this.setState({
          trainingTimeToUpdate
        });
      }
    }
    else if (name === 'coachNameFilter') {
      this.setState({[name]: value});
    }
  }

  onToggleCoachIsActive(coach_id) {
    if (this.state.exceptionTabSelected) {
      const trainingTimeExceptionToUpdate = {
        ...this.state.trainingTimeExceptionToUpdate,
        coach_ids: [...this.state.trainingTimeExceptionToUpdate.coach_ids]
      };

      if(trainingTimeExceptionToUpdate.coach_ids.includes(coach_id)) {
        trainingTimeExceptionToUpdate.coach_ids.splice(trainingTimeExceptionToUpdate.coach_ids.indexOf(coach_id), 1);
      }
      else {
        trainingTimeExceptionToUpdate.coach_ids.push(coach_id);
      }

      this.setState({
        trainingTimeExceptionToUpdate
      });
    }
    else {
      const trainingTimeToUpdate = {
        ...this.state.trainingTimeToUpdate,
        coach_ids: [...this.state.trainingTimeToUpdate.coach_ids]
      };

      if(trainingTimeToUpdate.coach_ids.includes(coach_id)) {
        trainingTimeToUpdate.coach_ids.splice(trainingTimeToUpdate.coach_ids.indexOf(coach_id), 1);
      }
      else {
        trainingTimeToUpdate.coach_ids.push(coach_id);
      }

      this.setState({
        trainingTimeToUpdate
      });
    }
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  async onSave() {
    if(!this.state.activeService) {
      return;
    }

    this.setState({
      loadingData: true
    });

    const class_duration_map = {};

    for(const [key, value] of Object.entries(this.state.class_duration_map)) {
      class_duration_map[key] = parseInt(value);
    }

    const checkin_limit_map = {};

    for(const [key, value] of Object.entries(this.state.checkin_limit_map)) {
      checkin_limit_map[key] = parseInt(value);
    }

    const data = {
      delete_ids: this.state.idsToDelete,
      delete_exception_ids: this.state.exceptionIdsToDelete,
      new_training_times: this.state.timesToAdd,
      new_training_time_exceptions: this.state.exceptionsToAdd.filter((entry) => !this.state.idsToDelete.includes(entry.training_time_id)),
      updated_training_times: this.state.training_times.filter((entry) => entry.updated && !this.state.idsToDelete.includes(entry.id)),
      updated_training_time_exceptions: this.state.training_time_exceptions.filter((entry) => entry.updated && !this.state.idsToDelete.includes(entry.training_time_id) && !this.state.exceptionIdsToDelete.includes(entry.id)),
      class_duration_map,
      checkin_limit_map
    };

    const update = {};

    try {
      const response = await postModel(routes.TRAINING_TIMES_POST_API, data, true);
      update.training_times = response.training_times;
      update.training_time_exceptions = response.training_time_exceptions;
      update.idsToDelete = [];
      update.exceptionIdsToDelete = [];
      update.timesToAdd = [];
      update.exceptionsToAdd = [];
    }
    catch(errors) {
      window.alert(DEFAULT_UNKNOWN_ERROR_MESSAGE);

      this.setState({
        loadingData: false,
      });

      return;
    }

    this.setState({
      loadingData: false,
      showDataSaved: true,
      class_duration_map,
      original_class_duration_map: {...class_duration_map},
      checkin_limit_map,
      original_checkin_limit_map: {...checkin_limit_map},
      ...update
    });

    setTimeout(() => this.setState({showDataSaved: false}), 1500);
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onAcceptConfirmation() {
    if (this.state.deleteId === null) {
      return;
    }

    if(this.state.deleteId.id) {
      const idsToDelete = [...this.state.idsToDelete];

      idsToDelete.push(this.state.deleteId.id);

      this.setState({
        deleteId: null,
        idsToDelete
      });
    }
    else {
      const timesToAdd = [...this.state.timesToAdd];

      timesToAdd.splice(this.state.timesToAdd.indexOf(this.state.deleteId), 1);

      this.setState({
        deleteId: null,
        timesToAdd
      });
    }
  }

  getDays() {
    if(!this.state.activeService) {
      return;
    }

    const weekdayMap = [
      'segunda-feira',
      'terça-feira',
      'quarta-feira',
      'quinta-feira',
      'sexta-feira',
      'sábado',
      'domingo',
    ];

    return weekdayMap.map((day, dayId) => {
      return (
        <React.Fragment key={`training_time:day:${dayId}`}>

          <input
            type="radio"
            id={`training_time:day:${dayId}`}
            name="day"
            value={dayId}
            onChange={() => this.setState({selectedDay: dayId})}
            className="training-time__days__input"
            checked={dayId === this.state.selectedDay}
          />

          <label
            htmlFor={`training_time:day:${dayId}`}
            className="training-time__days__label"
            dayid={dayId}
          >

            <span>{day}</span>

          </label>

        </React.Fragment>
      );
    });
  }

  getExceptionDays() {
    if(!this.state.activeService) {
      return;
    }

    const dates = this.state.exceptionDateMap.get(this.state.activeService);

    return dates.map((entry) => {
      return (
        <React.Fragment key={`training_time:exception_date:${entry.date}`}>

          <input
            className="training-time__days__input"
            type="radio"
            id={`training_time:exception_date:${entry.date}`}
            name="day"
            value={entry.date}
            onChange={() => this.setState({selectedExceptionDate: entry})}
            checked={this.state.selectedExceptionDate !==null && this.state.selectedExceptionDate.date === entry.date}
          />

          <label
            className="training-time__days__label"
            htmlFor={`training_time:exception_date:${entry.date}`}
            dayid={entry.dayId}
          >

            <span>{entry.weekday}</span>
            <span className="training-time__days__label__minor-text">{entry.dateText}</span>

          </label>

        </React.Fragment>
      );
    });
  }

  getHours() {
    if(!this.state.activeService) {
      return;
    }

    const filteredTimes = [
      ...this.state.training_times.filter((entry) => entry.day_id === this.state.selectedDay && entry.target_service === this.state.activeService && !this.state.idsToDelete.includes(entry.id)),
      ...this.state.timesToAdd.filter((entry) => entry.day_id === this.state.selectedDay && entry.target_service === this.state.activeService)
    ];

    if(filteredTimes.length <= 0) {
      return null;
    }

    filteredTimes.sort((a, b) => a.time.localeCompare(b.time));

    return filteredTimes.map((entry) => {
      const highlightCheckinIndicator = entry.target_student_count !== null ||
                                        entry.checkin_limit !== null ||
                                        (!entry.allow_experimental_class || entry.experimental_class_limit !== null);
      const targetStudentCount = entry.target_student_count || this.getDefaultTargetStudentCount(entry);
      const checkinLimit = entry.checkin_limit || this.getDefaultCheckinLimit(entry);
      const allowExperimentalClass = entry.allow_experimental_class;
      const experimentalClassLimit = entry.experimental_class_limit || checkinLimit;

      const entryKey = entry.id ? `training_time:${entry.id}` : `training_time:temporary:service${entry.target_service}:time:${entry.time}`;

      const coaches = this.state.coaches.filter((coach) => entry.coach_ids.includes(coach.id)).map((coach) => (
        <p
          key={`${entryKey}:coach:${coach.id}`}
          className="training-time__hour__coach-name"
        >
          {getAbbreviatedName(coach.name)}
        </p>
      ));

      return (
        <HorizontalContainer
          key={entryKey}
          className="training-time__hour"
        >

          <div className="training-time__hour__main-content">

            <button
              className="training-time__hour__select-time-button"
              onClick={() => this.onClickTime(entry)}
            >

              {entry.is_active &&
                <i className="fas fa-check"></i>
              }

            </button>

            <p className="training-time__hour__text-container">

              <span className="training-time__hour__time">{entry.time}</span>
              <span className={`training-time__hour__target-student-count${highlightCheckinIndicator ? '--highlighted' : ''}`}>({allowExperimentalClass ? `${experimentalClassLimit} - ` : '0 - '}{targetStudentCount}/{checkinLimit})</span>

            </p>

            <div className="training-time__hour__buttons-container">

              <button
                className="training-time__hour__edit-button"
                onClick={() => this.setState({trainingTimeToUpdate: {...entry}})}
              >

                <i className="fa-solid fa-pen"></i>

              </button>

              <button
                className="training-time__hour__remove-button"
                onClick={() => this.setState({deleteId: entry})}
              >

                <i className="fas fa-trash-alt"></i>

              </button>

            </div>

          </div>

          <div className="training-time__hour__coaches">

            {coaches.length > 0 ? coaches : <p className="training-time__hour__coach-name">-</p>}

          </div>

        </HorizontalContainer>
      );
    });
  }

  getExceptionHours() {
    if(!this.state.activeService || this.state.selectedExceptionDate === null) {
      return;
    }

    const dates = this.state.exceptionDateMap.get(this.state.activeService);

    if (!dates.some((entry) => entry.date === this.state.selectedExceptionDate.date)) {
      return;
    }

    const filteredTimes = [
      ...this.state.training_times.filter((entry) => entry.day_id === this.state.selectedExceptionDate.dayId && entry.target_service === this.state.activeService && !this.state.idsToDelete.includes(entry.id))
    ];

    if(filteredTimes.length <= 0) {
      return null;
    }

    filteredTimes.sort((a, b) => a.time.localeCompare(b.time));

    return filteredTimes.map((entry) => {
      let exceptionRelated = this.state.training_time_exceptions.find(
        (training_time_exception) => training_time_exception.date === this.state.selectedExceptionDate.date &&
                                     training_time_exception.training_time_id === entry.id &&
                                     !this.state.exceptionIdsToDelete.includes(training_time_exception.id));

      if (!exceptionRelated) {
        exceptionRelated = this.state.exceptionsToAdd.find(
          (training_time_exception) => training_time_exception.date === this.state.selectedExceptionDate.date &&
                                       training_time_exception.training_time_id === entry.id);
      }

      let highlightCheckinIndicator = null;
      let targetStudentCount = null;
      let checkinLimit = null;
      let allowExperimentalClass = false;
      let experimentalClassLimit = null;

      const entryKey = entry.id ? `training_time_exception:date:${this.state.selectedExceptionDate.date}:${entry.id}` : `training_time_exception:temporary:service${entry.target_service}:date:${this.state.selectedExceptionDate.date}:time:${entry.time}`;

      let coaches = [];

      if (exceptionRelated) {
        highlightCheckinIndicator = exceptionRelated.target_student_count !== null ||
                                    exceptionRelated.checkin_limit !== null ||
                                    entry.target_student_count !== null ||
                                    entry.checkin_limit !== null ||
                                    (!exceptionRelated.allow_experimental_class || (exceptionRelated.experimental_class_limit !== null || entry.experimental_class_limit !== null));
        targetStudentCount = exceptionRelated.target_student_count ||
                             entry.target_student_count ||
                             this.getDefaultTargetStudentCount(exceptionRelated.checkin_limit !== null ? exceptionRelated : entry);
        checkinLimit = exceptionRelated.checkin_limit ||
                       entry.checkin_limit ||
                       this.getDefaultCheckinLimit(entry);
        allowExperimentalClass = exceptionRelated.allow_experimental_class;
        experimentalClassLimit = exceptionRelated.experimental_class_limit || entry.experimental_class_limit || checkinLimit;

        coaches = this.state.coaches.filter((coach) => exceptionRelated.coach_ids.includes(coach.id)).map((coach) => (
          <p
            key={`${entryKey}:coach:${coach.id}`}
            className="training-time__hour__coach-name"
          >
            {getAbbreviatedName(coach.name)}
          </p>
        ));
      }

      return (
        <HorizontalContainer
          key={entryKey}
          className={`training-time__hour${exceptionRelated ? '' : '--gray'}`}
        >

          <div className="training-time__hour__main-content">

            <button
              className="training-time__hour__select-time-button"
              onClick={() => this.onClickExceptionTime(entry)}
            >

              {exceptionRelated &&
                <i className="fas fa-check"></i>
              }

            </button>

            <p className="training-time__hour__text-container">

              <span className="training-time__hour__time">{entry.time}</span>
              {highlightCheckinIndicator !== null &&
                <span className={`training-time__hour__target-student-count${highlightCheckinIndicator ? '--highlighted' : ''}`}>
                ({allowExperimentalClass ? `${experimentalClassLimit} - ` : '0 - '}{targetStudentCount}/{checkinLimit})
                </span>
              }

            </p>

            {exceptionRelated &&
              <div className="training-time__hour__buttons-container">

                <button
                  className="training-time__hour__edit-button"
                  onClick={() => this.setState({
                    trainingTimeExceptionToUpdate: {
                      ...exceptionRelated,
                      time: entry.time
                    }
                  })}
                >

                  <i className="fa-solid fa-gear"></i>

                </button>

              </div>
            }

          </div>

          {exceptionRelated &&
            <div className="training-time__hour__coaches">

              {coaches.length > 0 ? coaches : <p className="training-time__hour__coach-name">-</p>}

            </div>
          }

        </HorizontalContainer>
      );
    });
  }

  onClickTime(training_time_entry) {
    if(!this.state.activeService) {
      return;
    }

    if(training_time_entry.id) {
      const training_times = [...this.state.training_times];
      const index = training_times.findIndex((entry) => entry.id === training_time_entry.id);

      training_times[index] = {
        ...training_times[index],
        is_active: !training_times[index].is_active,
        updated: true
      };

      this.setState({
        training_times
      });
    }
    else {
      const timesToAdd = [...this.state.timesToAdd];
      const index = timesToAdd.indexOf(training_time_entry);

      timesToAdd[index] = {
        ...timesToAdd[index],
        is_active: !timesToAdd[index].is_active
      };

      this.setState({
        timesToAdd
      });
    }
  }

  onClickExceptionTime(training_time_entry) {
    if(!this.state.activeService || this.state.selectedExceptionDate === null) {
      return;
    }

    const training_time_exceptions = [...this.state.training_time_exceptions];

    let index = training_time_exceptions.findIndex((entry) => (entry.date === this.state.selectedExceptionDate.date && entry.training_time_id === training_time_entry.id));

    if(index >= 0) {
      let exceptionIdsToDelete = [...this.state.exceptionIdsToDelete];

      if (this.state.exceptionIdsToDelete.includes(training_time_exceptions[index].id)) {
        exceptionIdsToDelete = exceptionIdsToDelete.filter((entry) => entry !== training_time_exceptions[index].id);
      }
      else {
        exceptionIdsToDelete.push(training_time_exceptions[index].id);
      }

      this.setState({
        exceptionIdsToDelete
      });
    }
    else {
      const exceptionsToAdd = [...this.state.exceptionsToAdd];
      index = exceptionsToAdd.findIndex((entry) => entry.date === this.state.selectedExceptionDate.date && entry.training_time_id === training_time_entry.id);

      if (index >= 0) {
        exceptionsToAdd.splice(index, 1);
      }
      else {
        exceptionsToAdd.push({
          training_time_id: training_time_entry.id,
          target_service: this.state.activeService,
          date: this.state.selectedExceptionDate.date,
          checkin_limit: null,
          target_student_count: null,
          allow_experimental_class: training_time_entry.allow_experimental_class,
          experimental_class_limit: null,
          coach_ids: []
        });
      }

      this.setState({
        exceptionsToAdd
      });
    }
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.addHorario();
    }
  }

  addHorario() {
    if(!this.state.activeService) {
      return;
    }

    if(this.state.novoHorario) {
      if (this.state.training_times.find((entry) => entry.target_service === this.state.activeService && entry.time === this.state.novoHorario && entry.day_id === this.state.selectedDay) ||
          this.state.timesToAdd.find((entry) => entry.target_service === this.state.activeService && entry.time === this.state.novoHorario && entry.day_id === this.state.selectedDay)) {
        this.setState({
          confirmFailed: true,
          confirmFailDescription: "Horário já cadastrado para o dia selecionado."
        });
        return;
      }

      const timesToAdd = [...this.state.timesToAdd];

      timesToAdd.push({
        target_service: this.state.activeService,
        time: this.state.novoHorario,
        day_id: this.state.selectedDay,
        checkin_limit: null,
        target_student_count: null,
        allow_experimental_class: true,
        experimental_class_limit: null,
        coach_ids: [],
        is_active: true
      });

      this.setState({
        novoHorario: "",
        timesToAdd
      });
    }
  }

  addExceptionDate() {
    if(!this.state.activeService) {
      return;
    }

    if(this.state.newExceptionDate) {
      if (this.state.exceptionDateMap.has(this.state.activeService) && this.state.exceptionDateMap.get(this.state.activeService).some((entry) => entry.date === this.state.newExceptionDate)) {
        this.setState({
          confirmFailed: true,
          confirmFailDescription: "Data já cadastrada."
        });
        return;
      }

      const exceptionDateMap = new Map(this.state.exceptionDateMap);

      let exceptionDates = [];

      if (!exceptionDateMap.has(this.state.activeService)) {
        exceptionDateMap.set(this.state.activeService, exceptionDates);
      }
      else {
        exceptionDates = exceptionDateMap.get(this.state.activeService);
      }

      const date = getAsLocalDate(this.state.newExceptionDate);

      let dayId = date.getDay() - 1;

      if (dayId < 0) {
        dayId = 6;
      }

      const newDate = {
        date: this.state.newExceptionDate,
        weekday: this.dateFormat.format(date),
        dateText: date.toLocaleDateString(),
        dayId
      };

      exceptionDates.push(newDate);
      exceptionDates.sort((a, b) => a.date.localeCompare(b.date));

      const update = {
        newExceptionDate: "",
        exceptionDateMap
      };

      if (this.state.selectedExceptionDate === null) {
        update.selectedExceptionDate = newDate;
      }

      this.setState(update);
    }
  }

  getServiceSelectors() {
    return this.state.services.map((service) => {
      const serviceSelected = service === this.state.activeService;

      return (
        <button
          key={`training_time:service:${service.toLowerCase().replace(/ /g, '_')}`}
          className={`training-time__service-button`}
          disabled={serviceSelected}
          onClick={() => this.setState({
            activeService: service
          })}
        >

          {service}

        </button>
      );
    });
  }

  onChangeClassDuration(newValue) {
    if(this.state.class_duration_map) {
      this.setState({class_duration_map: {
        ...this.state.class_duration_map,
        [this.state.activeService]: newValue
      }});
    }
  }

  onChangeCheckinLimit(newValue) {
    if(this.state.checkin_limit_map) {
      this.setState({checkin_limit_map: {
        ...this.state.checkin_limit_map,
        [this.state.activeService]: newValue
      }});
    }
  }

  getClassDuration(service) {
    if(this.state.class_duration_map !== null && (typeof this.state.class_duration_map[service] !== 'undefined')) {
      return this.state.class_duration_map[this.state.activeService];
    }

    return 60;
  }

  getCheckinLimit() {
    if(this.state.checkin_limit_map !== null && (typeof this.state.checkin_limit_map[this.state.activeService] !== 'undefined')) {
      return this.state.checkin_limit_map[this.state.activeService];
    }

    return '';
  }

  hasClassDurationChange() {
    for(const [key, value] of Object.entries(this.state.class_duration_map)) {
      if(!this.state.original_class_duration_map[key] || this.state.original_class_duration_map[key] !== value) {
        return true;
      }
    }

    return false;
  }

  hasCheckinLimitChange() {
    for(const [key, value] of Object.entries(this.state.checkin_limit_map)) {
      if(!this.state.original_checkin_limit_map[key] || this.state.original_checkin_limit_map[key] !== value) {
        return true;
      }
    }

    return false;
  }

  getSelectedDayName() {
    const weekdayMap = [
      'segunda-feira',
      'terça-feira',
      'quarta-feira',
      'quinta-feira',
      'sexta-feira',
      'sábado',
      'domingo',
    ];

    return weekdayMap[this.state.selectedDay];
  }

  getDefaultTargetStudentCount(training_time) {
    const checkin_limit = training_time.checkin_limit || this.getDefaultCheckinLimit(training_time);

    if (checkin_limit === 'INDEFINIDO') {
      return 'INDEFINIDO';
    }

    return Math.ceil(checkin_limit * DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE);
  }

  getDefaultCheckinLimit(training_time) {
    if (this.state.checkin_limit_map[training_time.target_service]) {
      return parseFloat(this.state.checkin_limit_map[training_time.target_service]);
    }

    return 'INDEFINIDO';
  }

  confirmTrainingTimeUpdate() {
    if (this.state.exceptionTabSelected) {
      let target_student_count = this.state.trainingTimeExceptionToUpdate.target_student_count;

      if (target_student_count !== null) {
        target_student_count = parseInt(target_student_count);
      }

      let checkin_limit = this.state.trainingTimeExceptionToUpdate.checkin_limit;

      if (checkin_limit !== null) {
        checkin_limit = parseInt(checkin_limit);
      }

      let experimental_class_limit = this.state.trainingTimeExceptionToUpdate.experimental_class_limit;

      if (experimental_class_limit !== null) {
        experimental_class_limit = parseInt(experimental_class_limit);
      }

      if(this.state.trainingTimeExceptionToUpdate.id) {
        const training_time_exceptions = [...this.state.training_time_exceptions];
        const index = training_time_exceptions.findIndex((entry) => entry.id === this.state.trainingTimeExceptionToUpdate.id);

        training_time_exceptions[index] = {
          ...training_time_exceptions[index],
          checkin_limit,
          target_student_count,
          allow_experimental_class: this.state.trainingTimeExceptionToUpdate.allow_experimental_class,
          experimental_class_limit,
          coach_ids: this.state.trainingTimeExceptionToUpdate.coach_ids,
          updated: true
        };

        this.setState({
          training_time_exceptions,
          trainingTimeExceptionToUpdate: null
        });
      }
      else {
        const exceptionsToAdd = [...this.state.exceptionsToAdd];
        const index = exceptionsToAdd.findIndex((entry) => entry.date === this.state.trainingTimeExceptionToUpdate.date && entry.training_time_id === this.state.trainingTimeExceptionToUpdate.training_time_id);

        exceptionsToAdd[index] = {
          ...exceptionsToAdd[index],
          checkin_limit,
          target_student_count,
          allow_experimental_class: this.state.trainingTimeExceptionToUpdate.allow_experimental_class,
          experimental_class_limit,
          coach_ids: this.state.trainingTimeExceptionToUpdate.coach_ids
        };

        this.setState({
          exceptionsToAdd,
          trainingTimeExceptionToUpdate: null
        });
      }
    }
    else {
      let target_student_count = this.state.trainingTimeToUpdate.target_student_count;

      if (target_student_count !== null) {
        target_student_count = parseInt(target_student_count);
      }

      let checkin_limit = this.state.trainingTimeToUpdate.checkin_limit;

      if (checkin_limit !== null) {
        checkin_limit = parseInt(checkin_limit);
      }

      let experimental_class_limit = this.state.trainingTimeToUpdate.experimental_class_limit;

      if (experimental_class_limit !== null) {
        experimental_class_limit = parseInt(experimental_class_limit);
      }

      if(this.state.trainingTimeToUpdate.id) {
        const training_times = [...this.state.training_times];
        const index = training_times.findIndex((entry) => entry.id === this.state.trainingTimeToUpdate.id);

        training_times[index] = {
          ...training_times[index],
          time: this.state.trainingTimeToUpdate.time,
          checkin_limit,
          target_student_count,
          allow_experimental_class: this.state.trainingTimeToUpdate.allow_experimental_class,
          experimental_class_limit,
          coach_ids: this.state.trainingTimeToUpdate.coach_ids,
          updated: true
        };

        this.setState({
          training_times,
          trainingTimeToUpdate: null
        });
      }
      else {
        const timesToAdd = [...this.state.timesToAdd];
        const index = timesToAdd.findIndex((entry) => entry.target_service === this.state.trainingTimeToUpdate.target_service && entry.time === this.state.trainingTimeToUpdate.time && entry.day_id === this.state.trainingTimeToUpdate.day_id);

        timesToAdd[index] = {
          ...timesToAdd[index],
          time: this.state.trainingTimeToUpdate.time,
          checkin_limit,
          target_student_count,
          allow_experimental_class: this.state.trainingTimeToUpdate.allow_experimental_class,
          experimental_class_limit,
          coach_ids: this.state.trainingTimeToUpdate.coach_ids,
        };

        this.setState({
          timesToAdd,
          trainingTimeToUpdate: null
        });
      }
    }
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteId !== null) {
        return 'Deletando horário';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteId !== null) {
        return 'Falha ao deletar horário';
      }
      else if(this.state.newExceptionDate.length > 0) {
        return 'Falha ao adicionar data';
      }

      return 'Falha ao adicionar horário';
    }

    if(this.state.deleteId !== null) {
      return 'Deletar horário';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId != null) {
      return 'O horário será removido temporariamente até que clique em "salvar". ATENÇÃO: ao salvar, este horário também será removido da configuração das exceções.';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.deleteId != null) {
      return 'Remover horário';
    }

    return 'Unknown';
  }

  getCoachOptions() {
    let coach_ids = null;

    if (this.state.trainingTimeToUpdate !== null) {
      coach_ids = this.state.trainingTimeToUpdate.coach_ids;
    }
    else if (this.state.trainingTimeExceptionToUpdate !== null) {
      coach_ids = this.state.trainingTimeExceptionToUpdate.coach_ids;
    }

    if (coach_ids === null) {
      return null;
    }

    const filteredCoaches = this.state.coaches.filter((coach) => {
      return coach.is_active && coach.name.toLocaleLowerCase().includes(this.state.coachNameFilter.toLocaleLowerCase());
    });

    if(filteredCoaches.length <= 0) {
      return null;
    }

    return filteredCoaches.map((coach) => {
      const selected = coach_ids.includes(coach.id);

      return (
        <div
          key={`training_time:coach_option:${coach.id}`}
          className={`training-time__coach-option${!selected ? '--disabled': ''}`}
        >

          <button
            className="training-time__coach-option__check-button"
            onClick={() => this.onToggleCoachIsActive(coach.id)}
          >

            {selected &&
              <i className="fas fa-check"></i>
            }

          </button>

          <p className="training-time__coach-option__text">

            {getAbbreviatedName(coach.name)}

          </p>

        </div>
      );
    });
  }

  getSelectedSearchTags() {
    let coach_ids = null;

    if (this.state.trainingTimeToUpdate !== null) {
      coach_ids = this.state.trainingTimeToUpdate.coach_ids;
    }
    else if (this.state.trainingTimeExceptionToUpdate !== null) {
      coach_ids = this.state.trainingTimeExceptionToUpdate.coach_ids;
    }

    if (coach_ids === null) {
      return null;
    }

    const filteredCoaches = this.state.coaches.filter((coach) => coach_ids.includes(coach.id));

    return filteredCoaches.map((coach) => (
      <div
        key={`training_time:active_coach_option:${coach.id}`}
        className="training-time__active-coach-option"
      >
        {getAbbreviatedName(coach.name)}

        <button
          className="training-time__active-coach-option__button"
          onClick={(event) => {
            this.onToggleCoachIsActive(coach.id);
            event.stopPropagation();
          }}
        >

          <i className="fa-solid fa-xmark"></i>

        </button>
      </div>
    ));
  }

  getWeekdayTableProperties() {
    let properties = [
      Property('staffName', 'Colaborador', <i className="fa-solid fa-user"></i>, {
        sortable: false
      }),
      Property('weekday_0', 'seg', null, {
        sortable: false
      }),
      Property('weekday_1', 'ter', null, {
        sortable: false
      }),
      Property('weekday_2', 'qua', null, {
        sortable: false
      }),
      Property('weekday_3', 'qui', null, {
        sortable: false
      }),
      Property('weekday_4', 'sex', null, {
        sortable: false
      }),
      Property('weekday_5', 'sáb', null, {
        sortable: false
      }),
      Property('weekday_6', 'dom', null, {
        sortable: false
      }),
      Property('totalHours', 'Total', null, {
        getSortCallback: (a, b) => {
          if (a.id === 0) {
            return -1;
          }

          return a.totalHours - b.totalHours;
        },
        sortable: false
      })
    ];

    return properties;
  }

  getServiceTableProperties(services) {
    let properties = [
      Property('staffName', 'Colaborador', <i className="fa-solid fa-user"></i>, {
        sortable: false
      })
    ];

    services.forEach((service) => {
      properties.push(
        Property(service, service, null, {
          sortable: false,
          cellClassName: service === this.state.activeService ? 'training-time__overview-table__cell--highlight' : '',
          headerClassName: service === this.state.activeService ? 'training-time__overview-table__cell--highlight' : '',
        }),
      );
    });

    properties.push(
      Property('totalHours', 'Total', null, {
        getSortCallback: (a, b) => {
          if (a.id === 0) {
            return -1;
          }

          return a.totalHours - b.totalHours;
        },
        sortable: false
      })
    );

    return properties;
  }

  getScheduleOverview() {
    const training_times = [
      ...this.state.training_times.filter((entry) => entry.is_active && entry.coach_ids.length > 0),
      ...this.state.timesToAdd.filter((entry) => entry.is_active && entry.coach_ids.length > 0)
    ];

    const scheduleGridData = [];

    const coachMap = new Map();
    const totalWeekdayMap = new Map();
    const totalServiceMap = new Map();
    let totalWeekHours = 0;

    for (const training_time of training_times) {
      for (const coach_id of training_time.coach_ids) {
        let coachMapEntry;

        if (!coachMap.has(coach_id)) {
          coachMapEntry = {
            coach: this.state.coaches.find((coach) => coach.id === coach_id),
            entries: [],
            weekdayMap: new Map(),
            serviceMap: new Map(),
            totalHours: 0
          };

          coachMap.set(coach_id, coachMapEntry);
        }
        else {
          coachMapEntry = coachMap.get(coach_id);
        }

        const classDuration = this.getClassDuration(training_time.target_service);

        let parsedHour = parseInt(training_time.time.slice(0, 2));
        let parsedMinutes = parseInt(training_time.time.slice(3, 5)) + classDuration;

        const totalHours = classDuration / 60;

        coachMapEntry.totalHours += totalHours;
        totalWeekHours += totalHours;

        parsedHour += Math.floor(parsedMinutes / 60);
        parsedMinutes = parsedMinutes % 60;

        let totalWeekdayMapEntry = 0;

        if (totalWeekdayMap.has(training_time.day_id)) {
          totalWeekdayMapEntry = totalWeekdayMap.get(training_time.day_id);
        }

        totalWeekdayMapEntry += totalHours;
        totalWeekdayMap.set(training_time.day_id, totalWeekdayMapEntry);

        let weekdayMapEntry = 0;

        if (coachMapEntry.weekdayMap.has(training_time.day_id)) {
          weekdayMapEntry = coachMapEntry.weekdayMap.get(training_time.day_id);
        }

        weekdayMapEntry += totalHours;
        coachMapEntry.weekdayMap.set(training_time.day_id, weekdayMapEntry);

        let totalServiceMapEntry = 0;

        if (totalServiceMap.has(training_time.target_service)) {
          totalServiceMapEntry = totalServiceMap.get(training_time.target_service);
        }

        totalServiceMapEntry += totalHours;
        totalServiceMap.set(training_time.target_service, totalServiceMapEntry);

        let serviceMapEntry = 0;

        if (coachMapEntry.serviceMap.has(training_time.target_service)) {
          serviceMapEntry = coachMapEntry.serviceMap.get(training_time.target_service);
        }

        serviceMapEntry += totalHours;
        coachMapEntry.serviceMap.set(training_time.target_service, serviceMapEntry);

        parsedHour = parsedHour < 10 ? `0${parsedHour}` : parsedHour.toString();
        parsedMinutes = parsedMinutes < 10 ? `0${parsedMinutes}` : parsedMinutes.toString();

        coachMapEntry.entries.push({
          start_at: training_time.time,
          end_at: `${parsedHour}:${parsedMinutes}`,
          target_service: training_time.target_service,
          day_id: training_time.day_id
        });
      }
    }

    for (const work_schedule of this.state.work_schedule_list) {
      let coachMapEntry;

      const coach = this.state.coaches.find((coach) => coach.id === work_schedule.user_id);

      if (!coach || work_schedule.start_at >= work_schedule.end_at) {
        continue;
      }

      if (!coachMap.has(coach.id)) {
        coachMapEntry = {
          coach: coach,
          entries: [],
          weekdayMap: new Map(),
          serviceMap: new Map(),
          totalHours: 0
        };

        coachMap.set(coach.id, coachMapEntry);
      }
      else {
        coachMapEntry = coachMap.get(coach.id);
      }

      let startAtHour = parseInt(work_schedule.start_at.slice(0, 2));
      let startAtMinutes = parseInt(work_schedule.start_at.slice(3, 5));

      let endAtHour = parseInt(work_schedule.end_at.slice(0, 2));
      let endAtMinutes = parseInt(work_schedule.end_at.slice(3, 5));

      const totalHours = (endAtHour - startAtHour) + ((endAtMinutes - startAtMinutes) / 60);

      coachMapEntry.totalHours += totalHours;
      totalWeekHours += totalHours;

      let totalWeekdayMapEntry = 0;

      if (totalWeekdayMap.has(work_schedule.day_id)) {
        totalWeekdayMapEntry = totalWeekdayMap.get(work_schedule.day_id);
      }

      totalWeekdayMapEntry += totalHours;
      totalWeekdayMap.set(work_schedule.day_id, totalWeekdayMapEntry);

      let weekdayMapEntry = 0;

      if (coachMapEntry.weekdayMap.has(work_schedule.day_id)) {
        weekdayMapEntry = coachMapEntry.weekdayMap.get(work_schedule.day_id);
      }

      weekdayMapEntry += totalHours;
      coachMapEntry.weekdayMap.set(work_schedule.day_id, weekdayMapEntry);

      let totalServiceMapEntry = 0;

      if (totalServiceMap.has('Extra')) {
        totalServiceMapEntry = totalServiceMap.get('Extra');
      }

      totalServiceMapEntry += totalHours;
      totalServiceMap.set('Extra', totalServiceMapEntry);

      let serviceMapEntry = 0;

      if (coachMapEntry.serviceMap.has('Extra')) {
        serviceMapEntry = coachMapEntry.serviceMap.get('Extra');
      }

      serviceMapEntry += totalHours;
      coachMapEntry.serviceMap.set('Extra', serviceMapEntry);

      coachMapEntry.entries.push({
        start_at: work_schedule.start_at.slice(0, 5),
        end_at: work_schedule.end_at.slice(0, 5),
        target_service: 'Extra',
        day_id: work_schedule.day_id
      });
    }

    const weekdayTableData = [];
    const serviceTableData = [];
    const services = [...totalServiceMap.keys()].sort((a, b) => {
      if (a === "Extra") {
        return 1;
      }

      return a.localeCompare(b);
    });

    for (const coachMapEntry of coachMap.values()) {
      const text = getAbbreviatedName(coachMapEntry.coach.name);
      const sort_tag = text;

      coachMapEntry.entries.sort((a, b) => {
        if (a.target_service === b.target_service) {
          if(a.day_id === b.day_id) {
            return a.start_at.localeCompare(b.start_at);
          }

          return a.day_id - b.day_id;
        }

        return a.target_service.localeCompare(b.target_service);
      });

      let lastEntry = null;

      for (const entry of coachMapEntry.entries) {
        if (lastEntry === null) {
          lastEntry = entry;
          continue;
        }

        if (entry.start_at === lastEntry.end_at && entry.target_service === lastEntry.target_service && entry.day_id === lastEntry.day_id) {
          lastEntry = {
            ...lastEntry,
            end_at: entry.end_at
          };

          continue;
        }

        scheduleGridData.push({
          start_at: lastEntry.start_at,
          end_at: lastEntry.end_at,
          day_id: lastEntry.day_id,
          sort_tag,
          text,
          popupContent: (
            <div className="training-time__schedule-grid-entry__popup-content">
              <p className="training-time__schedule-grid-entry__popup-content__text">{lastEntry.target_service}</p>
              <p className="training-time__schedule-grid-entry__popup-content__text">{text}</p>
              <p className="training-time__schedule-grid-entry__popup-content__text">{`${lastEntry.start_at} - ${lastEntry.end_at}`}</p>
            </div>
          ),
          className: `training-time__schedule-grid-entry${lastEntry.target_service === this.state.activeService ? '--selected' : lastEntry.target_service === 'Extra' ? '--gray' : ''}`
        });

        lastEntry = entry;
      }

      scheduleGridData.push({
        start_at: lastEntry.start_at,
        end_at: lastEntry.end_at,
        day_id: lastEntry.day_id,
        sort_tag,
        text,
        popupContent: (
            <div className="training-time__schedule-grid-entry__popup-content">
              <p className="training-time__schedule-grid-entry__popup-content__text">{lastEntry.target_service}</p>
              <p className="training-time__schedule-grid-entry__popup-content__text">{text}</p>
              <p className="training-time__schedule-grid-entry__popup-content__text">{`${lastEntry.start_at} - ${lastEntry.end_at}`}</p>
            </div>
          ),
        className: `training-time__schedule-grid-entry${lastEntry.target_service === this.state.activeService ? '--selected' : lastEntry.target_service === 'Extra' ? '--gray' : ''}`
      });

      weekdayTableData.push({
        id: coachMapEntry.coach.id,
        staffName: coachMapEntry.coach.name,
        weekday_0: coachMapEntry.weekdayMap.get(0),
        weekday_1: coachMapEntry.weekdayMap.get(1),
        weekday_2: coachMapEntry.weekdayMap.get(2),
        weekday_3: coachMapEntry.weekdayMap.get(3),
        weekday_4: coachMapEntry.weekdayMap.get(4),
        weekday_5: coachMapEntry.weekdayMap.get(5),
        weekday_6: coachMapEntry.weekdayMap.get(6),
        totalHours: coachMapEntry.totalHours,
      });

      const serviceTableEntry = {
        id: coachMapEntry.coach.id,
        staffName: coachMapEntry.coach.name,
        totalHours: coachMapEntry.totalHours,
      };

      services.forEach((service) => {
        serviceTableEntry[service] = coachMapEntry.serviceMap.get(service)
      });

      serviceTableData.push(serviceTableEntry);
    }

    weekdayTableData.push({
      id: 0,
      staffName: 'Total',
      weekday_0: totalWeekdayMap.get(0),
      weekday_1: totalWeekdayMap.get(1),
      weekday_2: totalWeekdayMap.get(2),
      weekday_3: totalWeekdayMap.get(3),
      weekday_4: totalWeekdayMap.get(4),
      weekday_5: totalWeekdayMap.get(5),
      weekday_6: totalWeekdayMap.get(6),
      totalHours: totalWeekHours,
    });

    const totalServiceTableEntry = {
      id: 0,
      staffName: 'Total',
      totalHours: totalWeekHours
    };
    services.forEach((service) => {
      totalServiceTableEntry[service] = totalServiceMap.get(service)
    });
    serviceTableData.push(totalServiceTableEntry);

    return (
      <React.Fragment>

        <DefaultSubSectionTitle
          icon={<i className="fa-solid fa-clock"></i>}
          text="Grade de horário geral (padrão)"
        />

        <ScheduleGrid
          data={scheduleGridData}
        />

        <DefaultSubSectionTitle
          className="training-time__overview-table__section-title"
          icon={<i className="fa-solid fa-calendar-week"></i>}
          text="Por dias da semana (padrão)"
        />

        <ModelTable
          id='training_time:weekday_table'
          properties={this.getWeekdayTableProperties()}
          getRowClassName={(row) => row.id === 0 ? 'training-time__overview-table__total-row' : ''}
          data={weekdayTableData}
          initialLinesPerPage={weekdayTableData.length}
          initialOrderBy="totalHours"
          initialOrderIsDecrescent={true}
          hideFilter={true}
          hideLinesPerPageControl={true}
          hideNavigationControls={true}
        >
        </ModelTable>

        <DefaultSubSectionTitle
          className="training-time__overview-table__section-title"
          icon={<i className="fa-solid fa-bell-concierge"></i>}
          text="Por serviço (padrão)"
        />

        <ModelTable
          id='training_time:service_table'
          properties={this.getServiceTableProperties(services)}
          getRowClassName={(row) => row.id === 0 ? 'training-time__overview-table__total-row' : ''}
          data={serviceTableData}
          initialLinesPerPage={weekdayTableData.length}
          initialOrderBy="totalHours"
          initialOrderIsDecrescent={true}
          hideFilter={true}
          hideLinesPerPageControl={true}
          hideNavigationControls={true}
        >
        </ModelTable>

      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="training-time__overlay"
          visible={this.state.trainingTimeToUpdate != null || this.state.trainingTimeExceptionToUpdate != null}
          actions={(
            <div className="training-time__overlay__action-container">

              <DefaultMenuButton
                className="training-time__overlay__action-button"
                onClick={() => this.setState({
                  trainingTimeToUpdate: null,
                  trainingTimeExceptionToUpdate: null,
                })}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="training-time__overlay__action-button"
                onClick={() => this.confirmTrainingTimeUpdate()}
                color="green"
                text="Confirmar"
              />

            </div>
          )}
        >

          <header className="training-time__overlay__header">

            <h3 className="training-time__overlay__header__title">
              {`${this.state.activeService} - ${this.getSelectedDayName()}: editar horário`}
            </h3>

          </header>

          <hr className="training-time__horizontal-rule" />

          <div className="training-time__overlay__content">

            {(this.state.trainingTimeToUpdate !== null || this.state.trainingTimeExceptionToUpdate !== null) &&
              <React.Fragment>

                <DefaultInput
                  name="time"
                  label="Horário da aula"
                  type="time"
                  placeholder="Horário da aula"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.exceptionTabSelected ? this.state.trainingTimeExceptionToUpdate.time : this.state.trainingTimeToUpdate.time}
                  autoComplete="off"
                  disabled={true}
                />

                <DefaultInput
                  name="target_student_count"
                  label="Meta de ocupação na aula"
                  type="number"
                  placeholder="Meta de ocupação"
                  step="1"
                  min="1"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={(this.state.exceptionTabSelected ? this.state.trainingTimeExceptionToUpdate.target_student_count : this.state.trainingTimeToUpdate.target_student_count) || ''}
                  autoComplete="off"
                  suffix="aluno(s)"
                  labelMessage={this.state.exceptionTabSelected ? null : `Em caso de não preenchimento a meta de ocupação será de ${100 * DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE}% da ocupação máxima (${this.getDefaultTargetStudentCount(this.state.trainingTimeToUpdate)}).`}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="checkin_limit"
                  label="Limite de checkin"
                  type="number"
                  placeholder="Limite de checkin"
                  step="1"
                  min="1"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={(this.state.exceptionTabSelected ? this.state.trainingTimeExceptionToUpdate.checkin_limit : this.state.trainingTimeToUpdate.checkin_limit) || ''}
                  autoComplete="off"
                  suffix="aluno(s)"
                  labelMessage={this.state.exceptionTabSelected ? null : `Em caso de não preenchimento o limite de checkin será o padrão definido (${this.getDefaultCheckinLimit(this.state.trainingTimeToUpdate)}).`}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="allow_experimental_class"
                  label="Permite agendamento de aula expeperimental:"
                  type="toggle"
                  isHorizontal={false}
                  // horizontalAlign="right"
                  activeText="Sim"
                  inactiveText="Não"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.exceptionTabSelected ? this.state.trainingTimeExceptionToUpdate.allow_experimental_class : this.state.trainingTimeToUpdate.allow_experimental_class}
                />

                {(this.state.exceptionTabSelected ? this.state.trainingTimeExceptionToUpdate.allow_experimental_class : this.state.trainingTimeToUpdate.allow_experimental_class) &&
                  <DefaultInput
                    name="experimental_class_limit"
                    label="Limite de aulas experimentais"
                    type="number"
                    placeholder="Limite de aulas experimentais"
                    step="1"
                    min="1"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={(this.state.exceptionTabSelected ? this.state.trainingTimeExceptionToUpdate.experimental_class_limit : this.state.trainingTimeToUpdate.experimental_class_limit) || ''}
                    autoComplete="off"
                    suffix="agendamento(s)"
                    labelMessage={this.state.exceptionTabSelected ? null : 'Em caso de não preenchimento a aula poderá ser preenchida por aulas experimentais.'}
                    onFocus={(event) => event.target.select()}
                  />
                }

                <section className="training-time__default-section">

                  <header
                    className="training-time__default-section__header"
                    onClick={() => this.setState({coachOptionsVisible: !this.state.coachOptionsVisible})}
                  >

                    <h3 className="training-time__default-section__header__text">

                      <div className="training-time__default-section__header__text-wrapper">
                        <i className="fa-solid fa-user-ninja training-time__default-section__header__text-icon"></i>
                        Professores responsáveis:
                      </div>
                      <div className="training-time__active-coaches__wrapper">
                        {this.getSelectedSearchTags()}
                      </div>

                    </h3>

                    {this.state.coachOptionsVisible ?
                      <i className="fas fa-chevron-down training-time__default-section__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up training-time__default-section__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container training-time__default-section__content"
                    pose={this.state.coachOptionsVisible ? 'verticalOpen' : 'verticalClosed'}
                  >

                    <div className="vertical-accordion-container training-time__default-section__content-wrapper">

                      <div className="training-time__default-section-container">

                        <DefaultInput
                          name="coachNameFilter"
                          label="Busca rápida:"
                          type="text"
                          handleInputChange={(event) => this.handleInputChange(event)}
                          value={this.state.coachNameFilter}
                          autoComplete="off"
                          disabled={this.state.confirmInProgress}
                        />

                        <div className="training-time__coach-options__container">

                          {this.getCoachOptions()}

                        </div>

                      </div>

                    </div>

                  </VerticalAccordionContainer>

                </section>

              </React.Fragment>
            }

          </div>

        </OverlayWindow>

        <ContextPopup
          className="training-time__context-popup"
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          hideConfirmButton={this.state.confirmFailed}
          visible={this.state.deleteId !== null || this.state.confirmFailed}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.MANAGE_TRAINING_TIMES_PATH,
              text: "Horários de funcionamento"
            },
          ]}
          titleIcon={<i className="far fa-clock"></i>}
          title="Horários de funcionamento"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="training-time"
            title="Gerenciar Horários de funcionamento"
          >

            <DefaultSubSectionTitle
              className="training-time__service-selector-header"
              icon={<i className="fas fa-list"></i>}
              text="Selecione o serviço que deseja configurar"
            />

            <div className="training-time__service-selector">

              {this.getServiceSelectors()}

            </div>

            <HorizontalRule />

            <TabView
              id="training_time"
              tabOptions={[
                {
                  id: 1,
                  text: (
                    <span className="training-time__tab-option-wrapper">

                      Padrão
                      <i
                        className="fa-solid fa-circle-info training-time__info-icon"
                        onMouseEnter={(event) => this.onShowHoverdata(
                          event.target,
                          'Configuração dos horários dos serviços em dias normais de funcionamento.\n\nLegenda do texto abaixo dos horários no formato "(A - B/C)": \n-A: quantidade de aulas experimentais liberadas\n-B: meta de presença para o horário\n-C: capacidade de atendimento no horário')}
                        onMouseLeave={(event) => this.onHideHoverdata()}
                      >
                      </i>

                    </span>
                  )
                },
                {
                  id: 2,
                  text: (
                    <span className="training-time__tab-option-wrapper">

                      Exceção
                      <i
                        className="fa-solid fa-circle-info training-time__info-icon"
                        onMouseEnter={(event) => this.onShowHoverdata(
                          event.target,
                          'Utilize para sobrescrever as configurações dos horários dos serviços em uma data específica. Note que esta aba exibirá apenas os horários já salvos. Caso precise desabilitar todos os horários de uma determinada data, utilize a funcionalidade "Calendário de treinos".')}
                        onMouseLeave={(event) => this.onHideHoverdata()}
                      >
                      </i>

                    </span>
                  )
                }
              ]}
              initialTab={this.state.exceptionTabSelected ? 2 : 1}
              onSelectTab={(optionId) => this.setState({exceptionTabSelected: optionId === 2})}
              contentCallback={(optionsId) => {
                switch(optionsId) {
                  case 1:
                    return (
                      <div className="training-time__content-wrapper">

                        <div className="training-time__days">

                          <h3 className="training-time__days-title">Dias</h3>

                          <div className="training-time__days__wrapper">

                            {this.getDays()}

                          </div>


                        </div>

                        <div className="training-time__hour-manager">

                          <h3 className="training-time__hour-manager-title">Horários</h3>

                          <div className="training-time__hours">

                            <PoseGroup flipMove={false}>

                              {this.getHours()}

                            </PoseGroup>

                          </div>

                          <div className="training-time__add-hour-wrapper">

                            <DefaultInput
                              name="name"
                              type="time"
                              placeholder="Digite um novo horário"
                              handleInputChange={(event) => this.setState({novoHorario: event.target.value})}
                              value={this.state.novoHorario}
                              onKeyDown={(event) => this.handleKeyDown(event)}
                              isHorizontal={true}
                            />

                            <button
                              className="training-time__add-hour-button"
                              disabled={!this.state.novoHorario}
                              onClick={() => this.addHorario()}
                            >

                              Adicionar horário

                            </button>

                          </div>

                        </div>

                      </div>
                    );
                  case 2:
                    return (
                      <div className="training-time__exception">

                        <div className="training-time__exception__action-container">

                          <div className="training-time__add-hour-wrapper">

                            <DefaultInput
                              name="date"
                              type="date"
                              placeholder="Digite uma nova data"
                              min={(new Date()).toISOString().slice(0, 10)}
                              handleInputChange={(event) => this.setState({newExceptionDate: event.target.value})}
                              value={this.state.newExceptionDate}
                              onKeyDown={(event) => this.handleKeyDown(event)}
                              isHorizontal={true}
                            />

                            <button
                              className="training-time__add-hour-button"
                              disabled={!this.state.newExceptionDate}
                              onClick={() => this.addExceptionDate()}
                            >

                              Adicionar data

                            </button>

                          </div>

                        </div>

                        {(this.state.exceptionDateMap.has(this.state.activeService) && this.state.exceptionDateMap.get(this.state.activeService).length > 0) &&
                          <div className="training-time__content-wrapper">

                            <div className="training-time__days">

                              <h3 className="training-time__days-title">Datas</h3>

                              <div className="training-time__days__wrapper">

                                {this.getExceptionDays()}

                              </div>


                            </div>

                            <div className="training-time__hour-manager">

                              <h3 className="training-time__hour-manager-title">Horários</h3>

                              <div className="training-time__hours">

                                <PoseGroup flipMove={false}>

                                  {this.getExceptionHours()}

                                </PoseGroup>

                              </div>

                            </div>

                          </div>
                        }

                      </div>
                    );
                  default:
                    return null;
                }
              }}
            />

            <HorizontalRule />

            <DefaultInput
              className="training-time__class-duration-input"
              name="class_duration_map"
              type="number"
              label="Duração da aula:"
              min="1"
              step="1"
              suffix="minutos"
              placeholder="Duração da aula"
              handleInputChange={(event) => this.onChangeClassDuration(event.target.value)}
              value={this.getClassDuration(this.state.activeService)}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              isHorizontal={this.state.screenWidth > 510}
            />

            <DefaultInput
              className="training-time__checkin-limit-input"
              name="checkin_limit_map"
              type="number"
              label="Limite de checkin por aula:"
              min="1"
              step="1"
              suffix="aluno(s)"
              placeholder="Limite de checkin"
              handleInputChange={(event) => this.onChangeCheckinLimit(event.target.value)}
              value={this.getCheckinLimit()}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              isHorizontal={this.state.screenWidth > 510}
            />

            <HorizontalRule />

            {this.getScheduleOverview()}

            <HorizontalRule />

            <div className="training-time__buttons-container">

              <button
                className="training-time__save-button"
                onClick={() => {this.onSave()}}
                disabled={this.state.idsToDelete.length <= 0 &&
                          this.state.exceptionIdsToDelete.length <= 0 &&
                          this.state.timesToAdd.length <= 0 &&
                          this.state.exceptionsToAdd.length <= 0 &&
                          !this.state.training_times.some((entry) => entry.updated) &&
                          !this.state.training_time_exceptions.some((entry) => entry.updated) &&
                          !this.hasClassDurationChange() && !this.hasCheckinLimitChange()}
              >

                Salvar

              </button>

              <PoseGroup>

                {this.state.showDataSaved &&
                  <FadeContainer key="training-time__data-saved-text">

                    <p className="training-time__data-saved-text">Dados de salvos!</p>

                  </FadeContainer>
                }

              </PoseGroup>

            </div>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingTime;
