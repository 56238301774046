import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import ContextPopup from '../../components/context_popup';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import ScheduleGrid from '../../components/schedule_grid';
import {getModels, deleteModel, setUrlParameters, getAbbreviatedName} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';
import './work_schedule_list.scss';

class WorkScheduleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      work_schedule_list: [],
      users: [],
      training_times: [],
      class_duration_map: {},
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      popupContent: null,
      popupTarget: null,
      screenWidth: window.innerWidth
    };
  }

  async getStaff() {
    if (!this.props.userPermissionIds.includes(permissions.VIEW_STAFF_PERMISSION_ID)) {
      return [];
    }

    return await getModels(routes.STAFF_GET_API);
  }

  async getTrainingTimeData() {
    if (!this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_TIME_PERMISSION_ID)) {
      return null;
    }

    return await getModels(setUrlParameters(routes.TRAINING_TIMES_GET_API, {load_coach_ids: true}));
  }

  async getWorkScheduleList() {
    const parameters = {load_user: true};

    return await getModels(setUrlParameters(routes.WORK_SCHEDULE_LIST_GET_API, parameters));
  }

  async componentDidMount() {
    let update = this.reloadList(false);

    let users = this.getStaff();
    let trainingTimeData = this.getTrainingTimeData();

    update = await update;
    update.users = await users;
    trainingTimeData = await trainingTimeData;

    if (trainingTimeData !== null) {
      if(trainingTimeData.class_duration_map) {
        update.class_duration_map = trainingTimeData.class_duration_map;
      }
      update.training_times = trainingTimeData.training_times;
    }

    update.loadingData = false;

    this.setState(update)

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(updateState=true) {
    if (updateState) {
      this.setState({
        loadingData: true
      });
    }

    const work_schedule_list = await this.getWorkScheduleList();

    const update = {
      loadingData: false,
      work_schedule_list: work_schedule_list
    };

    if (updateState) {
      this.setState(update);
    }

    return update;
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.WORK_SCHEDULE_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_WORK_SCHEDULE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_WORK_SCHEDULE_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_WORK_SCHEDULE_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.WORK_SCHEDULE_EDIT_PAGE}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_WORK_SCHEDULE_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getProperties() {
    const weekdays = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo'
    ];

    let properties = [
      Property('user_id', 'Colaborador', <i className="fa-solid fa-user"></i>, {
        getDataText: (entry) => (
          <p className="work-schedule-list__user-name">

              {entry.user.name}

              {entry.description && (
                <i
                  className="fa-solid fa-circle-info work-schedule-list__user-name__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, (
                    <p className="work-schedule-list__entry-info">{entry.description}</p>
                  ))}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>
              )}

            </p>
        ),
        getFilterText: (entry) => entry.user.name
      }),
      Property('day_id', 'Dia', <i className="fa-solid fa-calendar-day"></i>, {
        getDataText: (entry) => weekdays[entry.day_id],
        getFilterText: (entry) => weekdays[entry.day_id]
      }),
      Property('start_at', 'Entrada', <i className="fa-solid fa-clock"></i>, {
        getDataText: (entry) => entry.start_at.slice(0, 5),
        getFilterText: (entry) => entry.start_at.slice(0, 5)
      }),
      Property('end_at', 'Saída', <i className="fa-solid fa-clock"></i>, {
        getDataText: (entry) => entry.end_at.slice(0, 5),
        getFilterText: (entry) => entry.end_at.slice(0, 5)
      }),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando período';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar período';
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  getClassDuration(service) {
    if(this.state.class_duration_map !== null && (typeof this.state.class_duration_map[service] !== 'undefined')) {
      return this.state.class_duration_map[this.state.activeService];
    }

    return 60;
  }

  getWeekdayTableProperties() {
    let properties = [
      Property('staffName', 'Colaborador', <i className="fa-solid fa-user"></i>, {
        sortable: false
      }),
      Property('weekday_0', 'seg', null, {
        sortable: false
      }),
      Property('weekday_1', 'ter', null, {
        sortable: false
      }),
      Property('weekday_2', 'qua', null, {
        sortable: false
      }),
      Property('weekday_3', 'qui', null, {
        sortable: false
      }),
      Property('weekday_4', 'sex', null, {
        sortable: false
      }),
      Property('weekday_5', 'sáb', null, {
        sortable: false
      }),
      Property('weekday_6', 'dom', null, {
        sortable: false
      }),
      Property('totalHours', 'Total', null, {
        getSortCallback: (a, b) => {
          if (a.id === 0) {
            return -1;
          }

          return a.totalHours - b.totalHours;
        },
        sortable: false
      })
    ];

    return properties;
  }

  getServiceTableProperties(services) {
    let properties = [
      Property('staffName', 'Colaborador', <i className="fa-solid fa-user"></i>, {
        sortable: false
      })
    ];

    services.forEach((service) => {
      properties.push(
        Property(service, service, null, {
          sortable: false
        }),
      );
    });

    properties.push(
      Property('totalHours', 'Total', null, {
        getSortCallback: (a, b) => {
          if (a.id === 0) {
            return -1;
          }

          return a.totalHours - b.totalHours;
        },
        sortable: false
      })
    );

    return properties;
  }

  getScheduleOverview() {
    if (!this.props.userPermissionIds.includes(permissions.VIEW_STAFF_PERMISSION_ID) || !this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_TIME_PERMISSION_ID)) {
      return null;
    }

    const training_times = [
      ...this.state.training_times.filter((entry) => entry.is_active && entry.coach_ids.length > 0)
    ];

    const scheduleGridData = [];

    const coachMap = new Map();
    const totalWeekdayMap = new Map();
    const totalServiceMap = new Map();
    let totalWeekHours = 0;

    for (const training_time of training_times) {
      for (const coach_id of training_time.coach_ids) {
        let coachMapEntry;

        if (!coachMap.has(coach_id)) {
          coachMapEntry = {
            coach: this.state.users.find((user) => user.id === coach_id),
            entries: [],
            weekdayMap: new Map(),
            serviceMap: new Map(),
            totalHours: 0
          };

          coachMap.set(coach_id, coachMapEntry);
        }
        else {
          coachMapEntry = coachMap.get(coach_id);
        }

        const classDuration = this.getClassDuration();

        let parsedHour = parseInt(training_time.time.slice(0, 2));
        let parsedMinutes = parseInt(training_time.time.slice(3, 5)) + classDuration;

        const totalHours = classDuration / 60;

        coachMapEntry.totalHours += totalHours;
        totalWeekHours += totalHours;

        parsedHour += Math.floor(parsedMinutes / 60);
        parsedMinutes = parsedMinutes % 60;

        let totalWeekdayMapEntry = 0;

        if (totalWeekdayMap.has(training_time.day_id)) {
          totalWeekdayMapEntry = totalWeekdayMap.get(training_time.day_id);
        }

        totalWeekdayMapEntry += totalHours;
        totalWeekdayMap.set(training_time.day_id, totalWeekdayMapEntry);

        let weekdayMapEntry = 0;

        if (coachMapEntry.weekdayMap.has(training_time.day_id)) {
          weekdayMapEntry = coachMapEntry.weekdayMap.get(training_time.day_id);
        }

        weekdayMapEntry += totalHours;
        coachMapEntry.weekdayMap.set(training_time.day_id, weekdayMapEntry);

        let totalServiceMapEntry = 0;

        if (totalServiceMap.has(training_time.target_service)) {
          totalServiceMapEntry = totalServiceMap.get(training_time.target_service);
        }

        totalServiceMapEntry += totalHours;
        totalServiceMap.set(training_time.target_service, totalServiceMapEntry);

        let serviceMapEntry = 0;

        if (coachMapEntry.serviceMap.has(training_time.target_service)) {
          serviceMapEntry = coachMapEntry.serviceMap.get(training_time.target_service);
        }

        serviceMapEntry += totalHours;
        coachMapEntry.serviceMap.set(training_time.target_service, serviceMapEntry);

        parsedHour = parsedHour < 10 ? `0${parsedHour}` : parsedHour.toString();
        parsedMinutes = parsedMinutes < 10 ? `0${parsedMinutes}` : parsedMinutes.toString();

        coachMapEntry.entries.push({
          start_at: training_time.time,
          end_at: `${parsedHour}:${parsedMinutes}`,
          target_service: training_time.target_service,
          day_id: training_time.day_id
        });
      }
    }

    for (const work_schedule of this.state.work_schedule_list) {
      let coachMapEntry;

      const coach = this.state.users.find((user) => user.id === work_schedule.user_id);

      if (!coach || work_schedule.start_at >= work_schedule.end_at) {
        continue;
      }

      if (!coachMap.has(coach.id)) {
        coachMapEntry = {
          coach: coach,
          entries: [],
          weekdayMap: new Map(),
          serviceMap: new Map(),
          totalHours: 0
        };

        coachMap.set(coach.id, coachMapEntry);
      }
      else {
        coachMapEntry = coachMap.get(coach.id);
      }

      let startAtHour = parseInt(work_schedule.start_at.slice(0, 2));
      let startAtMinutes = parseInt(work_schedule.start_at.slice(3, 5));

      let endAtHour = parseInt(work_schedule.end_at.slice(0, 2));
      let endAtMinutes = parseInt(work_schedule.end_at.slice(3, 5));

      const totalHours = (endAtHour - startAtHour) + ((endAtMinutes - startAtMinutes) / 60);

      coachMapEntry.totalHours += totalHours;
      totalWeekHours += totalHours;

      let totalWeekdayMapEntry = 0;

      if (totalWeekdayMap.has(work_schedule.day_id)) {
        totalWeekdayMapEntry = totalWeekdayMap.get(work_schedule.day_id);
      }

      totalWeekdayMapEntry += totalHours;
      totalWeekdayMap.set(work_schedule.day_id, totalWeekdayMapEntry);

      let weekdayMapEntry = 0;

      if (coachMapEntry.weekdayMap.has(work_schedule.day_id)) {
        weekdayMapEntry = coachMapEntry.weekdayMap.get(work_schedule.day_id);
      }

      weekdayMapEntry += totalHours;
      coachMapEntry.weekdayMap.set(work_schedule.day_id, weekdayMapEntry);

      let totalServiceMapEntry = 0;

      if (totalServiceMap.has('Extra')) {
        totalServiceMapEntry = totalServiceMap.get('Extra');
      }

      totalServiceMapEntry += totalHours;
      totalServiceMap.set('Extra', totalServiceMapEntry);

      let serviceMapEntry = 0;

      if (coachMapEntry.serviceMap.has('Extra')) {
        serviceMapEntry = coachMapEntry.serviceMap.get('Extra');
      }

      serviceMapEntry += totalHours;
      coachMapEntry.serviceMap.set('Extra', serviceMapEntry);

      coachMapEntry.entries.push({
        start_at: work_schedule.start_at.slice(0, 5),
        end_at: work_schedule.end_at.slice(0, 5),
        target_service: 'Extra',
        day_id: work_schedule.day_id
      });
    }

    const weekdayTableData = [];
    const serviceTableData = [];
    const services = [...totalServiceMap.keys()].sort((a, b) => {
      if (a === "Extra") {
        return 1;
      }

      return a.localeCompare(b);
    });

    for (const coachMapEntry of coachMap.values()) {
      const text = getAbbreviatedName(coachMapEntry.coach.name);
      const sort_tag = text;

      coachMapEntry.entries.sort((a, b) => {
        if (a.target_service === b.target_service) {
          if(a.day_id === b.day_id) {
            return a.start_at.localeCompare(b.start_at);
          }

          return a.day_id - b.day_id;
        }

        return a.target_service.localeCompare(b.target_service);
      });

      let lastEntry = null;

      for (const entry of coachMapEntry.entries) {
        if (lastEntry === null) {
          lastEntry = entry;
          continue;
        }

        if (entry.start_at === lastEntry.end_at && entry.target_service === lastEntry.target_service && entry.day_id === lastEntry.day_id) {
          lastEntry = {
            ...lastEntry,
            end_at: entry.end_at
          };

          continue;
        }

        scheduleGridData.push({
          start_at: lastEntry.start_at,
          end_at: lastEntry.end_at,
          day_id: lastEntry.day_id,
          sort_tag,
          text,
          popupContent: (
            <div className="work-schedule-list__schedule-grid-entry__popup-content">
              <p className="work-schedule-list__schedule-grid-entry__popup-content__text">{lastEntry.target_service}</p>
              <p className="work-schedule-list__schedule-grid-entry__popup-content__text">{text}</p>
              <p className="work-schedule-list__schedule-grid-entry__popup-content__text">{`${lastEntry.start_at} - ${lastEntry.end_at}`}</p>
            </div>
          ),
          className: `work-schedule-list__schedule-grid-entry${lastEntry.target_service === this.state.activeService ? '--selected' : lastEntry.target_service === 'Extra' ? '--gray' : ''}`
        });

        lastEntry = entry;
      }

      scheduleGridData.push({
        start_at: lastEntry.start_at,
        end_at: lastEntry.end_at,
        day_id: lastEntry.day_id,
        sort_tag,
        text,
        popupContent: (
            <div className="work-schedule-list__schedule-grid-entry__popup-content">
              <p className="work-schedule-list__schedule-grid-entry__popup-content__text">{lastEntry.target_service}</p>
              <p className="work-schedule-list__schedule-grid-entry__popup-content__text">{text}</p>
              <p className="work-schedule-list__schedule-grid-entry__popup-content__text">{`${lastEntry.start_at} - ${lastEntry.end_at}`}</p>
            </div>
          ),
        className: `work-schedule-list__schedule-grid-entry${lastEntry.target_service === this.state.activeService ? '--selected' : lastEntry.target_service === 'Extra' ? '--gray' : ''}`
      });

      weekdayTableData.push({
        id: coachMapEntry.coach.id,
        staffName: coachMapEntry.coach.name,
        weekday_0: coachMapEntry.weekdayMap.get(0),
        weekday_1: coachMapEntry.weekdayMap.get(1),
        weekday_2: coachMapEntry.weekdayMap.get(2),
        weekday_3: coachMapEntry.weekdayMap.get(3),
        weekday_4: coachMapEntry.weekdayMap.get(4),
        weekday_5: coachMapEntry.weekdayMap.get(5),
        weekday_6: coachMapEntry.weekdayMap.get(6),
        totalHours: coachMapEntry.totalHours,
      });

      const serviceTableEntry = {
        id: coachMapEntry.coach.id,
        staffName: coachMapEntry.coach.name,
        totalHours: coachMapEntry.totalHours,
      };

      services.forEach((service) => {
        serviceTableEntry[service] = coachMapEntry.serviceMap.get(service)
      });

      serviceTableData.push(serviceTableEntry);
    }

    weekdayTableData.push({
      id: 0,
      staffName: 'Total',
      weekday_0: totalWeekdayMap.get(0),
      weekday_1: totalWeekdayMap.get(1),
      weekday_2: totalWeekdayMap.get(2),
      weekday_3: totalWeekdayMap.get(3),
      weekday_4: totalWeekdayMap.get(4),
      weekday_5: totalWeekdayMap.get(5),
      weekday_6: totalWeekdayMap.get(6),
      totalHours: totalWeekHours,
    });

    const totalServiceTableEntry = {
      id: 0,
      staffName: 'Total',
      totalHours: totalWeekHours
    };
    services.forEach((service) => {
      totalServiceTableEntry[service] = totalServiceMap.get(service)
    });
    serviceTableData.push(totalServiceTableEntry);

    return (
      <React.Fragment>

        <HorizontalRule />

        <DefaultSubSectionTitle
          icon={<i className="fa-solid fa-clock"></i>}
          text="Grade de horário geral (padrão)"
        />

        <ScheduleGrid
          data={scheduleGridData}
        />

        <DefaultSubSectionTitle
          className="work-schedule-list__overview-table__section-title"
          icon={<i className="fa-solid fa-calendar-week"></i>}
          text="Por dias da semana (padrão)"
        />

        <ModelTable
          id='training_time:weekday_table'
          properties={this.getWeekdayTableProperties()}
          getRowClassName={(row) => row.id === 0 ? 'work-schedule-list__overview-table__total-row' : ''}
          data={weekdayTableData}
          initialLinesPerPage={weekdayTableData.length}
          initialOrderBy="totalHours"
          initialOrderIsDecrescent={true}
          hideFilter={true}
          hideLinesPerPageControl={true}
          hideNavigationControls={true}
        >
        </ModelTable>

        <DefaultSubSectionTitle
          className="work-schedule-list__overview-table__section-title"
          icon={<i className="fa-solid fa-bell-concierge"></i>}
          text="Por serviço (padrão)"
        />

        <ModelTable
          id='training_time:service_table'
          properties={this.getServiceTableProperties(services)}
          getRowClassName={(row) => row.id === 0 ? 'work-schedule-list__overview-table__total-row' : ''}
          data={serviceTableData}
          initialLinesPerPage={weekdayTableData.length}
          initialOrderBy="totalHours"
          initialOrderIsDecrescent={true}
          hideFilter={true}
          hideLinesPerPageControl={true}
          hideNavigationControls={true}
        >
        </ModelTable>

      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao período serão removidos'}
          confirmText="Deletar período"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.WORK_SCHEDULE_LIST_PAGE,
              text: "Agenda de trabalho"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Agenda de trabalho"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="work-schedule-list"
            title="Horários de trabalho"
          >

            <div className="work-schedule-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_WORK_SCHEDULE_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.WORK_SCHEDULE_ADD_PAGE}
                >

                  <i className="fas fa-plus"></i> Adicionar período

                </Link>
              }

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.work_schedule_list}
              initialOrderBy="name"
            >

            </ModelTable>

            {this.getScheduleOverview()}

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default WorkScheduleList;
