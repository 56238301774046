const VIEW_FINANCIAL_ENTRIES_PERMISSION_ID = 4;
const ADD_FINANCIAL_ENTRIES_PERMISSION_ID = 5;
const EDIT_FINANCIAL_ENTRIES_PERMISSION_ID = 6;

const VIEW_COST_CENTER_PERMISSION_ID = 8;
const ADD_COST_CENTER_PERMISSION_ID = 9;
const EDIT_COST_CENTER_PERMISSION_ID = 10;
const DELETE_COST_CENTER_PERMISSION_ID = 11;

const VIEW_FINANCIAL_CATEGORY_PERMISSION_ID = 12;
const ADD_FINANCIAL_CATEGORY_PERMISSION_ID = 13;
const EDIT_FINANCIAL_CATEGORY_PERMISSION_ID = 14;
const DELETE_FINANCIAL_CATEGORY_PERMISSION_ID = 15;

const VIEW_SERVICE_PLAN_PERMISSION_ID = 16;
const ADD_SERVICE_PLAN_PERMISSION_ID = 17;
const EDIT_SERVICE_PLAN_PERMISSION_ID = 18;
const DELETE_SERVICE_PLAN_PERMISSION_ID = 19;

const VIEW_BASIC_FEEDBACK_PERMISSION_ID = 20;
const VIEW_FEEDBACK_DATA_PERMISSION_ID = 21;
const DELETE_FEEDBACK_PERMISSION_ID = 22;

const VIEW_PHYSICAL_EXAM_DAY_PERMISSION_ID = 23;
const ADD_PHYSICAL_EXAM_DAY_PERMISSION_ID = 24;
const EDIT_PHYSICAL_EXAM_DAY_PERMISSION_ID = 25;
const DELETE_PHYSICAL_EXAM_DAY_PERMISSION_ID = 26;

const VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID = 27;
const ADD_PHYSICAL_EVALUATION_DATA_PERMISSION_ID = 28;
const EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID = 29;
const DELETE_PHYSICAL_EVALUATION_DATA_PERMISSION_ID = 30;

const MANAGE_EXPERIMENTAL_CLASS_SCHEDULE_PERMISSION_ID = 31;
const VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID = 32;
const EDIT_EXPERIMENTAL_CLASS_PERMISSION_ID = 33;
const DELETE_EXPERIMENTAL_CLASS_PERMISSION_ID = 34;

const VIEW_STUDENT_BASIC_DATA_PERMISSION_ID = 35;
const ADD_STUDENT_PERMISSION_ID = 36;
const DELETE_STUDENT_PERMISSION_ID = 37;
const EDIT_STUDENT_DATA_PERMISSION_ID = 38;
const VIEW_STUDENT_DATA_PERMISSION_ID = 39;

const UPDATE_PHYSICAL_EVALUATION_STATUS_PERMISSION_ID = 40;

const VIEW_CONTRACT_PERMISSION_ID = 41;
const ADD_CONTRACT_BASIC_PERMISSION_ID = 42;
const ADD_CONTRACT_COMPLETE_PERMISSION_ID = 43;
const EDIT_CONTRACT_PERMISSION_ID = 44;
const DELETE_CONTRACT_PERMISSION_ID = 45;

const VIEW_USER_NOTIFICATION_PERMISSION_ID = 46;
const SEND_STUDENT_REGISTRATION_NOTIFICATION_PERMISSION_ID = 47;
const SEND_PHYSICAL_EXAM_SCHEDULE_NOTIFICATION_PERMISSION_ID = 48;
const SEND_ALL_APPOINTMENT_SCHEDULE_NOTIFICATION_PERMISSION_ID = 49;
const SEND_PHYSICAL_EVALUATION_REPORT_NOTIFICATION_PERMISSION_ID = 50;

const VIEW_MANAGER_USER_PERMISSION_ID = 51;
const ADD_MANAGER_USER_PERMISSION_ID = 52;
const EDIT_MANAGER_USER_PERMISSION_ID = 53;
const DELETE_MANAGER_USER_PERMISSION_ID = 54;

const VIEW_COACH_PERMISSION_ID = 55;
const ADD_COACH_PERMISSION_ID = 56;
const EDIT_COACH_PERMISSION_ID = 57;
const DELETE_COACH_PERMISSION_ID = 58;

const VIEW_EXERCISE_CATEGORY_PERMISSION_ID = 59;
const ADD_EXERCISE_CATEGORY_PERMISSION_ID = 60;
const EDIT_EXERCISE_CATEGORY_PERMISSION_ID = 61;
const DELETE_EXERCISE_CATEGORY_PERMISSION_ID = 62;

const VIEW_EXERCISE_FUNCTION_PERMISSION_ID = 63;
const ADD_EXERCISE_FUNCTION_PERMISSION_ID = 64;
const EDIT_EXERCISE_FUNCTION_PERMISSION_ID = 65;
const DELETE_EXERCISE_FUNCTION_PERMISSION_ID = 66;

const VIEW_TRAINING_EXERCISE_PERMISSION_ID = 67;
const ADD_TRAINING_EXERCISE_PERMISSION_ID = 68;
const EDIT_TRAINING_EXERCISE_PERMISSION_ID = 69;
const DELETE_TRAINING_EXERCISE_PERMISSION_ID = 70;

const VIEW_TRAINING_PERIOD_PERMISSION_ID = 71;
const ADD_TRAINING_PERIOD_PERMISSION_ID = 72;
const EDIT_TRAINING_PERIOD_PERMISSION_ID = 73;
const DELETE_TRAINING_PERIOD_PERMISSION_ID = 74;

const VIEW_TRAINING_DAY_PERMISSION_ID = 75;
const ADD_TRAINING_DAY_PERMISSION_ID = 76;
const EDIT_TRAINING_DAY_PERMISSION_ID = 77;
const DELETE_TRAINING_DAY_PERMISSION_ID = 78;

const VIEW_EXERCISE_GROUP_PERMISSION_ID = 79;

const VIEW_DAY_GROUP_ASSOCIATION_PERMISSION_ID = 80;
const ADD_DAY_GROUP_ASSOCIATION_PERMISSION_ID = 81;
const EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID = 82;
const DELETE_DAY_GROUP_ASSOCIATION_PERMISSION_ID = 83;

const VIEW_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID = 84;
const ADD_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID = 85;
const EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID = 86;
const DELETE_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID = 87;

const VIEW_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID = 88;
const ADD_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID = 89;
const EDIT_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID = 90;
const DELETE_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID = 91;

const VIEW_TRAINING_CALENDAR_PERMISSION_ID = 92;
const EDIT_TRAINING_CALENDAR_PERMISSION_ID = 93;

const VIEW_TRAINING_PERIOD_SERVICES_PERMISSION_ID = 94;

const ADD_STUDENT_FINANCIAL_ENTRIES_PERMISSION_ID = 95;

const VIEW_USER_DEVICE_PERMISSION_ID = 97;
const EDIT_USER_DEVICE_PERMISSION_ID = 98;
const DELETE_USER_DEVICE_PERMISSION_ID = 99;

const MANAGE_TRAINING_TIME_PERMISSION_ID = 101;

const VIEW_TRAINING_DATA_PERMISSION_ID = 102;

const VIEW_TRAINING_TIME_PERMISSION_ID = 103;

const TRIGGER_USER_EMAIL_CHANGE_PERMISSION_ID = 105;

const VIEW_HOME_TRAINING_PERMISSION_ID = 108;
const ADD_HOME_TRAINING_PERMISSION_ID = 109;
const DELETE_HOME_TRAINING_PERMISSION_ID = 110;

const VIEW_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID = 111;
const ADD_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID = 112;
const EDIT_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID = 113;
const DELETE_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID = 114;

const VIEW_EXERCISE_ACTIVITY_PERMISSION_ID = 115;
const ADD_EXERCISE_ACTIVITY_PERMISSION_ID = 116;
const EDIT_EXERCISE_ACTIVITY_PERMISSION_ID = 117;
const DELETE_EXERCISE_ACTIVITY_PERMISSION_ID = 118;

const CHANGE_USER_EMAIL_PERMISSION_ID = 119;

const VIEW_MUSCLE_GROUP_PERMISSION_ID = 120;
const ADD_MUSCLE_GROUP_PERMISSION_ID = 121;
const EDIT_MUSCLE_GROUP_PERMISSION_ID = 122;
const DELETE_MUSCLE_GROUP_PERMISSION_ID = 123;

const VIEW_WEARABLE_DEVICE_PERMISSION_ID = 125;
const EDIT_WEARABLE_DEVICE_PERMISSION_ID = 126;
const DELETE_WEARABLE_DEVICE_PERMISSION_ID = 127;
const ADD_WEARABLE_DEVICE_PERMISSION_ID = 128;

const VIEW_RECEPTIONIST_PERMISSION_ID = 130;
const ADD_RECEPTIONIST_PERMISSION_ID = 131;
const EDIT_RECEPTIONIST_PERMISSION_ID = 132;
const DELETE_RECEPTIONIST_PERMISSION_ID = 133;

const VIEW_TRAINING_CLASS_DATA_PERMISSION_ID = 135;

const VIEW_PERSONAL_TRAINING_DATA_PERMISSION = 136;
const ADD_PERSONAL_TRAINING_DATA_PERMISSION = 137;
const EDIT_PERSONAL_TRAINING_DATA_PERMISSION = 138;
const DELETE_PERSONAL_TRAINING_DATA_PERMISSION = 139;
const VIEW_PERSONAL_TRAINING_PERIOD_PERMISSION = 140;
const MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION = 141;

const VIEW_PERSONAL_TRAINING_HISTORY_PERMISSION = 142;
const DELETE_PERSONAL_TRAINING_HISTORY_PERMISSION = 143;

const VIEW_USER_NOTIFICATION_STATUS_UPDATE_DATA_PERMISSION = 144;

const VIEW_FOOD_INGREDIENT_PERMISSION = 145;
const EDIT_FOOD_INGREDIENT_PERMISSION = 146;

const VIEW_FOOD_CLASSIFICATION_PERMISSION = 147;

const VIEW_FOOD_INFO_SOURCE_PERMISSION = 148;

const VIEW_MEAL_CLASSIFICATION_PERMISSION = 151;
const ADD_MEAL_CLASSIFICATION_PERMISSION = 152;
const EDIT_MEAL_CLASSIFICATION_PERMISSION = 153;
const DELETE_MEAL_CLASSIFICATION_PERMISSION = 154;

const VIEW_FOOD_RECIPE_PERMISSION = 155;
const ADD_FOOD_RECIPE_PERMISSION = 156;
const EDIT_FOOD_RECIPE_PERMISSION = 157;
const DELETE_FOOD_RECIPE_PERMISSION = 158;

const VIEW_MEAL_PERIOD_PERMISSION = 159;
const ADD_MEAL_PERIOD_PERMISSION = 160;
const EDIT_MEAL_PERIOD_PERMISSION = 161;
const DELETE_MEAL_PERIOD_PERMISSION = 162;

const VIEW_FOOD_PRESCRIPTION_PERMISSION = 163;
const ADD_FOOD_PRESCRIPTION_PERMISSION = 164;
const EDIT_FOOD_PRESCRIPTION_PERMISSION = 165;
const DELETE_FOOD_PRESCRIPTION_PERMISSION = 166;

const SHARE_TRAINING_EXERCISE_PERMISSION = 167;
const SHARE_TRAINING_PERIOD_PERMISSION = 168;

const ADD_FOOD_INGREDIENT_PERMISSION = 169;
const DELETE_FOOD_INGREDIENT_PERMISSION = 170;

const ADD_FOOD_CLASSIFICATION_PERMISSION = 171;
const EDIT_FOOD_CLASSIFICATION_PERMISSION = 172;
const DELETE_FOOD_CLASSIFICATION_PERMISSION = 173;

const MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION = 174;
const VIEW_STUDENT_FOOD_PRESCRIPTION_HISTORY_PERMISSION = 175;
const DELETE_STUDENT_FOOD_PRESCRIPTION_HISTORY_PERMISSION = 176;

const VIEW_NUTRITIONIST_PERMISSION_ID = 178;
const ADD_NUTRITIONIST_PERMISSION_ID = 179;
const EDIT_NUTRITIONIST_PERMISSION_ID = 180;
const DELETE_NUTRITIONIST_PERMISSION_ID = 181;
const MANAGE_ADMIN_NUTRITIONIST_PERMISSION_ID = 182;

const VIEW_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID = 183;
const ADD_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID = 184;
const EDIT_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID = 185;
const DELETE_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID = 186;

const VIEW_BASIC_WEBSITE_ANALYTICS_DATA_PERMISSION_ID = 187;

const VIEW_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID = 188;
const ADD_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID = 189;
const EDIT_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID = 190;
const DELETE_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID = 191;

const VIEW_CUSTOMER_FEEDBACK_PERMISSION_ID = 192;
const EDIT_CUSTOMER_FEEDBACK_PERMISSION_ID = 193;
const DELETE_CUSTOMER_FEEDBACK_PERMISSION_ID = 194;

const VIEW_APPOINTMENT_ROOM_PERMISSION_ID = 195;
const ADD_APPOINTMENT_ROOM_PERMISSION_ID = 196;
const EDIT_APPOINTMENT_ROOM_PERMISSION_ID = 197;
const DELETE_APPOINTMENT_ROOM_PERMISSION_ID = 198;

const VIEW_OWN_APPOINTMENT_SCHEDULE_PERMISSION_ID = 199;
const VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID = 200;
const ADD_APPOINTMENT_SCHEDULE_PERMISSION_ID = 201;
const EDIT_APPOINTMENT_SCHEDULE_PERMISSION_ID = 202;
const DELETE_APPOINTMENT_SCHEDULE_PERMISSION_ID = 203;

const MANAGE_OWN_APPOINTMENT_PERMISSION_ID = 204;
const MANAGE_APPOINTMENT_PERMISSION_ID = 205;

const VIEW_SERVICE_LEAD_PERMISSION_ID = 207;
const EDIT_SERVICE_LEAD_PERMISSION_ID = 208;
const DELETE_SERVICE_LEAD_PERMISSION_ID = 209;

const DOWNLOAD_FOOD_PRESCRIPTION_PDF_PERMISSION_ID = 210;

const MANAGE_USER_STATUS_PERMISSION_ID = 211;

const MANAGE_CUSTOMER_SUPPORT_PERMISSION_ID = 213;

const VIEW_SATISFACTION_REPORT_PERMISSION_ID = 214;

const UPDATE_USER_IMAGE_PERMISSION_ID = 215;

const VIEW_PROMOTIONAL_COUPON_PERMISSION_ID = 216;
const ADD_PROMOTIONAL_COUPON_PERMISSION_ID = 217;
const EDIT_PROMOTIONAL_COUPON_PERMISSION_ID = 218;
const DELETE_PROMOTIONAL_COUPON_PERMISSION_ID = 219;

const EDIT_STUDENT_BASIC_DATA_PERMISSION_ID = 220;

const LIST_PHYSICAL_EVALUATION_PERMISSION_ID = 221;

const MANAGE_TRAINING_MUSIC_PERMISSION_ID = 222;

const VIEW_CLASS_CHECKIN_STUDENTS_PERMISSION = 223;

const VIEW_PAYMENT_DEVICE_PERMISSION_ID = 224;
const EDIT_PAYMENT_DEVICE_PERMISSION_ID = 225;
const DELETE_PAYMENT_DEVICE_PERMISSION_ID = 226;
const ADD_PAYMENT_DEVICE_PERMISSION_ID = 227;

const VIEW_OPERATIONAL_TASK_BASIC_PERMISSION_ID = 228;
const VIEW_OPERATIONAL_TASK_COMPLETE_PERMISSION_ID = 229;
const UPDATE_OPERATIONAL_TASK_PERMISSION_ID = 230;
const EDIT_OPERATIONAL_TASK_PERMISSION_ID = 231;
const DELETE_OPERATIONAL_TASK_PERMISSION_ID = 232;
const ADD_MANAGEMENT_OPERATIONAL_TASK_PERMISSION_ID = 233;
const ADD_OPERATIONAL_TASK_PERMISSION_ID = 234;

const VIEW_FINANCIAL_SEARCH_TAG_PERMISSION_ID = 235;
const EDIT_FINANCIAL_SEARCH_TAG_PERMISSION_ID = 236;
const DELETE_FINANCIAL_SEARCH_TAG_PERMISSION_ID = 237;
const ADD_FINANCIAL_SEARCH_TAG_PERMISSION_ID = 238;

const VIEW_ACCESS_CONTROL_DEVICE_PERMISSION_ID = 239;
const EDIT_ACCESS_CONTROL_DEVICE_PERMISSION_ID = 240;
const DELETE_ACCESS_CONTROL_DEVICE_PERMISSION_ID = 241;
const ADD_ACCESS_CONTROL_DEVICE_PERMISSION_ID = 242;

const MANAGE_ACCESS_CONTROL_DEVICE_CONFIGURATION_PERMISSION_ID = 243;

const MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID = 244;

const VIEW_STAFF_PERMISSION_ID = 245;

const GENERATE_NF_COUPON_PERMISSION_ID = 246;

const VIEW_CLIENT_LEAD_PERMISSION_ID = 247;
const EDIT_CLIENT_LEAD_PERMISSION_ID = 248;
const DELETE_CLIENT_LEAD_PERMISSION_ID = 249;

const VIEW_EXIT_SURVEY_DATA_PERMISSION_ID = 251;
const DELETE_EXIT_SURVEY_DATA_PERMISSION_ID = 252;

const VIEW_WORK_SCHEDULE_PERMISSION_ID = 253;
const EDIT_WORK_SCHEDULE_PERMISSION_ID = 254;
const DELETE_WORK_SCHEDULE_PERMISSION_ID = 255;
const ADD_WORK_SCHEDULE_PERMISSION_ID = 256;

const VIEW_WORK_EXCEPTION_EVENT_PERMISSION = 257;
const EDIT_WORK_EXCEPTION_EVENT_PERMISSION = 258;
const DELETE_WORK_EXCEPTION_EVENT_PERMISSION = 259;
const ADD_WORK_EXCEPTION_EVENT_PERMISSION = 260;

export {
  VIEW_FINANCIAL_ENTRIES_PERMISSION_ID,
  ADD_FINANCIAL_ENTRIES_PERMISSION_ID,
  EDIT_FINANCIAL_ENTRIES_PERMISSION_ID,
  VIEW_COST_CENTER_PERMISSION_ID,
  ADD_COST_CENTER_PERMISSION_ID,
  EDIT_COST_CENTER_PERMISSION_ID,
  DELETE_COST_CENTER_PERMISSION_ID,
  VIEW_FINANCIAL_CATEGORY_PERMISSION_ID,
  ADD_FINANCIAL_CATEGORY_PERMISSION_ID,
  EDIT_FINANCIAL_CATEGORY_PERMISSION_ID,
  DELETE_FINANCIAL_CATEGORY_PERMISSION_ID,
  VIEW_SERVICE_PLAN_PERMISSION_ID,
  ADD_SERVICE_PLAN_PERMISSION_ID,
  EDIT_SERVICE_PLAN_PERMISSION_ID,
  DELETE_SERVICE_PLAN_PERMISSION_ID,
  VIEW_BASIC_FEEDBACK_PERMISSION_ID,
  VIEW_FEEDBACK_DATA_PERMISSION_ID,
  DELETE_FEEDBACK_PERMISSION_ID,
  VIEW_PHYSICAL_EXAM_DAY_PERMISSION_ID,
  ADD_PHYSICAL_EXAM_DAY_PERMISSION_ID,
  EDIT_PHYSICAL_EXAM_DAY_PERMISSION_ID,
  DELETE_PHYSICAL_EXAM_DAY_PERMISSION_ID,
  VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID,
  ADD_PHYSICAL_EVALUATION_DATA_PERMISSION_ID,
  EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID,
  DELETE_PHYSICAL_EVALUATION_DATA_PERMISSION_ID,
  MANAGE_EXPERIMENTAL_CLASS_SCHEDULE_PERMISSION_ID,
  VIEW_EXPERIMENTAL_CLASS_PERMISSION_ID,
  EDIT_EXPERIMENTAL_CLASS_PERMISSION_ID,
  DELETE_EXPERIMENTAL_CLASS_PERMISSION_ID,
  VIEW_STUDENT_BASIC_DATA_PERMISSION_ID,
  ADD_STUDENT_PERMISSION_ID,
  DELETE_STUDENT_PERMISSION_ID,
  EDIT_STUDENT_DATA_PERMISSION_ID,
  VIEW_STUDENT_DATA_PERMISSION_ID,
  UPDATE_PHYSICAL_EVALUATION_STATUS_PERMISSION_ID,
  VIEW_CONTRACT_PERMISSION_ID,
  ADD_CONTRACT_BASIC_PERMISSION_ID,
  ADD_CONTRACT_COMPLETE_PERMISSION_ID,
  EDIT_CONTRACT_PERMISSION_ID,
  DELETE_CONTRACT_PERMISSION_ID,
  VIEW_USER_NOTIFICATION_PERMISSION_ID,
  SEND_STUDENT_REGISTRATION_NOTIFICATION_PERMISSION_ID,
  SEND_PHYSICAL_EXAM_SCHEDULE_NOTIFICATION_PERMISSION_ID,
  SEND_ALL_APPOINTMENT_SCHEDULE_NOTIFICATION_PERMISSION_ID,
  SEND_PHYSICAL_EVALUATION_REPORT_NOTIFICATION_PERMISSION_ID,
  VIEW_MANAGER_USER_PERMISSION_ID,
  ADD_MANAGER_USER_PERMISSION_ID,
  EDIT_MANAGER_USER_PERMISSION_ID,
  DELETE_MANAGER_USER_PERMISSION_ID,
  VIEW_COACH_PERMISSION_ID,
  ADD_COACH_PERMISSION_ID,
  EDIT_COACH_PERMISSION_ID,
  DELETE_COACH_PERMISSION_ID,
  VIEW_EXERCISE_CATEGORY_PERMISSION_ID,
  ADD_EXERCISE_CATEGORY_PERMISSION_ID,
  EDIT_EXERCISE_CATEGORY_PERMISSION_ID,
  DELETE_EXERCISE_CATEGORY_PERMISSION_ID,
  VIEW_EXERCISE_FUNCTION_PERMISSION_ID,
  ADD_EXERCISE_FUNCTION_PERMISSION_ID,
  EDIT_EXERCISE_FUNCTION_PERMISSION_ID,
  DELETE_EXERCISE_FUNCTION_PERMISSION_ID,
  VIEW_TRAINING_EXERCISE_PERMISSION_ID,
  ADD_TRAINING_EXERCISE_PERMISSION_ID,
  EDIT_TRAINING_EXERCISE_PERMISSION_ID,
  DELETE_TRAINING_EXERCISE_PERMISSION_ID,
  VIEW_TRAINING_PERIOD_PERMISSION_ID,
  ADD_TRAINING_PERIOD_PERMISSION_ID,
  EDIT_TRAINING_PERIOD_PERMISSION_ID,
  DELETE_TRAINING_PERIOD_PERMISSION_ID,
  VIEW_TRAINING_DAY_PERMISSION_ID,
  ADD_TRAINING_DAY_PERMISSION_ID,
  EDIT_TRAINING_DAY_PERMISSION_ID,
  DELETE_TRAINING_DAY_PERMISSION_ID,
  VIEW_EXERCISE_GROUP_PERMISSION_ID,
  VIEW_DAY_GROUP_ASSOCIATION_PERMISSION_ID,
  ADD_DAY_GROUP_ASSOCIATION_PERMISSION_ID,
  EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID,
  DELETE_DAY_GROUP_ASSOCIATION_PERMISSION_ID,
  VIEW_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID,
  ADD_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID,
  EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID,
  DELETE_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID,
  VIEW_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID,
  ADD_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID,
  EDIT_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID,
  DELETE_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID,
  VIEW_TRAINING_CALENDAR_PERMISSION_ID,
  EDIT_TRAINING_CALENDAR_PERMISSION_ID,
  VIEW_TRAINING_PERIOD_SERVICES_PERMISSION_ID,
  ADD_STUDENT_FINANCIAL_ENTRIES_PERMISSION_ID,
  VIEW_USER_DEVICE_PERMISSION_ID,
  EDIT_USER_DEVICE_PERMISSION_ID,
  DELETE_USER_DEVICE_PERMISSION_ID,
  MANAGE_TRAINING_TIME_PERMISSION_ID,
  VIEW_TRAINING_DATA_PERMISSION_ID,
  VIEW_TRAINING_TIME_PERMISSION_ID,
  TRIGGER_USER_EMAIL_CHANGE_PERMISSION_ID,
  VIEW_HOME_TRAINING_PERMISSION_ID,
  ADD_HOME_TRAINING_PERMISSION_ID,
  DELETE_HOME_TRAINING_PERMISSION_ID,
  VIEW_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID,
  ADD_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID,
  EDIT_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID,
  DELETE_EXERCISE_ACTIVITY_TYPE_PERMISSION_ID,
  VIEW_EXERCISE_ACTIVITY_PERMISSION_ID,
  ADD_EXERCISE_ACTIVITY_PERMISSION_ID,
  EDIT_EXERCISE_ACTIVITY_PERMISSION_ID,
  DELETE_EXERCISE_ACTIVITY_PERMISSION_ID,
  CHANGE_USER_EMAIL_PERMISSION_ID,
  VIEW_MUSCLE_GROUP_PERMISSION_ID,
  ADD_MUSCLE_GROUP_PERMISSION_ID,
  EDIT_MUSCLE_GROUP_PERMISSION_ID,
  DELETE_MUSCLE_GROUP_PERMISSION_ID,
  VIEW_WEARABLE_DEVICE_PERMISSION_ID,
  EDIT_WEARABLE_DEVICE_PERMISSION_ID,
  DELETE_WEARABLE_DEVICE_PERMISSION_ID,
  ADD_WEARABLE_DEVICE_PERMISSION_ID,
  VIEW_RECEPTIONIST_PERMISSION_ID,
  ADD_RECEPTIONIST_PERMISSION_ID,
  EDIT_RECEPTIONIST_PERMISSION_ID,
  DELETE_RECEPTIONIST_PERMISSION_ID,
  VIEW_TRAINING_CLASS_DATA_PERMISSION_ID,
  VIEW_PERSONAL_TRAINING_DATA_PERMISSION,
  ADD_PERSONAL_TRAINING_DATA_PERMISSION,
  EDIT_PERSONAL_TRAINING_DATA_PERMISSION,
  DELETE_PERSONAL_TRAINING_DATA_PERMISSION,
  VIEW_PERSONAL_TRAINING_PERIOD_PERMISSION,
  MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION,
  VIEW_PERSONAL_TRAINING_HISTORY_PERMISSION,
  DELETE_PERSONAL_TRAINING_HISTORY_PERMISSION,
  VIEW_USER_NOTIFICATION_STATUS_UPDATE_DATA_PERMISSION,
  VIEW_FOOD_INGREDIENT_PERMISSION,
  EDIT_FOOD_INGREDIENT_PERMISSION,
  VIEW_FOOD_CLASSIFICATION_PERMISSION,
  VIEW_FOOD_INFO_SOURCE_PERMISSION,
  VIEW_MEAL_CLASSIFICATION_PERMISSION,
  ADD_MEAL_CLASSIFICATION_PERMISSION,
  EDIT_MEAL_CLASSIFICATION_PERMISSION,
  DELETE_MEAL_CLASSIFICATION_PERMISSION,
  VIEW_FOOD_RECIPE_PERMISSION,
  ADD_FOOD_RECIPE_PERMISSION,
  EDIT_FOOD_RECIPE_PERMISSION,
  DELETE_FOOD_RECIPE_PERMISSION,
  VIEW_MEAL_PERIOD_PERMISSION,
  ADD_MEAL_PERIOD_PERMISSION,
  EDIT_MEAL_PERIOD_PERMISSION,
  DELETE_MEAL_PERIOD_PERMISSION,
  VIEW_FOOD_PRESCRIPTION_PERMISSION,
  ADD_FOOD_PRESCRIPTION_PERMISSION,
  EDIT_FOOD_PRESCRIPTION_PERMISSION,
  DELETE_FOOD_PRESCRIPTION_PERMISSION,
  SHARE_TRAINING_EXERCISE_PERMISSION,
  SHARE_TRAINING_PERIOD_PERMISSION,
  ADD_FOOD_INGREDIENT_PERMISSION,
  DELETE_FOOD_INGREDIENT_PERMISSION,
  ADD_FOOD_CLASSIFICATION_PERMISSION,
  EDIT_FOOD_CLASSIFICATION_PERMISSION,
  DELETE_FOOD_CLASSIFICATION_PERMISSION,
  MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION,
  VIEW_STUDENT_FOOD_PRESCRIPTION_HISTORY_PERMISSION,
  DELETE_STUDENT_FOOD_PRESCRIPTION_HISTORY_PERMISSION,
  VIEW_NUTRITIONIST_PERMISSION_ID,
  ADD_NUTRITIONIST_PERMISSION_ID,
  EDIT_NUTRITIONIST_PERMISSION_ID,
  DELETE_NUTRITIONIST_PERMISSION_ID,
  MANAGE_ADMIN_NUTRITIONIST_PERMISSION_ID,
  VIEW_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID,
  ADD_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID,
  EDIT_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID,
  DELETE_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID,
  VIEW_BASIC_WEBSITE_ANALYTICS_DATA_PERMISSION_ID,
  VIEW_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID,
  ADD_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID,
  EDIT_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID,
  DELETE_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID,
  VIEW_CUSTOMER_FEEDBACK_PERMISSION_ID,
  EDIT_CUSTOMER_FEEDBACK_PERMISSION_ID,
  DELETE_CUSTOMER_FEEDBACK_PERMISSION_ID,
  VIEW_APPOINTMENT_ROOM_PERMISSION_ID,
  ADD_APPOINTMENT_ROOM_PERMISSION_ID,
  EDIT_APPOINTMENT_ROOM_PERMISSION_ID,
  DELETE_APPOINTMENT_ROOM_PERMISSION_ID,
  VIEW_OWN_APPOINTMENT_SCHEDULE_PERMISSION_ID,
  VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID,
  ADD_APPOINTMENT_SCHEDULE_PERMISSION_ID,
  EDIT_APPOINTMENT_SCHEDULE_PERMISSION_ID,
  DELETE_APPOINTMENT_SCHEDULE_PERMISSION_ID,
  MANAGE_OWN_APPOINTMENT_PERMISSION_ID,
  MANAGE_APPOINTMENT_PERMISSION_ID,
  VIEW_SERVICE_LEAD_PERMISSION_ID,
  EDIT_SERVICE_LEAD_PERMISSION_ID,
  DELETE_SERVICE_LEAD_PERMISSION_ID,
  DOWNLOAD_FOOD_PRESCRIPTION_PDF_PERMISSION_ID,
  MANAGE_USER_STATUS_PERMISSION_ID,
  MANAGE_CUSTOMER_SUPPORT_PERMISSION_ID,
  VIEW_SATISFACTION_REPORT_PERMISSION_ID,
  UPDATE_USER_IMAGE_PERMISSION_ID,
  VIEW_PROMOTIONAL_COUPON_PERMISSION_ID,
  ADD_PROMOTIONAL_COUPON_PERMISSION_ID,
  EDIT_PROMOTIONAL_COUPON_PERMISSION_ID,
  DELETE_PROMOTIONAL_COUPON_PERMISSION_ID,
  EDIT_STUDENT_BASIC_DATA_PERMISSION_ID,
  LIST_PHYSICAL_EVALUATION_PERMISSION_ID,
  MANAGE_TRAINING_MUSIC_PERMISSION_ID,
  VIEW_CLASS_CHECKIN_STUDENTS_PERMISSION,
  VIEW_PAYMENT_DEVICE_PERMISSION_ID,
  EDIT_PAYMENT_DEVICE_PERMISSION_ID,
  DELETE_PAYMENT_DEVICE_PERMISSION_ID,
  ADD_PAYMENT_DEVICE_PERMISSION_ID,
  VIEW_OPERATIONAL_TASK_BASIC_PERMISSION_ID,
  VIEW_OPERATIONAL_TASK_COMPLETE_PERMISSION_ID,
  UPDATE_OPERATIONAL_TASK_PERMISSION_ID,
  EDIT_OPERATIONAL_TASK_PERMISSION_ID,
  DELETE_OPERATIONAL_TASK_PERMISSION_ID,
  ADD_MANAGEMENT_OPERATIONAL_TASK_PERMISSION_ID,
  ADD_OPERATIONAL_TASK_PERMISSION_ID,
  VIEW_FINANCIAL_SEARCH_TAG_PERMISSION_ID,
  EDIT_FINANCIAL_SEARCH_TAG_PERMISSION_ID,
  DELETE_FINANCIAL_SEARCH_TAG_PERMISSION_ID,
  ADD_FINANCIAL_SEARCH_TAG_PERMISSION_ID,
  VIEW_ACCESS_CONTROL_DEVICE_PERMISSION_ID,
  EDIT_ACCESS_CONTROL_DEVICE_PERMISSION_ID,
  DELETE_ACCESS_CONTROL_DEVICE_PERMISSION_ID,
  ADD_ACCESS_CONTROL_DEVICE_PERMISSION_ID,
  MANAGE_ACCESS_CONTROL_DEVICE_CONFIGURATION_PERMISSION_ID,
  MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID,
  VIEW_STAFF_PERMISSION_ID,
  GENERATE_NF_COUPON_PERMISSION_ID,
  VIEW_CLIENT_LEAD_PERMISSION_ID,
  EDIT_CLIENT_LEAD_PERMISSION_ID,
  DELETE_CLIENT_LEAD_PERMISSION_ID,
  VIEW_EXIT_SURVEY_DATA_PERMISSION_ID,
  DELETE_EXIT_SURVEY_DATA_PERMISSION_ID,
  VIEW_WORK_SCHEDULE_PERMISSION_ID,
  EDIT_WORK_SCHEDULE_PERMISSION_ID,
  DELETE_WORK_SCHEDULE_PERMISSION_ID,
  ADD_WORK_SCHEDULE_PERMISSION_ID,
  VIEW_WORK_EXCEPTION_EVENT_PERMISSION,
  EDIT_WORK_EXCEPTION_EVENT_PERMISSION,
  DELETE_WORK_EXCEPTION_EVENT_PERMISSION,
  ADD_WORK_EXCEPTION_EVENT_PERMISSION
};
