import React from 'react';
import { Link } from 'react-router-dom';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import './customer_feedback_data.scss';


class CustomerFeedbackData extends React.Component {
  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getStaffOptions() {
    let options = this.props.users.map((entry) => SelectOption(entry.id, entry.name));

    return [
      SelectOption('', 'Não vinculado'),
      ...options
    ];
  }

  getTypeOptions() {
    return this.props.customer_feedback_types.map((entry) => SelectOption(entry.id, entry.name));
  }

  getTargetOptions() {
    return this.props.customer_feedback_targets.map((entry) => SelectOption(entry.id, entry.name));
  }

  render() {
    return (
      <DefaultSection
        className="customer-feedback-data"
        title="Dados do assunto"
      >

        <div className="customer-feedback-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="customer-feedback-data__input-container">

          <DefaultInput
            name="staff_id"
            isHighlighted={this.isHighlighted("staff_id")}
            label="Colaborador vinculado"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.customer_feedback.staff_id || ''}
            options={this.getStaffOptions()}
          />

          <HalfWrapper>

            <DefaultInput
              name="customer_feedback_type_id"
              isHighlighted={this.isHighlighted("customer_feedback_type_id")}
              label="Tipo"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.customer_feedback.customer_feedback_type_id || ''}
              options={this.getTypeOptions()}
            />

            <DefaultInput
              name="customer_feedback_target_id"
              isHighlighted={this.isHighlighted("customer_feedback_target_id")}
              label="Assunto"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.customer_feedback.customer_feedback_target_id || ''}
              options={this.getTargetOptions()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome do cliente"
              type="text"
              placeholder="-"
              maxLength="128"
              // handleInputChange={this.props.handleInputChange}
              value={this.props.customer_feedback.name || ''}
              autoComplete="off"
              disabled={true}
            />

            <DefaultInput
              name="email"
              isHighlighted={this.isHighlighted("email")}
              label="Email do cliente"
              type="text"
              placeholder="-"
              maxLength="128"
              // handleInputChange={this.props.handleInputChange}
              value={this.props.customer_feedback.email || ''}
              autoComplete="off"
              disabled={true}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Mensagem"
            type="textarea"
            placeholder="Mensagem do cliente"
            rows="6"
            // handleInputChange={this.props.handleInputChange}
            value={this.props.customer_feedback.description || ''}
            disabled={true}
          />

          <DefaultInput
            name="archived"
            label="Arquivado:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.customer_feedback.archived}
          />

        </div>

        <HorizontalRule />

        <div className="customer-feedback-data__buttons-container">

          <button
            className="customer-feedback-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="customer-feedback-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default CustomerFeedbackData;
